import { http, objectToFormData } from './http';
import store from '@/store/index';
import qs from 'qs';

const baseCall = async (url, method = 'get', name, data, headersProp = {}) => {
  store.commit('ADD_LOADING', name);
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
    ...headersProp,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.request({ method, url, data, headers })
    .catch((e) => {
      store.commit('DEL_LOADING', name);
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', name);
  return res;
};

export const getTasksApi = async () => {
  const name = 'tasks.getTasksApi';
  const url = `/v1/tasks/get_tasks_for_complex`;
  return baseCall(url, 'get', name)
}

export const addTaskApi = async (data) => {
  const name = 'tasks.addTaskApi';
  const url = '/v1/tasks/task';
  return baseCall(url, 'post', name, data)
}

export const completeTaskApi = async (data) => {
  const name = 'tasks.completeTaskApi';
  const url = '/v1/tasks/complete_task';
  return baseCall(url, 'post', name, data)
}
