import { http, objectToFormData } from './http'
import store from '@/store/index'

const baseCall = async (url, method = 'get', name, data, headersProp = {}) => {
  store.commit('ADD_LOADING', name);
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
    ...headersProp,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.request({ method, url, data, headers })
    .catch((e) => {
      store.commit('DEL_LOADING', name);
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', name);
  return res;
};

export const getDepartmentsApi = async () => {
  const name = 'departments.getDepartmentsApi';
  const url = '/v1/companies/get_departments';
  return baseCall(url, 'get', name)
}

export const getDepartmentsOfComplexApi = async () => {
  const name = 'departments.getDepartmentsOfComplexApi';
  const url = '/v1/companies/department_of_complex';
  return baseCall(url, 'get', name)
}

export const addDepartmentToComplexApi = async (data) => {
  const name = 'departments.addDepartmentToComplexApi';
  const url = '/v1/companies/department_of_complex';
  return baseCall(url, 'post', name, data)
}

export const deleteDepartmentFromComplexApi = async (data) => {
  const name = 'departments.deleteDepartmentFromComplexApi';
  const url = '/v1/companies/department_of_complex';
  return baseCall(url, 'delete', name, data)
}

export const getStaffByDepartmentApi = async (id) => {
  const name = 'departments.getStaffByDepartmentApi';
  const url = `/v1/staff?department_id=${id}`;
  return baseCall(url, 'get', name)
}

export const addStaffToDepartmentApi = async (data) => {
  const name = 'departments.addStaffToDepartmentApi';
  const url = '/v1/department_staff';
  return baseCall(url, 'post', name, data)
}

export const deleteStaffFromDepartmentApi = async (data) => {
  const name = 'departments.deleteStaffFromDepartmentApi';
  const url = '/v1/remove_staff_department';
  return baseCall(url, 'delete', name, data)
}
