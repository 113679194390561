import Vue from 'vue'
import VueRouter from 'vue-router'
import Tickets from '../views/Tickets.vue'
import TicketModal from '@/components/modals/TicketModal.vue'
import Employee from '../views/Employee.vue'
import Users from '../views/Users.vue'
import Login from '../views/Login.vue'
import BuildingInfo from '../views/BuildingInfo.vue'
import Chess from '../views/Chess.vue'
import Events from '../views/Events.vue'
import Telemetry from '../views/Telemetry.vue'
import SmartHouse from '../views/SmartHouse.vue'
import News from '../views/News.vue'
import Monitoring from '../views/Monitoring.vue'
import Finance from '../views/Finance.vue'
import Calendar from '../views/Calendar.vue'
import Security from '../views/Security.vue'
import Documents from '../views/Documents.vue'
import ChangePassword from "../views/ChangePassword";
import ResetPassword from "../views/ResetPassword";
import Voting from "../views/Voting";
import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/tickets' },
  {
    path: '/login',
    name: 'Login',
    meta: {
      layout: 'empty',
      title: 'Авторизация',
    },
    component: Login
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      layout: 'empty',
      title: 'Сброс пароля',
    },
    component: ResetPassword
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    meta: {
      layout: 'empty',
      title: 'Смена пароля',
    },
    component: ChangePassword
  },
  {
    path: '/building-info',
    name: 'BuildingInfo',
    meta: {
      layout: 'empty',
      title: 'Информация о ЖК',
    },
    component: BuildingInfo
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: Tickets,
    meta: {
      layout: 'main',
      title: 'Заявки',
    },
    children: [
      {
        path: ':id',
        name: 'Tickets.modal',
        component: TicketModal,
        meta: { title: 'Заявка' },
        props: true,
      },
    ],
  },
  {
    path: '/monitoring',
    name: 'Monitoring',
    meta: {
      layout: 'main',
      title: 'Мониторинг',
    },
    component: Monitoring
  },  
  {
    path: '/events',
    name: 'Events',
    meta: {
      layout: 'main',
      title: 'События',
    },
    component: Events
  },
  {
    path: '/finance',
    name: 'Finance',
    meta: {
      layout: 'main',
      title: 'Финансы',
    },
    component: Finance
  },
  {
    path: '/calendar',
    name: 'Calendar',
    meta: {
      layout: 'main',
      title: 'Календарь',
    },
    component: Calendar
  },
  {
    path: '/employee',
    meta: {
      layout: 'main',
      title: 'Сотрудники',
    },
    component: Employee,
    children: [
      {
        path: 'staff',
        name: 'Employee.staff',
        meta: { title: 'Сотрудники' },
        component: () => import('@/views/employee/staff.vue'),
      },
      {
        path: 'schedule',
        name: 'Employee.schedule',
        meta: { title: 'Рабочий график' },
        component: () => import('@/views/employee/schedule.vue'),
      },
      {
        path: 'departments',
        name: 'Employee.departments',
        meta: { title: 'Отделы' },
        component: () => import('@/views/employee/departments.vue'),
      },
      {
        path: 'contractors',
        name: 'Employee.contractors',
        meta: { title: 'Подрядчики' },
        component: () => import('@/views/employee/contractors.vue'),
      },
      {
        path: '/',
        redirect: 'staff'
      },
    ]
  },
  {
    path: '/users',
    meta: {
      layout: 'main',
      title: 'Люди',
    },
    component: Users,
    children: [
      {
        path: 'residents',
        name: 'Users.residents',
        meta: { title: 'Лицевые счета' },
        component: () => import('@/views/users/residents.vue'),
      },
      {
        path: '/',
        redirect: 'residents'
      },
    ]
  },
  {
    path: '/chess',
    meta: {
      layout: 'main',
      title: 'Шахматка',
    },
    component: Chess,
    children: [
      {
        path: 'apartment',
        name: 'Chess.apartment',
        meta: { title: 'Жилые помещения' },
        component: () => import('@/components/chess/ChessApartment.vue'),
      },
      {
        path: 'parking',
        name: 'Chess.parking',
        meta: { title: 'Паркинг' },
        component: () => import('@/components/chess/ChessParking.vue'),
      },
      {
        path: 'commerce',
        name: 'Chess.commerce',
        meta: { title: 'Коммерция' },
        component: () => import('@/components/chess/ChessCommerce.vue'),
      },
      {
        path: 'nonresident',
        name: 'Chess.nonresident',
        meta: { title: 'Нежилые помещения' },
        component: () => import('@/components/chess/ChessNotResidential.vue'),
      },
      {
        path: '/',
        redirect: 'apartment'
      },
    ]
  },
  {
    path: '/news',
    name: 'News',
    meta: {
      layout: 'main',
      title: 'Новости',
    },
    component: News
  },
  {
    path: '/telemetry',
    name: 'Telemetry',
    meta: {
      layout: 'main',
      title: 'Телеметрия',
    },
    component: Telemetry
  },
  {
    path: '/smartHouse',
    name: 'SmartHouse',
    meta: {
      layout: 'main',
      title: 'Умный дом',
    },
    component: SmartHouse
  },
  {
    path: '/voting',
    name: 'Voting',
    meta: {
      layout: 'main',
      title: 'Голосование',
    },
    component: Voting,
    children: [
      {
        path: 'active',
        name: 'Voting.active',
        meta: { title: 'Активные голосования' },
        component: () => import('@/components/voting/Active.vue'),
      },
      {
        path: 'archive',
        name: 'Voting.archive',
        meta: { title: 'Архив' },
        component: () => import('@/components/voting/Archive.vue'),
      },
      {
        path: '/',
        redirect: 'active'
      },

    ],
  },
  {
    path: '/security',
    meta: {
      layout: 'main',
      title: 'Безопасность',
    },
    component: Security,
    children: [
      // {
      //   path: 'cctv',
      //   name: 'Security.cctv',
      //   meta: { title: 'Видеонаблюдение' },
      //   component: () => import('@/components/security/cctv.vue'),
      // },
      {
        path: 'pass',
        name: 'Security.pass',
        meta: { title: 'Пропуски' },
        component: () => import('@/components/security/pass.vue'),
      },
      {
        path: '/',
        redirect: 'pass'
      },
    ]
  },
  {
    path: '/documents',
    name: 'Documents',
    meta: {
      layout: 'main',
      title: 'Документы',
    },
    component: Documents
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const defaultTitle = 'Domme.kz';
  if (to.meta.title !== 'Заявка') {
    document.title = `${to.meta.title} 🌞 ${defaultTitle}` || defaultTitle;
  } else {
    document.title = `${to.meta.title} №${to.params.id} 🌞 ${defaultTitle}` || defaultTitle;
  }

  let isAuthenticated = store.state.user.access;
  if (to.name !== 'Login' && (!isAuthenticated)) next({ name: 'Login' })
  else next()
})

export default router
