<template>
  <div class="content">
      <div class="monitoring-wrap">
          <div class="circle">
              <div class="num">7 </div>
              Осталось <br> месяцев
          </div>
          <div class="text">
              Доступно после <br>
8 месяцев пользования
          </div>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>