<template>
  <v-app>
    <v-main>
      <router-view />
      <Snackbar style="padding-top: 64px" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  components: {
    Snackbar: () => import("@/components/Snackbar.vue"),
  },
};
</script>