<template>
  <div>
    <v-btn
      @click.stop="editOperationDialog = true, init()"
      color="#5b6cff"
      text
    >
      Изменить
    </v-btn>
    <v-dialog
      v-model="editOperationDialog"
      width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ operationTitle }} </span>
        </v-card-title>
        <v-card-text>
          <v-form ref="editOperationForm">
            <v-row no-gutters justify="center">
              <v-row no-gutters>
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="newOperationTitle"
                    :rules="operationRules"
                    hide-details
                    required
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-tooltip
                    max-width="300"
                    color="warning"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="sumOfDetails"
                        append-icon="mdi-currency-kzt"
                        type="number"
                        v-bind="attrs"
                        v-on="on"
                        :rules="operationRules"
                        hide-details
                        readonly
                        required
                        outlined
                      ></v-text-field>
                    </template>
                    <span class="tooltip-text">
                      Данное поле нельзя редактировать напрямую.
                      Заполняя дополнительные поля рассчёт идёт автоматически
                    </span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <hr class="divider">
              <v-row
                no-gutters
                v-for="detail in newOperationDetails"
                :key="detail.id"
              >
                <v-col cols="12" md="8">
                  <v-text-field
                    v-model="detail.title"
                    :rules="operationRules"
                    hide-details
                    required
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="detail.amount"
                    append-icon="mdi-currency-kzt"
                    type="number"
                    :rules="operationRules"
                    hide-details
                    required
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            :disabled="disableButton"
            @click="updateOperation({
              operationType,
              operationId,
              operationYear,
              operationMonth,
              newOperationTitle,
              sumOfDetails,
              newOperationDetails,
            })"
          >
            Сохранить
          </v-btn>
          <v-btn
            color="error"
            text
            :disabled="disableButton"
            @click="editOperationDialog = false"
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    newOperationTitle: "",
    newOperationDetails: [],
    editOperationDialog: false,
    disableButton: false,
    operationRules: [ v => !!v || 'Обязательное поле' ],
  }),
  props: {
    operationType: String,
    operationId: Number,
    operationYear: Number,
    operationMonth: Number,
    operationTitle: String,
    operationAmount: String,
    operationDetails: Array,
  },
  computed: {
    sumOfDetails() {
      return JSON.stringify(this.operationDetails.reduce((acc, cur) => {
        let sum = acc;
        sum += Number.parseFloat(cur.amount);
        return sum
      }, 0));
    },
  },
  created () {
    this.init();
  },
  methods: {
    init() {
      this.newOperationTitle = this.operationTitle;
      this.newOperationDetails = this.operationDetails;
    },
    updateOperation(newData) {
      this.newOperationTitle = this.newOperationTitle.trim();
      if (newData.newOperationDetails[0] && newData.newOperationDetails[0].length > 0) {
        newData.newOperationDetails[0].title = newData.newOperationDetails[0].title.trim();
        if (this.$refs.editOperationForm.validate() === true &&
          this.newOperationTitle !== '' &&
          newData.newOperationDetails[0].title !== ''
        ) {
          this.disableButton = true;
          let operationAmountChange = this.sumOfDetails - parseFloat(this.operationAmount);
          console.log('operationAmountChange', operationAmountChange);
          this.$emit('emitUpdateOperation', newData, operationAmountChange);
          this.disableButton = false;
          this.editOperationDialog = false;
        } else {
          this.$store.commit('SHOW_SNACKBAR', 'editOperationErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Поле не может быть пустым!');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        };
      } else {
        if (this.$refs.editOperationForm.validate() === true &&
          this.newOperationTitle !== ''
        ) {
          this.disableButton = true;
          let operationAmountChange = this.sumOfDetails - parseFloat(this.operationAmount);
          console.log('operationAmountChange', operationAmountChange);
          this.$emit('emitUpdateOperation', newData, operationAmountChange);
          this.disableButton = false;
          this.editOperationDialog = false;
        } else {
          this.$store.commit('SHOW_SNACKBAR', 'editOperationErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Поле не может быть пустым!');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        };
      };
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card__title {
  padding: 16px 30px 8px !important;
}
.v-card__text {
  padding: 0px 12px !important;
}
.v-form {
  padding: 0px 12px !important;
}
.divider {
  margin: 12px 6px;
  width: 100%;
  border: 1px solid #F0F0F3;
}
.tooltip-text {
  color: #000000;
  font-weight: 500;
}
.col-12, .col-md-8, .col-md-4 {
  padding: 6px !important;
}
.v-card__actions {
  padding: 8px 30px 16px !important;
}
</style>