import { http } from './http'
import store from '@/store/index'

export const getComplexByManager = async (id) => {
  store.commit('ADD_LOADING', 'companies.getCommentsApi');
  const url = '/v1/companies/get_complex_by_manager';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (Number.parseInt(id) > -1) {
    headers['CurrentComplex'] = id;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'companies.getCommentsApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'companies.getCommentsApi');
  return res;
}

export const getBlocksApi = async () => {
  store.commit('ADD_LOADING', 'companies.getBlocksApi');
  const url = '/v1/companies/blocks';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'companies.getBlocksApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'companies.getBlocksApi');
  return res;
}

export const deleteBlockApi = async (data) =>{
  store.commit('ADD_LOADING','buildings.deleteBlockApi');
  const url = 'v1/companies/blocks/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.delete(url, { headers,data })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.deleteBlockApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.deleteBlockApi');
  return res
}

export const getCheckAccountStatusApi = async () => {
  store.commit('ADD_LOADING', 'companies.getCheckAccountStatusApi');
  const url = '/v1/companies/check_personal_account';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'companies.getCheckAccountStatusApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'companies.getCheckAccountStatusApi');
  return res;
}

export const updateCheckAccountStatusApi = async (data) => {
  store.commit('ADD_LOADING', 'companies.updateCheckAccountStatusApi');
  const url = '/v1/companies/switch_personal_account_check';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'companies.updateCheckAccountStatusApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'companies.updateCheckAccountStatusApi');
  return res;
}