<template>
  <div class="flex-space-around">
    <div class="login-row">
      <img width="120px" src="@/assets/images/main-logo.svg" alt="" />
      <div class="slogan">Цифровой помощник в управлении кондоминиумом</div>

      <v-form @submit.prevent="login" class="login-form" ref="loginInput">
        <v-text-field
          v-model="phone"
          :rules="phoneRules"
          label="Номер телефона"
          type="phone"
          v-mask="'+7 (###) ###-##-##'"
          placeholder="+7 (___) ___-__-__"
          outlined
          required
        ></v-text-field>
        <v-text-field
          v-model="password"
          :rules="passwordRules"
          label="Пароль"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          outlined
          required
        ></v-text-field>

        <v-btn
          class="login-button"
          type="submit"
          :disabled="loading"
        >
          <div v-show="loading">
            <v-progress-circular
              indeterminate
              color="#5b6cff"
              size="30"
              width="3"
            ></v-progress-circular>
          </div>
          <div v-show="!loading">Войти</div>
        </v-btn>
        <div
          @click="$router.push({ name: 'ResetPassword' });"
          class="forggot-password-btn"
        >Забыли пароль?</div>
      </v-form>
    </div>
    <v-footer>
      <v-card flat tile width="100%">
        <hr class="footer-line">
        <v-card-text class="footer-text">
          <div class="footer-rights">
            ©{{ new Date().getFullYear() }} Domme.kz | Все права защищены.
          </div>
          <div>
            <a class="footer-contacts" href="tel:+77717017279"> +7 (771)-701-72-79 </a>
            <a class="footer-contacts" href="mailto:ssn@domme.kz"> ssn@domme.kz </a>
          </div>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>
<script>

export default {
  data() {
    return {
      phone: "",
      password: "",
      loading: false,
      showPassword: false,
      phoneRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length >= 18) || 'Неправильный формат номера',
      ],
      passwordRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length >= 8) || 'Пароль должен быть не менее 8 символов',
      ],
    };
  },
  methods: {
    async login() {
      const phone = this.phone.replace(/[^0-9.]/g, "");
      const password = this.password;
      try {
        this.loading = true;
        if (this.$refs.loginInput.validate() === true) {
          await this.$store.dispatch("user/login", { phone, password });
          await this.$store.dispatch("user/managerProfile");
          await this.$store.dispatch("user/setDefaultComplex");
          this.$router.push({ name: "Tickets" });
          this.$store.commit('SHOW_SNACKBAR', 'loginSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Авторизация прошла успешно');
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        }
      } catch (error) {
        console.log(error);
        let errorMessage = error.response.data.detail;
        if (errorMessage) {
          this.$store.commit('SHOW_SNACKBAR', 'loginErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', errorMessage);
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>
