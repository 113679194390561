<template>
  <div class="header">
    <div class="header-logo" @click="$router.push('/')">
      <img src="@/assets/images/Logo.svg" alt="" />
    </div>
    <div v-if="$vuetify.breakpoint.mdAndDown" class="header-top side-paddings">
      <v-app-bar-nav-icon @click="$emit('toggleSidebar')"></v-app-bar-nav-icon>
    </div>
    <div v-else class="header-top side-paddings">
      <div class="header-left">
        <SelectComplex />
      </div>
      <div class="header-right">
        <ManagerProfile />
      </div>
    </div>
  </div>
</template>
<script>
import SelectComplex from "@/components/widgets/SelectComplex.vue";
import ManagerProfile from "@/components/widgets/ManagerProfile.vue";

export default {
  components: {
    SelectComplex,
    ManagerProfile,
  },
};
</script>
