<template>
  <div
    class="comments-box"
    :class="{ reported: ticketReport && ticketReport.file.length > 0 }"
  >
    <div class="comments-box-wrapper">
      <v-btn
        v-if="$vuetify.breakpoint.xsOnly"
        @click="$emit('showTicket')"
        text
        class="back-to-ticket-btn"
      >
        <v-icon>mdi-chevron-left</v-icon>
        Вернуться к заявке
      </v-btn>
      <div
        v-if="ticketReport && ticketReport.file.length > 0"
        class="ticket-rate-wrapper"
      >
        <v-rating
          :value="ticketInformation.rate"
          background-color="#7F8297"
          color="#FFBB12"
          readonly
        ></v-rating>
        <span class="ticket-rate-title" v-if="ticketInformation.rate === 0"
          >Оценка отсутствует</span
        >
        <span class="ticket-rate-title" v-else>Заявка оценена</span>
      </div>
      <v-container v-if="loading" class="comments-loading">
        <v-progress-circular
          :size="50"
          :width="5"
          color="#5B6CFF"
          indeterminate
        ></v-progress-circular>
      </v-container>
      <v-container v-if="comments_info.count == 0" class="comments-empty">
        <div>Начните чат с отправителем</div>
      </v-container>
      <div
        v-if="comments_info.count != 0"
        ref="scroll"
        class="comments-available"
        :class="{has_rate: ticketReport && ticketReport.file.length > 0}"
      >
        <v-card v-intersect.quiet="onReachTop"></v-card>
        <v-container v-if="this.comments_info.next == true">
          <v-progress-circular
            style="display: flex; margin: 0 auto"
            :size="30"
            :width="3"
            color="#5B6CFF"
            indeterminate
          ></v-progress-circular>
        </v-container>
        <template v-if="showDynamicContent">
          <DynamicScroller
            :items="comments"
            :min-item-size="1"
            @resize="onResize"
          >
            <template v-slot="{ item, index, active }">
              <DynamicScrollerItem
                :item="item"
                :active="active"
                :data-index="index"
                class="message"
              >
                <div class="user-info" style="justify-content: space-between">
                  <div class="user-info">
                    <div class="chat-avatar avatar-img">
                      <v-img
                        v-if="item.author.user_avatar"
                        :src="item.author.user_avatar"
                        lazy-src="@/assets/images/avatar.svg"
                      ></v-img>
                      <v-img v-else src="@/assets/images/avatar.svg"></v-img>
                    </div>
                    <div class="mid-text">
                      <div class="name">
                        {{ item.author.first_name }} {{ item.author.last_name }}
                      </div>
                      <div
                        class="user-status"
                        :class="{ ruler: item.authoRuler }"
                      >
                        {{ item.author.position }}
                      </div>
                    </div>
                  </div>
                  <div class="date">
                    {{ $moment(item.created_at).format("DD.MM.YYYY в HH:mm") }}
                  </div>
                </div>
                <div class="text">{{ item.comment }}</div>
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
        </template>
        <div
          v-if="ticketReport && ticketReport.file.length > 0"
          class="ticket-report-wrap"
        >
          <div
            class="ticket-report"
            v-for="file in ticketReport.file"
            :key="file"
          >
            <v-img
              :src="file"
              @click.stop="(reportDialog = true), showFile(file)"
              class="ticket-report-image"
            ></v-img>
            <div class="report-btn-wrap">
              <v-btn @click.stop="(reportDialog = true), showFile(file)">
                Открыть
              </v-btn>
            </div>
          </div>
          <v-dialog v-model="reportDialog" width="500" scrollable>
            <v-card>
              <v-card-title class="jc-sb">
                <span class="text-h5">Фото-отчёт</span>
                <v-btn icon @click="reportDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <div style="overflow-y: auto">
                <v-card-text>
                  <v-img :src="clickedReportFile"></v-img>
                </v-card-text>
              </div>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
    <div
      v-if="
        (ticketReport && ticketReport.file.length > 0) ||
        ticketInformation.status.title === 'Решен'
      "
      class="no-message"
    >
      <span>Заявка решена, сообщения закрыты</span>
    </div>
    <div v-else class="enter-message">
      <v-form ref="commentForm">
        <v-text-field
          v-model="comment"
          :rules="newCommentRules"
          placeholder="Введите сообщение..."
          hide-details
          clearable
          required
          @keydown.enter.prevent.exact="
            submitComment({
              comment: comment,
              app_label: 'tickets',
              model: 'ticket',
              object_id: ticketInformation.id,
            })
          "
        ></v-text-field>
      </v-form>
      <div
        @click="
          submitComment({
            comment: comment,
            app_label: 'tickets',
            model: 'ticket',
            object_id: ticketInformation.id,
          })
        "
      >
        <img src="@/assets/images/send.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Comments",
  props: {
    comments: {
      type: Array,
      default: () => {},
    },
    comments_info: {
      type: Object,
      default: () => {},
    },
    ticketInformation: {
      type: Object,
      default: () => {},
    },
    ticketReport: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: () => {},
    },
  },
  data() {
    return {
      comment: "",
      firstLoad: true,
      prevScrollHeight: 0,
      showDynamicContent: true,
      reportDialog: false,
      clickedReportFile: "",
      isCommentSubmitted: false,
      newCommentRules: [(v) => !!v || "Обязательное поле"],
    };
  },
  watch: {
    comments() {
      if (this.isCommentSubmitted === true) {
        this.showDynamicContent = false;
        this.prevScrollHeight = 0;
        this.$nextTick(() => {
          this.showDynamicContent = true;
          this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight;
          this.isCommentSubmitted = false;
        });
        console.log("Submitted", this.isCommentSubmitted);
      }
    },
  },
  methods: {
    submitComment(data) {
      // submit comment
      this.comment = this.comment.trim();
      if (this.$refs.commentForm.validate() === true && this.comment !== "") {
        this.$emit("addComment", data);
        this.comment = "";
        this.isCommentSubmitted = true;
        this.$refs.commentForm.reset();
      } else {
        alert("Введите корректный комментарий!");
      }
    },
    onReachTop(entries, observer, isIntersecting) {
      // gets next page
      if (isIntersecting) {
        this.$emit("getNextComments", this.firstLoad);
        this.firstLoad = false;
      }
    },
    scrollToBottom() {
      // fires at start
      this.$nextTick(() => {
        this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight;
      });
    },
    onResize() {
      // calls when resized
      this.$nextTick(() => {
        this.$refs.scroll.scrollTop =
          this.$refs.scroll.scrollHeight - this.prevScrollHeight;
        this.prevScrollHeight = this.$refs.scroll.scrollHeight;
        console.log(
          "Resized scrollHeight:",
          this.$refs.scroll.scrollHeight,
          "prevScrollHeight:",
          this.prevScrollHeight,
          "scrollTop:",
          this.$refs.scroll.scrollTop
        );
      });
    },
    showFile(data) {
      this.clickedReportFile = data;
    },
  },
};
</script>

<style lang="scss">
.v-image__image--preload {
  filter: blur(1px) !important;
}
</style>