<template>
  <v-row no-gutters justify="center">
    <v-dialog
      v-model="editSavingBalance"
      @click:outside="resetSavingBalance"
      width="300px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          color="primary"
        >
          Изменить
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5"> Текущий счёт </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters justify="center">
              <v-col cols="12">
                <v-text-field
                  v-model="newSavingBalance"
                  append-icon="mdi-currency-kzt"
                  type="number"
                  :rules="savingBalanceRules"
                  hide-details
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="emitUpdateSavingBalance(newSavingBalance)"
            :disabled="submitForm"
            :loading="submitForm"
            color="primary"
            text
          >
            Сохранить
          </v-btn>
          <v-btn
            @click="resetSavingBalance"
            color="error"
            text
          >
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    editSavingBalance: false,
    newSavingBalance: "",
    submitForm: false,
    savingBalanceRules: [ v => !!v || 'Обязательное поле' ],
  }),
  props: {
    savingBalance: Number,
  },
  created() {
    this.newSavingBalance = this.savingBalance;
  },
  methods: {
    emitUpdateSavingBalance(newSavingBalance) {
      this.$emit('emitUpdateSavingBalance', newSavingBalance);
    },
    resetSavingBalance() {
      this.newSavingBalance = this.savingBalance;
      this.editSavingBalance = false;
    },
  },
}
</script>

<style lang="scss" scoped>
.v-card__title {
  padding: 16px 30px 8px !important;
}
.v-card__text {
  padding: 0px 12px !important;
}
.container {
  padding: 0px 12px !important;
}
.col-12, .col-md-8, .col-md-4 {
  padding: 6px !important;
}
.v-card__actions {
  padding: 8px 30px 16px !important;
}
</style>