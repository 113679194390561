<template>
  <div>
    <div
      v-if="$route.name !== 'Tickets.modal' || !$vuetify.breakpoint.xsOnly"
      class="content"
    >
      <div class="row side-paddings">
        <div v-if="!$vuetify.breakpoint.xsOnly" class="tab-row">
          <div
            class="tab-item mr-3"
            :class="{
              active: tableFilter.status == null && !tableFilter.important,
            }"
            @click="
              (page = 1),
                setFilter({ name: 'status', value: null }),
                setFilter({ name: 'important', value: null })
            "
          >
            Все ({{ statusList.reduce((acc, cur) => (acc += cur.count), 0) }})
          </div>
          <div
            class="tab-item mr-3"
            :class="{ active: tableFilter.important }"
            @click="
              (page = 1),
                setFilter({ name: 'important', value: true }),
                setFilter({ name: 'status', value: null })
            "
          >
            Важные ({{ importantTickets.important }})
          </div>
          <div
            v-for="status in statusList.filter((s) => s.count !== -1)"
            :key="status.name"
            class="tab-item mr-3"
            :class="{ active: tableFilter.status === status.id }"
            @click="
              (page = 1),
                setFilter({ name: 'status', value: status.id }),
                setFilter({ name: 'important', value: null })
            "
          >
            {{ status.title }} ({{ status.count }})
          </div>
        </div>
        <div v-else class="tab-row">
          <v-menu ref="ticketType" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ticket-type-dropdown" v-bind="attrs" v-on="on" text>
                {{
                  ticketTypeTitle ||
                  `Все (${statusList.reduce((acc, cur) => (acc += cur.count), 0)})`
                }}
                <v-icon v-if="$refs.ticketType && $refs.ticketType.isActive"
                  >mdi-chevron-up</v-icon
                >
                <v-icon v-else> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="
                  (page = 1),
                    setFilter({ name: 'status', value: null }),
                    setFilter({ name: 'important', value: null }),
                    (ticketTypeTitle = `Все (${statusList.reduce(
                      (acc, cur) => (acc += cur.count),
                      0
                    )})`)
                "
              >
                <v-list-item-title>
                  Все ({{ statusList.reduce((acc, cur) => (acc += cur.count), 0) }})
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                @click="
                  (page = 1),
                    setFilter({ name: 'important', value: true }),
                    setFilter({ name: 'status', value: null }),
                    (ticketTypeTitle = `Важные (${importantTickets.important})`)
                "
              >
                <v-list-item-title>
                  Важные ({{ importantTickets.important }})
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-for="status in statusList.filter((s) => s.count !== -1)"
                :key="status.name"
                @click="
                  (page = 1),
                    setFilter({ name: 'status', value: status.id }),
                    setFilter({ name: 'important', value: null }),
                    (ticketTypeTitle = `${status.title} (${status.count})`)
                "
              >
                <v-list-item-title
                  >{{ status.title }} ({{ status.count }})</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div>
          <v-btn
            v-if="$vuetify.breakpoint.xsOnly"
            @click="toggleShowFilter"
            class="filter"
            text
          >
            Фильтр
            <img src="@/assets/images/filter.svg" alt="" />
          </v-btn>
          <CreateTicket
            v-if="!showMobileFilters"
            :ticketTypes="ticketTypes"
            :departmentsOfComplex="departmentsOfComplex"
            @close="toggleNewTicket"
            @init="init"
            @addTicket="addTicket"
          />
        </div>
      </div>
      <div v-if="!$vuetify.breakpoint.xsOnly" class="search-row side-paddings">
        <div class="search">
          <input type="text" placeholder="Поиск..." v-model="searchValue" />
        </div>
        <div class="right">
          <div class="switch-box">
            Только с сообщениями
            <label class="switch">
              <input
                type="checkbox"
                :checked="tableFilter.comments"
                @change="
                  (e) =>
                    setFilter({ name: 'comments', value: e.target.checked })
                "
                @click="page = 1"
              />
              <span class="slider"></span>
            </label>
          </div>
          <div class="filter" @click="toggleShowFilter">
            Фильтр
            <img src="@/assets/images/filter.svg" alt="" />
          </div>
        </div>
      </div>
      <v-row no-gutters class="filter-row side-paddings" v-show="showFilter">
        <v-col lg="2" md="4" cols="12" class="filter-item">
          <h4 class="filter-title">Дата заявки</h4>
          <v-menu
            v-model="ticketDatePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-on="on"
                v-bind="attrs"
                label="Выбрать"
                append-icon="mdi-calendar"
                :value="computedDateFormattedMomentjs"
                @click:clear="
                  page = 1,
                  currDate = '',
                  setFilter({ name: 'date', value: null })
                "
                clearable
                hide-details
                readonly
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currDate"
              @input="
                page = 1,
                setFilter({ name: 'date', value: currDate }),
                ticketDatePicker = false
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col lg="2" md="4" cols="12" class="filter-item">
          <h4 class="filter-title">Отправитель</h4>
          <v-autocomplete
            v-model="currSender"
            :items="apiSenders"
            item-text="name"
            item-value="id"
            label="Выбрать"
            @input="
              page = 1,
              setFilter({ name: 'addresser', value: currSender })
            "
            @click:clear="
              page = 1,
              currSender = '',
              setFilter({ name: 'addresser', value: null })
            "
            :menu-props="{ bottom: true, offsetY: true }"
            hide-details
            clearable
            outlined
          >
            <template v-slot:item="sender">
              <v-list-item-avatar>
                <v-img
                  v-if="sender.item.avatar"
                  :src="sender.item.avatar"
                  lazy-src="@/assets/images/avatar.svg"
                ></v-img>
                <v-img v-else src="@/assets/images/avatar.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ sender.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip label class="sender-address-chip mr-1">
                    <pre class="sender-address">Блок: </pre>
                    <span class="sender-number">{{ sender.item.block }}</span>
                  </v-chip>
                  <v-chip label class="sender-address-chip">
                    <pre class="sender-address">Квартира: </pre>
                    <span class="sender-number">{{ sender.item.number }}</span>
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col lg="2" md="4" cols="12" class="filter-item">
          <h4 class="filter-title">Тип заявки</h4>
          <v-select
            v-model="currType"
            :items="ticketTypes"
            item-text="title"
            item-value="id"
            label="Выбрать"
            @input="
              page = 1,
              setFilter({ name: 'type', value: currType })
            "
            @click:clear="
              page = 1,
              currType = '',
              setFilter({ name: 'type', value: null })
            "
            :menu-props="{ bottom: true, offsetY: true }"
            hide-details
            clearable
            outlined
          ></v-select>
        </v-col>
        <v-col lg="2" md="4" cols="12" class="filter-item">
          <h4 class="filter-title">Служба</h4>
          <v-select
            v-model="currDepartment"
            :items="departmentsOfComplex"
            item-text="department_title"
            item-value="department_id"
            label="Выбрать"
            @input="
              page = 1,
              setFilter({ name: 'type', value: currDepartment })
            "
            @click:clear="
              page = 1,
              currDepartment = '',
              setFilter({ name: 'type', value: null })
            "
            :menu-props="{ bottom: true, offsetY: true }"
            hide-details
            clearable
            outlined
          ></v-select>
        </v-col>
        <v-col lg="2" md="4" cols="12" class="filter-item">
          <h4 class="filter-title">Исполнитель</h4>
          <v-autocomplete
            v-model="currExec"
            :items="apiExecutors"
            item-text="name"
            item-value="id"
            label="Выбрать"
            @input="
              page = 1,
              setFilter({ name: 'executor', value: currExec })
            "
            @click:clear="
              page = 1,
              currExec = '',
              setFilter({ name: 'executor', value: null })
            "
            :menu-props="{ bottom: true, offsetY: true }"
            hide-details
            clearable
            outlined
          >
            <template v-slot:item="executor">
              <v-list-item-avatar>
                <v-img
                  v-if="executor.item.avatar"
                  :src="executor.item.avatar"
                  lazy-src="@/assets/images/avatar.svg"
                ></v-img>
                <v-img v-else src="@/assets/images/avatar.svg"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ executor.item.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip label class="executor-address-chip">
                    <span class="executor-number">{{ executor.item.position }}</span>
                  </v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          v-if="$vuetify.breakpoint.xsOnly"
          class="filter-item save-filters-btn"
        >
          <v-btn
            @click="toggleShowFilter"
          >
            Применить фильтр
          </v-btn>
        </v-col>
        <v-col lg="2" md="4" cols="12" class="filter-item reset-filters-btn">
          <v-btn text color="#5B6CFF" @click="resetMainFilter">Сбросить фильтр</v-btn>
        </v-col>
      </v-row>
      <div v-if="!$vuetify.breakpoint.xsOnly" class="table-container">
        <table class="table">
          <tr>
            <td></td>
            <td>№</td>
            <td>Служба</td>
            <td style="text-align: center; padding-right: 58px">Статус</td>
            <td>Описание</td>
            <td>Исполнитель</td>
            <td>Дата заявки</td>
            <td>Дата завершения</td>
            <td>Отправитель</td>
          </tr>
          <tr v-for="ticket in tickets" :key="ticket.number">
            <td>
              <div class="notify-cell">
                <div
                  class="alert-circle"
                  :class="{ 'blue-circle': ticket.important }"
                  @click="
                    updateTicketImportance({
                      ticket: ticket.number,
                      important: !ticket.important,
                    })
                  "
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z"
                      stroke="#7b7e97"
                      stroke-width="1.6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 6V9"
                      stroke="#7b7e97"
                      stroke-width="1.6"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 12H9.00833"
                      stroke="#7b7e97"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="message">
                  <img src="@/assets/images/mail.svg" alt="" />
                </div>
              </div>
            </td>
            <td class="number">
              {{ ticket.number }}
            </td>
            <td class="location">
              <div v-if="ticket.department">{{ ticket.department }}</div>
              <div v-else>Не назначен</div>
            </td>
            <td>
              <div
                class="status"
                :class="{
                  new: ticket.status == 'Новый',
                  pending: ticket.status == 'В ожидании',
                  in_process: ticket.status == 'В процессе',
                  expired: ticket.status == 'Просрочен',
                  done: ticket.status == 'Решен',
                  rejected: ticket.status == 'Отклонен',
                }"
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="white"
                      v-bind="attrs"
                      v-on="on"
                      :class="{
                        new: ticket.status.title == 'Новый',
                        pending: ticket.status.title == 'В ожидании',
                        in_process: ticket.status.title == 'В процессе',
                        expired: ticket.status.title == 'Просрочен',
                        done: ticket.status.title == 'Решен',
                        rejected: ticket.status.title == 'Отклонен',
                      }"
                    >
                      &bull; {{ ticket.status.title }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="status in statusList"
                      :key="status.id"
                      @click="setStatus(ticket.number, status.id)"
                    >
                      <v-list-item-title>{{ status.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </td>
            <td>
              <router-link
                class="description"
                :to="`/tickets/${ticket.number}`"
              >
                Открыть
              </router-link>
            </td>
            <td class="executor">
              {{ ticket.executor }}
            </td>
            <td class="ticket-date">
              {{ ticket.ticketDate }}
            </td>
            <td class="finish-date">
              {{ ticket.finishDate }}
            </td>
            <td class="executor">
              {{ ticket.sender }}
            </td>
          </tr>
        </table>
      </div>
      <div
        v-else-if="$vuetify.breakpoint.xsOnly && !showMobileFilters"
        class="table-container"
      >
        <v-card
          v-for="ticket in tickets"
          :key="ticket.number"
          @click="$router.push(`/tickets/${ticket.number}`)"
          class="mobile-tickets"
        >
          <v-card-subtitle class="ticket-number-date">
            <span class="ticket-number">№{{ ticket.number }}</span>
            <span class="ticket-date">{{ ticket.ticketDate }}</span>
          </v-card-subtitle>
          <v-divider></v-divider>
          <v-card-title class="ticket-department-status">
            <span class="ticket-department">{{
              ticket.department || "Не назначен"
            }}</span>
            <div>
              <v-chip
                label
                :class="{
                  new: ticket.status.title == 'Новый',
                  pending: ticket.status.title == 'В ожидании',
                  in_process: ticket.status.title == 'В процессе',
                  expired: ticket.status.title == 'Просрочен',
                  done: ticket.status.title == 'Решен',
                  rejected: ticket.status.title == 'Отклонен',
                }"
              >
                {{ ticket.status.title }}
              </v-chip>
              <v-icon size="32">mdi-chevron-right</v-icon>
            </div>
          </v-card-title>
          <v-card-text
            v-if="ticket.block && ticket.address"
            class="ticket-address"
          >
            <v-chip label class="address-chip mr-3">
              <pre class="address-title">Блок: </pre>
              <span class="address-number">{{ ticket.block.number }}</span>
            </v-chip>
            <v-chip label class="address-chip mr-3">
              <pre class="address-title">Подъезд: </pre>
              <span class="address-number">{{ ticket.address.entrance }}</span>
            </v-chip>
            <v-chip label class="address-chip">
              <pre class="address-title">Квартира: </pre>
              <span class="address-number">{{ ticket.address.number }}</span>
            </v-chip>
          </v-card-text>
        </v-card>
      </div>
      <div v-if="!showMobileFilters" class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="pagesData.pages"
          total-visible="7"
          @input="changePage"
          @next="changePage"
          @previous="changePage"
          :disabled="loadingPage"
          color="#5b6cff"
        ></v-pagination>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import {
  applicationApi,
  getApplicationType,
  getAppStatusesApi,
  getStatusListApi,
  setStatusApi,
  updateTicketImportanceAPI,
  getImportantTicketNum,
} from "@/api/applications";
import CreateTicket from "@/components/modals/CreateTicket.vue";
import locationDict from "@/models/locations.json";
import { staffApi } from "@/api/staff";
import { residentApi } from "@/api/residents";
import { getDepartmentsOfComplexApi } from "@/api/departments";

export default {
  name: "Tickets",
  components: {
    CreateTicket,
    IconCalendar: () => import("@/components/ui/icons/calendar"),
  },
  data() {
    return {
      tickets: [],
      ticketsPerPage: 10,
      pagesData: [],
      ticketTypeTitle: undefined,
      importantTickets: [],
      location: locationDict,
      currloc: "",
      currType: "",
      currExec: "",
      currSender: "",
      currDate: "",
      currDepartment: "",
      ticketDatePicker: false,
      page: 1,
      executorId: 1,
      ticketId: 0,
      senderId: 1,
      showSenders: false,
      showTypes: false,
      createTicket: false,
      showFilter: false,
      showMobileFilters: false,
      loadingPage: false,
      tableFilter: {},
      statusList: [],
      ticketTypes: [],
      departmentsOfComplex: [],
      applicationId: null,
      apiExecutors: [],
      apiSenders: [],
      search: "",
      searchValue: "",
    };
  },
  computed: {
    executors() {
      let executors = this.apiExecutors;
      if (this.search.length > 0) {
        const r = new RegExp(pregQuote(this.search), "ig");
        executors = this.apiExecutors.filter((ex) =>
          Object.values(ex).some((prop) => r.test(prop))
        );
      }
      return executors;
    },
    senders() {
      let senders = this.apiSenders;
      if (this.search.length > 0) {
        const r = new RegExp(pregQuote(this.search), "ig");
        senders = this.apiSenders.filter((ex) =>
          Object.values(ex).some((prop) => r.test(prop))
        );
      }
      return senders;
    },
    computedDateFormattedMomentjs () { // formatting date inside datepicker input
      return this.currDate ? this.$moment(this.currDate).format("DD MMMM YYYY") : '';
    },
  },
  watch: {
    tableFilter: {
      deep: true,
      handler() {
        /** set url parameters from tableFilters data & fetch application list */
        if (
          Object.keys(this.tableFilter).length > 0 &&
          this.$route.query.tableFilter !== JSON.stringify(this.tableFilter)
        ) {
          this.$router.push({
            ...this.$route,
            query: {
              ...this.$route.query,
              tableFilter: JSON.stringify(this.tableFilter),
            },
          });
          this.getApplications();
        }
      },
    },
    searchValue: function () {
      this.page = 1;
      this.setFilter({ name: "description", value: this.searchValue });
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      /** fill tableFilter from route query */
      // console.log(this.$route.query.tableFilter)
      this.tableFilter = JSON.parse(this.$route.query.tableFilter || "{}");

      /** get statuses, apps etc. */
      this.getApplications();
      this.getTicketTypes();
      this.getDepartmentsOfComplex();
      this.getAppStatuses();
      this.getExecutors();
      this.getSenders();
      this.getImportantTickets();
      console.log("init");
    },
    // TO DO исправить ошибку в консоли
    filterSender(sender) {
      console.log(sender);
      this.page = 1;
      this.setFilter({ name: "addresser", value: sender.id });
      this.currSender = sender.name;
      console.log(this.currSender);
    },
    filterExecutor(exec) {
      console.log(exec);
      this.page = 1;
      this.setFilter({ name: "executor", value: exec.id });
      this.currExec = exec.first_name + " " + exec.last_name;
      console.log(this.currExec);
    },
    changePage() {
      this.loadingPage = true;
      this.getApplications();
    },
    addTicket() {
      this.page = 1;
      this.getAppStatuses();
      this.changePage();
    },
    toggleNewTicket() {
      this.createTicket = !this.createTicket;
    },
    toggleShowFilter() {
      this.showFilter = !this.showFilter;
      if (this.$vuetify.breakpoint.xsOnly) {
        this.showMobileFilters = !this.showMobileFilters;
      };
    },
    toggleStatus(smth) {
      console.log(smth);
    },
    async getApplications() {
      if (this.$vuetify.breakpoint.xsOnly) {
        this.ticketsPerPage = 5;
      }
      const res = await applicationApi(
        this.tableFilter,
        this.page,
        this.ticketsPerPage
      );
      const tickets = res.data.results.map((ticket) => ({
        number: ticket.id,
        location: ticket.location,
        status: ticket.status,
        type: ticket.type.title,
        important: ticket.important,
        description: ticket.description,
        department: ticket.department,
        address: ticket.address,
        block: ticket.block,
        executor:
          ticket.executor?.first_name + " " + ticket.executor?.last_name,
        executorId: ticket.executor?.id,
        ticketDate: this.$moment(ticket.created_at).isValid()
          ? this.$moment(ticket.created_at).format("DD.MM.YYYY в HH:mm")
          : "--/--",
        finishDate: this.$moment(ticket.completion_date).isValid()
          ? this.$moment(ticket.completion_date).format("DD.MM.YYYY в HH:mm")
          : "--/--",
        sender:
          ticket.addresser?.first_name + " " + ticket.addresser?.last_name,
        senderId: ticket.addresser?.id,
      }));
      this.tickets = tickets;
      this.pagesData = res.data;
      this.loadingPage = false;
    },
    async getTicketTypes() {
      let res = await getApplicationType();
      this.ticketTypes = [...res.data];
    },
    async getDepartmentsOfComplex() {
      let res = await getDepartmentsOfComplexApi();
      this.departmentsOfComplex = res.data;
    },
    async getAppStatuses() {
      let response = await getAppStatusesApi();
      let statusCount = {...response.data};
      let res = await getStatusListApi();
      this.statusList = [...res.data];
      this.statusList.forEach(status => {
        status.count = statusCount[status.code];
      });
    },
    async getImportantTickets() {
      const res = await getImportantTicketNum();
      this.importantTickets = res.data;
    },
    /** toggle tableFilter */
    setFilter({ name, value }) {
      console.log("FILTERED");
      this.$set(this.tableFilter, name, value);
    },
    /** reset only filter with inputs */
    resetMainFilter() {
      this.setFilter({ name: "date", value: null });
      this.setFilter({ name: "type", value: null });
      this.setFilter({ name: "executor", value: null });
      this.setFilter({ name: "addresser", value: null });
      this.setFilter({ name: "location", value: null });
      this.currDate = "";
      this.currloc = "";
      this.currSender = "";
      this.currType = "";
      this.currExec = "";
      if (this.$vuetify.breakpoint.xsOnly) {
        this.toggleShowFilter();
      };
    },
    async setStatus(app, id) {
      await setStatusApi(app, id)
        .then(() => this.init())
        .catch(() => {});
    },
    async getExecutors() {
      const res = await staffApi();
      this.apiExecutors = res.data.map((s) => ({
        id: s.user.id,
        position: s.position,
        name: `${s.user?.first_name} ${s.user?.last_name}`,
        avatar: s.user?.user_avatar?.avatar,
      }));
    },
    async getSenders() {
      const res = await residentApi();
      // console.log('sender',res.data);
      this.apiSenders = res.data.map((s) => ({
        id: s.id,
        name: `${s?.first_name} ${s?.last_name}`,
        avatar: s.user_avatar?.avatar,
        block: s.block,
        entrance: s.entrance,
        number: s.number,
      }));
    },
    async updateTicketImportance(data) {
      await updateTicketImportanceAPI({ data }).then(() => this.init());
      // await updateTicketImportanceAPI({data});
    },
  },
};
</script>

<style scope lang="scss">
.ticket-date {
  white-space: nowrap;
}
</style>
