import { http } from './http'
import store from '@/store/index'

export const residentApi = async () => {
  store.commit('ADD_LOADING', 'residents.residentApi');
  const url = '/v1/residents/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'residents.residentApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'residents.residentApi');
  return res;
};

export const residentDeleteApi = async () => {
  store.commit('ADD_LOADING', 'residents.residentDeleteApi');
  const url = '/v1/residents';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'residents.residentDeleteApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'residents.residentDeleteApi');
  return res;
};
