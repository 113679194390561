<template>
  <div class="backdrop" @click.self="closeModal">
    <div class="modal-sm">
      <div class="delete-user delete-entrance">
        <img src="@/assets/images/delete-icon.svg" alt="" />

        <div class="sure-text">
          {{title}}
        </div>
        <div class="name">{{description}}</div>
        <div class="btn-row">
          <div class="decline-btn" @click="closeModal">Отмена</div>
          <div class="delete-btn" @click="deleteItem">Подтвердить</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    deleteItem() {
      this.$emit('delete');
      this.closeModal();
    },
  },
};
</script>

<style>
</style>