<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="select-complex-button"
        v-bind="attrs"
        v-on="on"
      >
        <div class="icon">
          <img src="@/assets/images/home.svg" alt="" />
        </div>
        <div class="house">
          <div class="text">
            <div class="house-name">
              {{ complexes.current ? complexes.current.name : "Не выбран" }}
            </div>
            <div class="pick-other">
              Выбрать другой
            </div>
          </div>
        </div>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="item in complexes.others"
        :key="item.id"
        class="house-list d-flex justify-space-between"
        @click="selectComplex(item.id)"
      >
        <div class="text" style="min-width: 120px">
          <div class="house-item">{{ item.name }}</div>
        </div>
        <div>
          <img src="@/assets/images/house-arrow.svg" alt="" />
        </div>
      </v-list-item>
      <v-list-item v-if="complexes.others && complexes.others.length === 0">
        <span class="house-item">Нет других ЖК</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { getComplexByManager } from '@/api/companies';

export default {
  data: () => ({
    complexes: [],
  }),
  computed: {
    currentComplex() {
      return this.$store.state.currentComplex;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      let curComplex = localStorage.getItem("currentComplex");
      if (curComplex) {
        this.$store.commit("SET_CURRENT_COMPLEX", curComplex);
      }
      this.getComplexes(this.currentComplex);
    },
    async getComplexes(id) {
      try {
        let res = await getComplexByManager(id);
        this.complexes = res.data;
      } catch (err) {
        throw err;
      } finally {
        // Done
      }
    },
    selectComplex(id) {
      this.$store.dispatch("setCurrentComplex", id);
      this.$router.go();
    },
  },
};
</script>