<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>
<script>
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'

export default {
  components: {
    EmptyLayout,
    MainLayout
  },
  computed: {
    layout() {
      const layout = this.$route.matched.find((record) => record.meta?.layout)?.meta?.layout
      return (layout || 'empty') + '-layout'
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      // set moment locale
      this.$moment.locale('ru');

      // prefill store from localStorage
      // const currentComplex = localStorage.getItem('currentComplex');

      // this.$store.commit('SET_CURRENT_COMPLEX', currentComplex);
    }
  }
}
</script>
<style>
#app {
  font-family: "Inter", sans-serif;
  color: #2c3e50;
  max-width: 100%;
  margin: auto;
  /* border-radius: 14px; */
}
</style>
