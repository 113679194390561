<template>
  <div>
    <div v-show="!isChessSettingsVisible" class="content">
      <v-row no-gutters class="side-paddings" :class="{ details: showSideModal }">
        <div class="tab-row">
          <router-link
            v-for="item in buildingTypes"
            :key="item.id"
            :to="{ name: `Chess.${item.type}` }"
            class="tab-item"
          >
            {{ item.title }}
          </router-link>
        </div>
        <v-btn
          text
          outlined
          class="chess-settings-btn"
          @click="showChessSettings"
        >
          <span>Настройки</span>
          <img src="@/assets/images/settings.svg" alt="gear" />
        </v-btn>
      </v-row>
      <router-view @resizeTabsRow="resizeTabsRow"></router-view>
    </div>
    <div v-show="isChessSettingsVisible" class="content">
      <v-row no-gutters class="side-paddings">
        <v-btn
          text
          color="primary"
          :disabled="submitChanges"
          @click="hideChessSettings"
        >
          <v-icon color="primary">mdi-chevron-left</v-icon>
          <span> Вернуться в шахматку </span>
        </v-btn>
      </v-row>
      <div class="chess-wrapper">
        <div class="grey-bg">
          <div class="chess-settings-title">Настройки шахматки</div>
          <v-card class="chess-settings-list">
            <v-card class="chess-settings-item">
              <v-card-title>Проверка</v-card-title>
              <v-card-text>
                <span> Сверка регистрируемых жителей с лицевым счётом </span>
                <div class="switch-button">
                  <label class="switch"
                    ><input
                      type="checkbox"
                      :checked="checkAccountStatus"
                      :disabled="submitChanges"
                      @click="checkAccountStatus = !checkAccountStatus"
                    />
                    <span class="slider"></span>
                  </label>
                </div>
              </v-card-text>
            </v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="error"
                :disabled="!isSettingsChanged || submitChanges"
                :loading="submitChanges"
                @click="resetChanges"
              >
                Отменить изменения
              </v-btn>
              <v-btn
                text
                color="primary"
                :disabled="!isSettingsChanged || submitChanges"
                :loading="submitChanges"
                @click="saveChanges"
              > Сохранить </v-btn>
            </v-card-actions>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBuildingTypesApi } from "@/api/buildings";
import {
  getCheckAccountStatusApi,
  updateCheckAccountStatusApi,
} from "@/api/companies";

export default {
  data: () => ({
    buildingTypes: [],
    isChessSettingsVisible: false,
    checkAccountStatus: null,
    checkAccountStatusDefault: null,
    submitChanges: false,
    showSideModal: false,
  }),
  computed: {
    isSettingsChanged() {
      if (this.checkAccountStatus !== this.checkAccountStatusDefault) {
        return true;
      } else return false;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getBuildingTypes();
      this.getCheckAccountStatus();
    },
    async getBuildingTypes() {
      let res = await getBuildingTypesApi();
      this.buildingTypes = [...res.data];
    },
    async getCheckAccountStatus() {
      let res = await getCheckAccountStatusApi();
      this.checkAccountStatus = res.data.check_personal_account;
      this.checkAccountStatusDefault = res.data.check_personal_account;
    },
    showChessSettings() {
      this.isChessSettingsVisible = true;
    },
    hideChessSettings() {
      this.isChessSettingsVisible = false;
      this.resetChanges();
    },
    resetChanges() {
      this.checkAccountStatus = this.checkAccountStatusDefault;
    },
    async saveChanges() {
      try {
        this.submitChanges = true;
        let res = await updateCheckAccountStatusApi();
        console.log('updateCheckAccountStatusApi', res);
        this.getCheckAccountStatus();
        this.$store.commit('SHOW_SNACKBAR', 'changeSettingsSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Настройки успешно обновлены');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'changeSettingsErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при обновлении настроек');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      } finally {
        this.submitChanges = false;
      };
    },
    resizeTabsRow(){
      this.showSideModal = !this.showSideModal;
    },
  },
};
</script>
