import axios from 'axios'
import store from "@/store";
import router from '../router/index'

const http = axios.create({
  baseURL: process.env.VUE_APP_API_HOST
})

http.defaults.headers.common['Content-Type'] = 'application/json';
http.defaults.headers.common['Accept'] = 'application/json';
http.defaults.headers.common['Accept-Language'] = 'ru';

export {http};

/**
 * convert simple object to FormData
 * @param {*} data
 */
export const objectToFormData = (data) => {
  console.log("FormData Data",data)
  const formDataParams = new FormData()
  Object.keys(data).forEach(paramProp => {
    if (Array.isArray(data[paramProp])) {
      data[paramProp].forEach((paramPropItem, idx) => {
        formDataParams.append(paramProp, data[paramProp][idx])
      })
    } else if (data[paramProp] instanceof File) {
      formDataParams.append(paramProp, data[paramProp], data[paramProp].name)
    } else {
      formDataParams.append(paramProp, data[paramProp])
    }
  })
  // console.log("FormData",formDataParams.getAll())
  return formDataParams;
}

// Intercepting token
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })
  
  failedQueue = [];
}

http.interceptors.response.use(function (response) {
  return response;
}, async function (error) {

  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest._retry && store.state.user.access) {
      
    if (isRefreshing) {
      return new Promise(function(resolve, reject) {
        failedQueue.push({resolve, reject})
      }).then(() => {
        originalRequest.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access');
        return axios(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      })
    }

    originalRequest._retry = true;
    isRefreshing = true;

    const refreshToken = store.state.user.refresh;
    return new Promise(function (resolve, reject) {
      store.dispatch("user/refresh", refreshToken)
        .then(() => {
          http.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access');
          originalRequest.headers['Authorization'] = 'Bearer ' + localStorage.getItem('access');
          processQueue(null, localStorage.getItem('access'));
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          reject(err);
        })
        .finally(() => { isRefreshing = false });
    })
  }

  if (error.response.status >= 500 && error.response.status <= 504) {
    store.commit('SHOW_SNACKBAR', 'serverErrorSnackbar');
    store.commit('SET_SNACKBAR_TITLE', 'Внутренняя ошибка сервера');
    store.commit('SET_SNACKBAR_COLOR', 'error');
  }

  return Promise.reject(error);
});