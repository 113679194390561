<template>
    <div class="flex-space-around">
      <div class="building-row build-info">
        <h5>Введите данные ЖК</h5>
        <form class="login-form">

          <v-text-field
          label="Название ЖК"
            v-model="name"
            :rules="rules"
            required
          ></v-text-field>
          
          <v-select
            label="Город"
            v-model="city"
            name="city"
            id="city"
            :items="items"
            item-text="title"
            item-value="value"
            return-object
          ></v-select>
          
          <v-text-field
            label="Адрес (ул. Гагарина, 124)"
            v-model="phone"
            :rules="rules"
            required
          ></v-text-field>
          
          <div class="block-count">
            <div class="counter">
              <div @click="counter--" class="decrease">-</div>
              <div class="num">
                {{ counter }}
              </div>
              <div @click="counter++" class="increase">+</div>
            </div>
            <p>Количество блоков</p>
          </div>
          <div class="save-btn">Сохранить</div>
        </form>
      </div>
      <v-footer>
        <div class="rights">Domme. All rights reserved</div>
        <div class="contacts">
          <a href="tel:+77717017279">+7 771 701 72 79</a>
          <a href="mailto:ssn@domme.kz">ssn@domme.kz</a>
        </div>
      </v-footer>
    </div>
</template>

<script>
export default {
  data() {
    return {
      counter: 0,
      rules: [(value) => !!value || "Обязательное поле"],
      items: [
        {
          title: "Алматы",
          value: "almaty",
        },
        {
          title: "Нұр-Сұлтан",
          value: "nursultan",
        },
        {
          title: "Шымкент",
          value: "shymkent",
        },
        {
          title: "Ақтау",
          value: "aktau",
        },
      ],
    };
  },
};
</script>

<style>
</style>