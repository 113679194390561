<template>
  <div class="documents-wrap">
    <h1>It's Documents Tab</h1>
    <p>Hey yo</p>
  </div>
</template>

<script>
  export default {
    
  }
</script>

<style>
</style>