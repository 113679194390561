import { http } from './http'
import store from '@/store/index'

export const subscribeToPushAPI = (user_id) => {
  const url = '/v1/subscribe/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const data = {
    "onesignal_user_id": user_id,
    "type": 2,
  };
  console.log('Subscribed to push', data);
  return http.post(url, data, { headers });
}

export const unsubscribeFromPushAPI = (user_id) => {
  const url = '/v1/unsubscribe/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const data = {
    "onesignal_user_id": user_id,
  };
  console.log('Unsubscribed from push', data);
  return http.delete(url, { data, headers });
}