<template>
  <v-app>
    <v-app-bar app color="white" elevate-on-scroll flat>
      <Header @toggleSidebar="toggleSidebar" />
    </v-app-bar>
    <v-navigation-drawer app v-model="sidebar" width="176px">
      <Sidebar />
    </v-navigation-drawer>

    <v-main>
      <router-view/>
      <transition name="fade">
        <Loading />
      </transition>
      <Snackbar />
    </v-main>
  </v-app>
</template>

<script>
import Header from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import { subscribeToPushAPI, unsubscribeFromPushAPI, setExternalUserIdAPI } from '@/api/one-signal'

export default {
  components: {
    Header,
    Sidebar,
    Loading: () => import('@/components/loading.vue'),
    Snackbar: () => import('@/components/Snackbar.vue'),
  },
  data: () => ({
    sidebar: true,
  }),
  created() {
    if (this.$vuetify.breakpoint.mdAndDown) {
      this.sidebar = false;
    };
  },
  beforeMount() {
    // Calculating refresh token expiration
    let refreshToken = this.$store.state.user.refresh;
    let refreshTokenParsed = JSON.parse(window.atob(refreshToken.split('.')[1]));
    let currentTime = this.$moment().format();
    let expiresAtSeconds = refreshTokenParsed.exp;
    let currentTimeSeconds = this.$moment(currentTime).unix();
    if (currentTimeSeconds >= expiresAtSeconds) {
      this.$store.dispatch("user/logout");
      this.$store.commit("DEL_CURRENT_COMPLEX");
      this.$router.push({ name: "Login" });
      this.$store.commit('SHOW_SNACKBAR', 'tokenExpiredErrorSnackbar');
      this.$store.commit('SET_SNACKBAR_TITLE', 'Время сессии истекло, пройдите авторизацию');
      this.$store.commit('SET_SNACKBAR_COLOR', 'error');
    };
    
    // OneSignal settings
    this.$OneSignal.init({
      appId: process.env.VUE_APP_ONE_SIGNAL_APP_ID, // for localhost "40d4cef8-57e1-4cce-9b36-31aed104dde6"
      safari_web_id: process.env.VUE_APP_ONE_SIGNAL_SAFARI_ID, // "web.onesignal.auto.08e72fe8-7d9e-49e9-8aad-204d649fdf9c"
      promptOptions: {
        actionMessage: "Добро пожаловать на domme.kz! Разрешите отправку уведомлений, чтобы быть в курсе событий.", // limit 90 characters
        exampleNotificationCaption: "Вы можете отписаться (кнопка в правом нижнем углу)",
        acceptButton: "Разрешить",
        cancelButton: "Отклонить",
      },
      welcomeNotification: {
        message: "Спасибо за подписку!",
      },
      notificationClickHandlerMatch: 'origin',
      allowLocalhostAsSecureOrigin: true,
    });

    // Getting user's ID from state
    const userState = JSON.stringify(this.$store.state.user.user.user.id);

    // Subscribes user to notification if browser allowed notifications at first time
    this.$OneSignal.on('notificationPermissionChange', function(permissionChange) {
      OneSignal.on('subscriptionChange', function (isSubscribed) {
        OneSignal.getUserId((user_id) => {
          if (permissionChange.to == 'granted' && isSubscribed === true) {
            subscribeToPushAPI(user_id);
            OneSignal.setExternalUserId(userState);
            console.log("Access granted 1st time");
          };
        });
      });
    });

    // Subscribes user to notification if browser already allowed notifications
    this.$OneSignal.getNotificationPermission((permission) => {
      OneSignal.isPushNotificationsEnabled((isEnabled) => {
        OneSignal.getUserId((user_id) => {
          if (permission === 'granted' && isEnabled === false) {
            subscribeToPushAPI(user_id);
            OneSignal.setExternalUserId(userState);
            OneSignal.setSubscription(true);
            console.log("After access granted");
          };
        });
      });
    });

    // Navigate to ticket when notification is clicked
    this.$OneSignal.addListenerForNotificationOpened((data) => {
      console.log('Data of clicked notification', data);
      let additionalData = data.data;
      if (additionalData.ticket_id) {
        this.$router.push(`/tickets/${additionalData.ticket_id}`);
      } else if (additionalData.ticketId) {
        this.$router.push(`/tickets/${additionalData.ticketId}`);
      };
    });
  },
  methods: {
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    }
  },
}
</script>

<style lang="scss" scoped>
.v-main {
  padding: 80px 0 0 176px !important;
}

@media only screen and (max-width: 1263px) {
  .v-main {
    padding: 80px 0 0 0 !important;
  }
}
</style>
