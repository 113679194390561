import { http } from './http'
import store from '@/store/index'

export const personalAccountsApi = async (params) => {
  store.commit('ADD_LOADING', 'users.personalAccountsApi');
  const url = '/v1/users/personal-accounts';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers, params })
    .catch((e) => {
      store.commit('DEL_LOADING', 'users.personalAccountsApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'users.personalAccountsApi');
  return res;
};

export const personalAccountsIdApi = async (id) => {
  store.commit('ADD_LOADING', 'users.personalAccountsApi');
  const url = `/v1/users/personal-accounts/${id}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'users.personalAccountsApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'users.personalAccountsApi');
  return res;
};

export const removeUserApi = async (building_id) => {
  store.commit('ADD_LOADING', 'users.removeUserApi');
  const url = `/v1/buildings/delete_building_owner/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  const data = {
    building: building_id,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data, {headers})
      .catch((e) => {
        store.commit('DEL_LOADING', 'users.removeUserApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'users.removeUserApi');
  return res;
};

export const removePersonalApi = async (id) => {
  store.commit('ADD_LOADING', 'users.removePersonalApi');
  const url = `/v1/buildings/delete_personal_account/${id}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.delete(url,  {headers})
      .catch((e) => {
        store.commit('DEL_LOADING', 'users.removePersonalApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'users.removePersonalApi');
  return res;
};

export const changePassword = async (data) => {
  store.commit('ADD_LOADING', 'users.changePassword');
  const url ="/v1/change_password/";
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.put(url,  data,{headers})
      .catch((e) => {
        store.commit('DEL_LOADING', 'users.changePassword');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'users.changePassword');
  return res;
};

export const sendOtp = async (phone) => {
  store.commit('ADD_LOADING', 'users.sendOtp');
  const url = `/v1/reset_password`;
  const data = {
    phone: phone,
  };
  const res = await http.post(url, data)
      .catch((e) => {
        store.commit('DEL_LOADING', 'users.sendOtp');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'users.sendOtp');
  return res;
};

export const resetPassword = async (data) => {
  store.commit('ADD_LOADING', 'users.resetPassword');
  const url = `/v1/verification/reset_password`;
  const res = await http.post(url, data)
      .catch((e) => {
        store.commit('DEL_LOADING', 'users.resetPassword');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'users.resetPassword');
  return res;
};
