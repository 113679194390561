import { http } from './http'
import store from '@/store/index'

export const applicationApi = async (filter,page,page_size) => {
  store.commit('ADD_LOADING', 'applications.applicationApi');
  const url = `/v1/tickets/ticket?page_size=${page_size}&page=${page}`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const params = filter;
  const res = await http.get(url, { headers, params })
    .catch((e) => {
      store.commit('DEL_LOADING', 'applications.applicationApi');
      Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'applications.applicationApi');
  return res;
}

export const getAppStatusesApi = () => {
  const url = '/v1/tickets/get_ticket_statuses'
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  return http.get(url, { headers })
}
export const getImportantTicketNum = () => {
  const url = '/v1/tickets/importance_count'
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  return http.get(url, { headers })
}

export const getStatusListApi = () => {
  const url = '/v1/references/list_ticket_status'
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  return http.get(url, { headers })
}

export const setStatusApi = (ticket, status) => {
  const url = '/v1/tickets/status/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const data = {
    ticket: ticket,
    status: status,
  };
  return http.post(url, data, { headers });
}

export const getApplicationIdApi = async (application_id) => {
  store.commit('ADD_LOADING', 'applications.getApplicationIdApi')
  const url = `/v1/tickets/ticket?ticket_id=${application_id}`
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  // const params = { application_id }
  const res = await http.get(url, { headers})
    .catch((e) => {
      store.commit('DEL_LOADING', 'applications.getApplicationIdApi');
      Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'applications.getApplicationIdApi')
  return res
}

export const getApplicationType = async () => {
  store.commit('ADD_LOADING', 'applications.getApplicationType')
  const url = '/v1/tickets/ticket_type/'
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
  store.commit('DEL_LOADING', 'applications.getApplicationType')
  return res
}

export const addApplicationApi = async (data) => {
  console.log(data);
  store.commit('ADD_LOADING', 'applications.addApplicationApi');
  const url = '/v1/tickets/ticket/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'applications.addApplicationApi');
      Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'applications.addApplicationApi');
  // console.log(res);
  return res;
}

export const updateExecutorApi = async (data) => {
  console.log(data);
  store.commit('ADD_LOADING', 'applications.updateExecutorApi');
  const url = '/v1/tickets/executor/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data, { headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'applications.updateExecutorApi');
        Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'applications.updateExecutorApi');
  return res;
}

export const getApplicationAttachmentApi = async () => {
  store.commit('ADD_LOADING', 'comments.getApplicationAttachmentApi')
  const url = `/v1/tickets/attachment/`
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
  store.commit('DEL_LOADING', 'comments.getApplicationAttachmentApi')
  return res
}

export const getAppStatusByIDApi = async (id) => {
  store.commit('ADD_LOADING', 'comments.getAppStatusByIDApi')
  const url = `/v1/tickets/get_ticket_status/${id}/`
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
  store.commit('DEL_LOADING', 'comments.getAppStatusByIDApi')
  return res
}

export const updateTicketImportanceAPI = async (data) =>{
  store.commit('ADD_LOADING', 'applications.updateTicketImportanceAPI');
  const url = '/v1/tickets/update_importance/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.put(url, data.data, { headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'applications.updateTicketImportanceAPI');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'applications.updateTicketImportanceAPI');
  return res
}

export const getTicketReportApi = (id) => {
  store.commit('ADD_LOADING', 'tickets.getTicketReportApi');
  const url = `/v1/tickets/reports?ticket_id=${id}`
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  store.commit('DEL_LOADING', 'tickets.getTicketReportApi');
  return http.get(url, { headers })
}