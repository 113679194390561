<template>
  <div>
    <v-dialog
      v-model="createTaskDialog"
      @click:outside="closeTaskDialog"
      scrollable
      width="485px"
    >
      <v-card class="create-task-card">
        <v-card-title class="jc-sb" :style="{ color: titleColor, background: titleBgColor }">
          <span> {{ isCreatingTask ? 'Создать событие' : 'Просмотр события' }} </span>
          <v-btn icon @click="closeTaskDialog" :color="titleColor">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="newTaskForm" :disabled="onSubmitTask">
            <div class="mb-5">
              <v-text-field
                v-model="formTask.title"
                :readonly="!isCreatingTask"
                :rules="newTaskRules"
                label="Заголовок"
                hide-details
                outlined
                required
              ></v-text-field>
            </div>
            <div v-if="isCreatingTask" class="task-pick-color mb-5">
              <div class="task-subtitle"> Выберите цвет </div>
              <v-color-picker
                v-model="pickedColor"
                :value="pickedColor"
                :swatches="availableColors"
                show-swatches
                hide-canvas
                hide-sliders
                hide-inputs
              ></v-color-picker>
            </div>
            <div class="mb-3">
              <div class="task-subtitle mb-3"> Список предметов и услуг </div>
              <div
                v-if="formSubtasks.length === 0 && isCreatingTask"
                class="add-task-text mb-3"
              >
                Нажмите кнопку ниже чтобы добавить новое поле
              </div>
              <div v-if="formSubtasks.length > 0">
                <v-row
                  v-for="(subTask, i) in formSubtasks"
                  :key="i"
                  no-gutters
                  class="mb-4"
                >
                  <v-col cols="12" class="pb-2">
                    <v-text-field
                      v-model="formSubtasks[i].sub_title"
                      :readonly="!isCreatingTask"
                      :rules="newTaskRules"
                      label="Наименование"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="8">
                    <v-text-field
                      v-model="formSubtasks[i].sub_comment"
                      label="Комментарий"
                      :readonly="!isCreatingTask"
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="pl-2">
                    <v-text-field
                      v-model="formSubtasks[i].sub_sum"
                      append-icon="mdi-currency-kzt"
                      :readonly="!isCreatingTask"
                      :rules="newTaskRules"
                      label="Сумма"
                      type="number"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-if="isCreatingTask" class="text-center">
                <v-btn text color="primary" @click="addSubtask"> Добавить поле </v-btn>
              </div>
            </div>
            <div class="task-sum-title mb-5">
              <pre class="task-subtitle">Итого: </pre>
              <span class="task-sum"> {{totalSum}} </span>
              <v-icon color="#2A2E52" size="20">mdi-currency-kzt</v-icon>
            </div>
            <v-row no-gutters>
              <v-col cols="12" md="6" class="pr-1">
                <v-menu
                  v-model="taskStartDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :disabled="!isCreatingTask"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="startDateFormattedMomentjs"
                      label="Начало события"
                      prepend-inner-icon="mdi-calendar"
                      :rules="newTaskRules"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      outlined
                      hide-details
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formTask.start_date"
                    @input="taskStartDateMenu = false"
                    :max="formTask.end_date || undefined"
                    first-day-of-week="1"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" class="pl-1">
                <v-menu
                  v-model="taskEndDateMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  :disabled="!isCreatingTask"
                  offset-y
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="endDateFormattedMomentjs"
                      label="Конец события"
                      prepend-inner-icon="mdi-calendar"
                      :rules="newTaskRules"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      outlined
                      hide-details
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="formTask.end_date"
                    @input="taskEndDateMenu = false"
                    :min="formTask.start_date || new Date().toISOString()"
                    first-day-of-week="1"
                    no-title
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" class="pt-2">
                <v-textarea
                  v-model="formTask.comment"
                  label="Комментарий к событию"
                  :readonly="!isCreatingTask"
                  outlined
                  hide-details
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="isCreatingTask">
          <v-btn
            color="error"
            @click="closeTaskDialog"
            :disabled="onSubmitTask"
            :loading="onSubmitTask"
          > Отмена </v-btn>
          <v-btn
            color="primary"
            @click="submitTask"
            :disabled="
              formSubtasks.length === 0 ||
              onSubmitTask
            "
            :loading="onSubmitTask"
          > Добавить </v-btn>
        </v-card-actions>
        <v-card-actions v-else-if="!isTaskClosed">
          <v-btn
            color="error"
            text
            @click="closeTaskDialog"
          > Отмена </v-btn>
          <v-btn
            color="error"
            @click="$emit('emitTaskComplete', formTask.id, formTask.title)"
          > Завершить событие </v-btn>
        </v-card-actions>
        <v-card-actions v-else-if="isTaskClosed">
          <div class="task-completed-text">
            Событие завершено
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { addTaskApi } from "@/api/tasks";

export default {
  data: () => ({
    formTask: {},
    formSubtasks: [],
    dialogType: "create",
    createTaskDialog: false,
    onSubmitTask: false,
    pickedColor: "#5B6CFF",
    availableColors: [['#FF7171'], ['#FAC640'], ['#64DCA2'], ['#5B6CFF'], ['#B75BFF']],
    taskStartDateMenu: false,
    taskEndDateMenu: false,
    newTaskRules: [ v => !!v || 'Обязательное поле' ],
  }),
  computed: {
    isCreatingTask() {
      return this.dialogType === "create" ? true : false;
    },
    isTaskClosed() {
      return this.formTask.is_completed;
    },
    titleColor() {
      if (this.dialogType !== "create") {
        if (
          this.formTask?.color === "#FAC640" ||
          this.formTask?.color === "#64DCA2"
        ) {
          return "#2A2E52";
        } else if (
          this.formTask?.color === "#FF7171" ||
          this.formTask?.color === "#5B6CFF" ||
          this.formTask?.color === "#B75BFF"
        ) return "#FFFFFF";
      } else return "#2A2E52";
    },
    titleBgColor() {
      if (this.formTask?.color && this.dialogType !== "create") {
        let bgColor = this.formTask.color;
        return bgColor;
      } else return "transparent";
    },
    totalSum() {
      return this.formSubtasks.reduce((acc, cur) => {
        let sum = acc;
        sum += Number.parseInt(cur.sub_sum);
        return sum ? sum : 0;
      }, 0);
    },
    startDateFormattedMomentjs() {
      return this.formTask.start_date
        ? this.$moment(this.formTask.start_date).format("DD MMMM YYYY")
        : "";
    },
    endDateFormattedMomentjs() {
      return this.formTask.end_date
        ? this.$moment(this.formTask.end_date).format("DD MMMM YYYY")
        : "";
    },
  },
  methods: {
    closeTaskDialog() {
      this.createTaskDialog = false;
      this.formTask = {};
      this.formSubtasks = [];
      this.$refs.newTaskForm.resetValidation();
      this.dialogType = "create";
      this.pickedColor = "#5B6CFF";
    },
    setDataToForm(data) {
      this.dialogType = "view";
      let task = data._task;
      let sub_tasks = data._sub_tasks;
      this.formTask = {...task};
      this.formSubtasks = [...sub_tasks];
    },
    addSubtask() {
      this.formSubtasks.push({ sub_sum: "", sub_title: "" });
    },
    submitTask() {
      this.formTask.title = this.formTask.title ? this.formTask.title.trim() : "";
      this.formSubtasks.forEach(subtask => {
        subtask.sub_title = subtask.sub_title.trim();
      });
      
      if (
        this.isCreatingTask &&
        this.$refs.newTaskForm.validate() === true
      ) {
        this.addTask();
      } else {
        this.$store.commit('SHOW_SNACKBAR', 'submitTaskErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Заполните форму полностью!');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
      };
    },
    async addTask() {
      try {
        this.onSubmitTask = true;
        this.formTask.color = this.pickedColor;
        const data = {
          ...this.formTask,
          sub_tasks: this.formSubtasks,
          current_complex: this.$store.state.currentComplex,
          executor: this.$store.state.user.user?.id, // hardcoded
        };
        await addTaskApi(data);
        this.$emit("updateCalendar");
        this.closeTaskDialog();
        this.$store.commit('SHOW_SNACKBAR', 'submitTaskSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Событие успешно добавлено');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'submitTaskErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при добавлении события');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      } finally {
        this.onSubmitTask = false;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 10px 24px 20px;
}
.v-dialog > .v-card > .v-card__actions {
  padding: 0 24px 16px;
}
.v-card__actions > .v-btn {
  width: calc((100% - 8px) / 2) !important;
}
</style>