<template>
  <div class="flex-space-around">
    <div class="back-btn ml-12" @click="backTo">
      <img src="@/assets/images/back.svg" alt="" />
    </div>
    <div class="login-row">
      <img src="@/assets/images/main-logo.svg" alt="" />
      <div class="slogan">Цифровой помощник в управлении кондоминиумом</div>

      <v-form @submit.prevent="changePassword" class="login-form" ref="loginInput">
        <v-text-field
            v-model="old_password"
            :rules="passwordRules"
            label="Введите текущий пароль"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            color="#5b6cff"
            outlined
            required
            style="width: 400px"
        ></v-text-field>
        <v-text-field
            v-model="new_password"
            :rules="passwordRules"
            label="Новый пароль"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            color="#5b6cff"
            outlined
            required
            style="width: 400px"
        ></v-text-field>
        <v-text-field
            v-model="new_password2"
            :rules="[passwordRules,passwordConfirmationRule]"
            label="Повторите новый пароль"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            color="#5b6cff"
            outlined
            required
            style="width: 400px"
        ></v-text-field>

        <span class="error-message" style="margin-top:360px" v-if="errorMsg">{{ errorMsg }}</span>

        <v-btn
          class="login-button"
          type="submit"
          :disabled="loading"
        >
          <div v-show="loading">
            <v-progress-circular
              indeterminate
              color="#5b6cff"
              size="30"
              width="3"
            ></v-progress-circular>
          </div>
          <div v-show="!loading">Сменить пароль</div>
        </v-btn>
      </v-form>
    </div>
    <v-footer>
      <div class="rights">Domme.kz Все права защищены.</div>
      <div class="contacts">
        <a href="">+7 771 701 72 79</a>
        <a href="mailto:ssn@domme.kz">ssn@domme.kz</a>
      </div>
    </v-footer>
  </div>
</template>
<script>

import {changePassword} from "../api/users";

export default {
  data() {
    return {
      old_password: "",
      new_password: "",
      new_password2: "",
      loading: false,
      errorMsg: "",
      showPassword: false,
      passwordRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length >= 8) || 'Пароль должен быть не менее 8 символов',
      ],
    };
  },
  computed: {
    passwordConfirmationRule(){
      return () => (this.new_password === this.new_password2) || 'Пароли должны совпадать'
    }
  },
  methods: {
    backTo(){
      this.$router.go(-1);
    },
    async changePassword() {
      let _data = {
        new_pass:this.new_password,
        old_pass:this.old_password
      }
      try {
        this.loading = true;
        this.errorMsg = "";
        if (this.$refs.loginInput.validate() === true) {
          await changePassword(_data);
          alert("Вы успешно сменили пароль!")
          this.$store.dispatch('user/logout');
          this.$store.commit('DEL_CURRENT_COMPLEX');
          this.$router.push({ name: 'Login' });
        }
      } catch (error) {
        console.dir(error.response);
        if (error.response.status == 401) {
          this.errorMsg = error.response.data.detail;
        }
        if (error.response.status == 400) {
          this.errorMsg = "Вы ввели неправильный текущий пароль";
          this.new_password=""
          this.new_password2=""
          this.old_password=""
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style>
</style>
