import { http } from './http'
import store from '@/store/index'

export const authUrl = (data) => {
  const url = '/v1/auth/token/'
  return http.post(url, data)
}

export const refreshUrl = (refresh) => {
  const url = '/v1/api/token/refresh/'
  return http.post(url, { refresh })
}

export const logoutUrl = () => {
  const url = '/v1/logout/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  const data = {
    refresh: store.state.user.refresh,
  };
  return http.post(url, data, { headers });
}

export const managerProfile = () => {
  const url = '/v1/manager_profile/'
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  return http.get(url, { headers })
}

export const getUser = (id) => {
  store.commit('ADD_LOADING', 'user.getUser');
  const url = `/v1/get_user/${id}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  store.commit('DEL_LOADING', 'user.getUser');
  return http.get(url, { headers })
}
