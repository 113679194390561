import { http, objectToFormData } from './http'
import store from '@/store/index'

const baseCall = async (url, method = 'get', name, data, headersProp = {}) => {
  store.commit('ADD_LOADING', name);
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
    ...headersProp,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.request({ method, url, data, headers })
    .catch((e) => {
      store.commit('DEL_LOADING', name);
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', name);
  return res;
};

export const getScheduleApi = async () => {
  const name = 'schedule.getScheduleApi';
  const url = '/v1/schedule/schedule';
  return baseCall(url, 'get', name)
}

export const getStaffScheduleApi = async () => {
  const name = 'schedule.getStaffScheduleApi';
  const url = '/v1/schedule/staff_schedule';
  return baseCall(url, 'get', name)
}

export const getStaffScheduleByStaffIdApi = async (id) => {
  const name = 'schedule.getStaffScheduleByStaffIdApi';
  const url = `/v1/schedule/staff_schedule?staff_id=${id}`;
  return baseCall(url, 'get', name)
}

export const addStaffScheduleApi = async (data) => {
  const name = 'schedule.addStaffScheduleApi';
  const url = '/v1/schedule/staff_schedule';
  return baseCall(url, 'post', name, data)
}

export const getStaffWorkScheduleApi = async () => {
  const name = 'schedule.getStaffWorkScheduleApi';
  const url = '/v1/schedule/staff_work_schedule';
  return baseCall(url, 'get', name)
}

export const addStaffWorkScheduleApi = async (data) => {
  const name = 'schedule.addStaffWorkScheduleApi';
  const url = '/v1/schedule/staff_work_schedule';
  return baseCall(url, 'post', name, data)
}
