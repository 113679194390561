<template>
  <div class="flex-space-around">
    <div class="back-btn ml-12" @click="$router.push('Login')">
      <img src="@/assets/images/back.svg" alt="" />
    </div>
    <div class="login-row">
      <img src="@/assets/images/main-logo.svg" alt="" class=""/>
      <div class="slogan" style="margin-bottom: 70px !important;">Цифровой помощник в управлении кондоминиумом</div>
      <v-form class="login-form" ref="loginInput" v-if="!done">
        <span class="error-message password-message" v-if="errorMsg">{{ errorMsg }}</span>
        <span class="success-message password-message" v-if="successMsg">{{ successMsg }}</span>
        <span class="password-message mb-2" v-if="!successMsg && !errorMsg" >Введите ваш номер телефона чтобы сбросить пароль:</span>
        <v-text-field
          v-model="phone"
          :rules="phoneRules"
          label="Номер телефона"
          type="phone"
          v-mask="'+ 7 (###) ###-##-##'"
          placeholder="+ 7 (___) ___-__-__"
          color="#5b6cff"
          outlined
          required
        ></v-text-field>
        <v-text-field
            v-if="successMsg"
            v-model="otp"
            :rules="otpRules"
            label="Код подтверждения"
            type="phone"
            v-mask="'#-#-#-#'"
            placeholder="0-0-0-0"
            color="#5b6cff"
            outlined
            required
        ></v-text-field>
        <div class="btn-row justify-space-between">
          <v-btn
            class="login-button mr-3"
            :disabled="disableBtn"
            @click="sendCode"
            >
            <div v-show="loading">
              <v-progress-circular
                indeterminate
                color="#5b6cff"
                size="30"
                width="3"
              ></v-progress-circular>
            </div>
            <div v-show="!loading && !disableBtn">Отправить код</div>
            <div v-show="disableBtn"> {{disabledTime}} </div>
          </v-btn>
          <v-btn
              class="login-button mr-3"
              :disabled="loading"
              @click="resetPass"
              v-if="successMsg"
          >
            <div v-show="loading">
              <v-progress-circular
                  indeterminate
                  color="#5b6cff"
                  size="30"
                  width="3"
              ></v-progress-circular>
            </div>
            <div v-show="!loading">Готово</div>
          </v-btn>
        </div>
      </v-form>
      <v-form class="login-form" v-if="done">
        <span class="password-message " v-if="mainMsg">{{mainMsg}}</span>
        <v-btn
            class="login-button mr-3"
            :disabled="loading"
            @click="$router.push('Login')"
        >
          <div v-show="loading">
            <v-progress-circular
                indeterminate
                color="#5b6cff"
                size="30"
                width="3"
            ></v-progress-circular>
          </div>
          <div v-show="!loading">Войти</div>
        </v-btn>
      </v-form>
    </div>
    <v-footer>
      <div class="rights">Domme.kz Все права защищены.</div>
      <div class="contacts">
        <a href="">+7 771 701 72 79</a>
        <a href="mailto:ssn@domme.kz">ssn@domme.kz</a>
      </div>
    </v-footer>
  </div>
</template>
<script>
import {sendOtp,resetPassword} from "../api/users";

export default {
  data() {
    return {
      phone: "",
      password: "",
      loading: false,
      disableBtn: false,
      errorMsg: "",
      successMsg: "",
      mainMsg: "",
      showPassword: false,
      done: false,
      disabledTime: 60,
      otp: "",
      phoneRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length >= 19) || 'Неправильный формат номера',
      ],
      otpRules: [
        v => !!v || 'Обязательное поле',
        v => (v && v.length >= 7) || 'Код должен быть не менее 4 символов',
      ],
    };
  },
  methods: {
    async sendCode() {
      const phone = this.phone.replace(/[^0-9.]/g, "");
      try {
        this.loading = true;
        this.errorMsg = "";
        if (this.$refs.loginInput.validate() === true) {
          const res = await sendOtp(phone);
          if (res.data.succes){
            console.log("success")
            this.errorMsg= "";
            this.successMsg= "Код был отправление на номер " + this.phone;
            this.startTimer();

          } else {
            console.log(res.data.succes)
            this.successMsg= "";
            this.errorMsg = "Пользователь с таким номером не найден!";

          }
        }
      }catch (error) {
        console.dir(error.response);
        if (error.response.status == 401) {
          this.errorMsg = error.response.data.detail;
        }
      }
      this.loading = false;
    },
    async resetPass(){
      if (this.$refs.loginInput.validate() === true) {
        const phone = this.phone.replace(/[^0-9.]/g, "");
        const otp = this.otp.replace(/[^0-9.]/g, "");
        const res = await resetPassword({phone: phone, otp: otp});
        this.errorMsg= "";
        this.successMsg= "";
        this.mainMsg="Пароль был изменен." + " " + res.data.message + ". " + "Запишите ваш новый пароль в надежном месте!"
        this.done=true;
        console.log(this.mainMsg);
      }
    },
    startTimer() {
      this.disabledTime = 60;
      this.disableBtn = true;
      const timer = setInterval(() => {
        this.disabledTime -= 1;
        if (this.disabledTime === 0) {
          clearInterval(timer);
          this.disableBtn = false;
          this.disabledTime = 60;
        }
      }, 1000);
    },
  },
};
</script>

<style>
</style>
