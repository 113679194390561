<template>
  <div class="pl-5 pt-5">
    <VoteItem

      title="Лист №47 голосования при проведении онлайн опроса собственников квартир/нежилых помещений"
      start_date="01.09.19"
      end_date="6.04.22"
      vouted_count=85
    />
  </div>
</template>

<script>
import VoteItem from "./VoteItem"

export default {
  name: "Active",
  components: {
    VoteItem
  },
}
</script>

<style scoped>

</style>