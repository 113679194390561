import { http, objectToFormData } from './http'
import store from '@/store/index'

export const  newsApi = async (params,page,page_size) => {
  store.commit('ADD_LOADING', 'news.newsApi');
  let url = `/v1/news/list?page_size=${page_size}&page=${page}`;
  if (params && params.type) {
    url = `/v1/news/list?page_size=${page_size}&page=${page}&type=${params.type}`;
    delete params.type;
  }
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers, params })
      .catch((e) => {
        store.commit('DEL_LOADING', 'news.newsApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'news.newsApi');
  return res;
};

export const newsTypeApi = async (params) => {
  store.commit('ADD_LOADING', 'news.newsTypeApi');
  const url = '/v1/news/type';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers, params })
    .catch((e) => {
      store.commit('DEL_LOADING', 'news.newsTypeApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'news.newsTypeApi');
  return res;
};

export const newsIdApi = async (id) => {
  store.commit('ADD_LOADING', 'news.newsIdApi');
  const url = `/v1/news/get_news/${id}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'news.newsIdApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'news.newsIdApi');
  return res;
};

export const newsAddApi = async (data) => {
  console.log("news",data)
  store.commit('ADD_LOADING', 'news.newsAddApi');
  const url = '/v1/news/news/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data, { headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'news.newsAddApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'news.newsAddApi');
  return res;
};

export const newsEndApi = async (data) => {
  store.commit('ADD_LOADING', 'news.newsEndApi');
  const url = `/v1/news/close_poll/${data.id}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data, { headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'news.newsEndApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'news.newsEndApi');
  return res;
};

export const newsAddPhotoApi = async (data) => {
  console.log('data',data);
  store.commit('ADD_LOADING', 'news.newsAddPhotoApi');
  const url = '/v1/news/images/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
    'Content-Type': 'multipart/form-data',
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  // const formDataParams = objectToFormData(data);
  const res = await http.post(url, data, { headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'news.newsAddPhotoApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'news.newsAddPhotoApi');
  return res;
};

export const getNewsPhotoApi = async (id) => {
  store.commit('ADD_LOADING', 'news.getNewsPhotoApi');
  const url = `/v1/news/get_news_photo/${id}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'news.getNewsPhotoApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'news.getNewsPhotoApi');
  return res;
};

export const getNewsCommentsApi = async (id,app_label,size,page,model) => {
  store.commit('ADD_LOADING', 'comments.getCommentsApi')
  const url = `/v1/comments/comment/?page_size=${size}&object_id=${id}&app_label=${app_label}&model=${model}&page=${page}&ordering=-created_at`
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
  store.commit('DEL_LOADING', 'comments.getCommentsApi')
  return res
}
