var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('v-row',{staticClass:"side-paddings",attrs:{"no-gutters":""}},[_c('div',{staticClass:"tab-row"},[_c('div',{staticClass:"tab-item",class:[_vm.activetab === '1' ? 'active' : ''],on:{"click":function($event){_vm.activetab = '1'}}},[_vm._v(" Финансовый поток ")]),_c('div',{staticClass:"tab-item",class:[_vm.activetab === '3' ? 'active' : ''],on:{"click":function($event){_vm.activetab = '3'}}},[_vm._v(" Тарифы ")])])]),(_vm.activetab === '1')?_c('div',{staticClass:"finance"},[_c('div',{staticClass:"finance-balance"},[_c('div',{staticClass:"finance-balance-item"},[_c('div',{staticClass:"left"},[_vm._v("Остаток на текущем счете")]),_c('div',{staticClass:"saving-balance"},[_c('div',{staticStyle:{"margin-right":"10px"}},[_vm._v(" "+_vm._s(parseFloat(_vm.savingBalance).toLocaleString('ru-RU'))+" ₸ ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#aaabb9","size":"30px"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,1529532420)},[_c('v-list',[_c('v-list-item',[_c('EditSavingBalance',{ref:"savingBalanceForm",attrs:{"savingBalance":_vm.savingBalance},on:{"emitUpdateSavingBalance":_vm.updateSavingBalance}})],1)],1)],1)],1)])]),_c('div',{staticClass:"finance-date"},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Выберите месяц","prepend-inner-icon":"mdi-calendar","value":_vm.computedDateFormattedMomentjs,"readonly":"","hide-details":"","outlined":"","background-color":"#eff0ff"}},'v-text-field',attrs,false),on))]}}],null,false,1513315432),model:{value:(_vm.datePickerDialog),callback:function ($$v) {_vm.datePickerDialog=$$v},expression:"datePickerDialog"}},[_c('v-date-picker',{attrs:{"type":"month"},on:{"input":function($event){_vm.selectedDate = new Date(_vm.date),
              _vm.getFilterByPeriod,
              _vm.datePickerDialog = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),(_vm.isClosed)?_c('div',{staticClass:"share-with-residents"},[_c('span',{staticClass:"share-reminder"},[_vm._v("Внесённые изменения сохранены")])]):_c('div',{staticClass:"share-with-residents"},[_c('span',{staticClass:"share-reminder"},[_vm._v("Последние изменения не опубликованы")]),_c('v-btn',{attrs:{"disabled":_vm.debits.length === 0 || _vm.credits.length === 0},on:{"click":_vm.shareWithResidents}},[_vm._v(" Опубликовать для жителей ")])],1)],1),_c('div',{staticClass:"finance-operations"},[_c('div',{staticClass:"io-item"},[_c('div',{staticClass:"name"},[_vm._v("Текущие доходы")]),_c('div',{staticClass:"sum"},[_vm._v(_vm._s(parseFloat(_vm.totalDebit).toLocaleString('ru-RU'))+" ₸")]),_vm._l((_vm.debits),function(debit){return _c('v-card',{key:debit.finance_operation.id,staticClass:"accardeon"},[_c('v-card',{staticClass:"finance-operation-item",on:{"click":function($event){return _vm.toggleAccordeons(debit.finance_operation.id)}}},[_c('div',{staticClass:"left"},[_c('v-icon',{attrs:{"color":"primary","size":"36px"}},[_vm._v(" "+_vm._s(_vm.openedAccordeons.includes(debit.finance_operation.id) ? "mdi-chevron-up" : "mdi-chevron-down")+" ")]),_vm._v(" "+_vm._s(debit.finance_operation.title)+" ")],1),_c('div',{staticClass:"right"},[_c('div',{staticStyle:{"padding":"8px"}},[_vm._v(" "+_vm._s(parseFloat(debit.finance_operation.amount).toLocaleString('ru-RU'))+" ₸ ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#aaabb9","size":"30px"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('EditFinanceOperation',{attrs:{"operationType":debit.finance_operation.type,"operationId":debit.finance_operation.id,"operationYear":_vm.year,"operationMonth":_vm.month,"operationTitle":debit.finance_operation.title,"operationAmount":debit.finance_operation.amount,"operationDetails":debit.details},on:{"emitUpdateOperation":_vm.updateOperation}})],1),_c('v-list-item',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.toggleDeleteOperation(debit)}}},[_vm._v("Удалить")])],1)],1)],1)],1)]),_c('v-expand-transition',[(_vm.openedAccordeons.includes(debit.finance_operation.id))?_c('div',{staticClass:"operation-details"},[_c('v-divider'),_vm._l((debit.details),function(detail){return _c('div',{key:detail.id,staticClass:"operation-details-item"},[_c('div',{staticClass:"operation-details-title"},[_vm._v(" "+_vm._s(detail.title)+" ")]),_c('div',{staticClass:"operation-details-amount"},[_vm._v(" "+_vm._s(parseFloat(detail.amount).toLocaleString('ru-RU'))+" ₸ ")])])}),_c('v-row',{staticClass:"add-detail-button",attrs:{"no-gutters":""}},[_c('v-dialog',{attrs:{"max-width":"600px"},on:{"click:outside":function($event){return _vm.closeAddDetailDialog(debit.finance_operation.id)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" + Добавить поле ")])]}}],null,true),model:{value:(_vm.addDetailDialog[debit.finance_operation.id]),callback:function ($$v) {_vm.$set(_vm.addDetailDialog, debit.finance_operation.id, $$v)},expression:"addDetailDialog[debit.finance_operation.id]"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Добавить поле в "+_vm._s(("\"" + (debit.finance_operation.title) + "\"")))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"addDetailForm",refInFor:true,attrs:{"disabled":_vm.submitForm}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"label":"Описание","rules":_vm.operationRules,"hide-details":"","required":"","outlined":""},model:{value:(_vm.detailTitle),callback:function ($$v) {_vm.detailTitle=$$v},expression:"detailTitle"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Сумма","append-icon":"mdi-currency-kzt","type":"number","rules":_vm.operationRules,"hide-details":"","required":"","outlined":""},model:{value:(_vm.detailAmount),callback:function ($$v) {_vm.detailAmount=$$v},expression:"detailAmount"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.submitForm,"loading":_vm.submitForm},on:{"click":function($event){return _vm.addOperationDetail({
                            finance_operation: debit.finance_operation.id,
                            title: _vm.detailTitle,
                            amount: _vm.detailAmount,
                            forOperationUpdate: debit.finance_operation,
                          })}}},[_vm._v(" Добавить ")]),_c('v-btn',{attrs:{"color":"error","text":"","disabled":_vm.submitForm,"loading":_vm.submitForm},on:{"click":_vm.closeAddDetailDialog}},[_vm._v(" Отмена ")])],1)],1)],1)],1)],2):_vm._e()])],1)}),_c('v-dialog',{attrs:{"max-width":"600px"},on:{"click:outside":_vm.closeAddOperationDialog},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"add-finance-operation",attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Добавить доход ")])]}}],null,false,2795449973),model:{value:(_vm.addIncomeDialog),callback:function ($$v) {_vm.addIncomeDialog=$$v},expression:"addIncomeDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Добавление дохода")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"addOperationForm",attrs:{"disabled":_vm.submitForm}},[_c('v-row',{staticClass:"add-finance-operation-field",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"label":"Введите наименование дохода","rules":_vm.operationRules,"hide-details":"","required":"","outlined":""},model:{value:(_vm.operationTitle),callback:function ($$v) {_vm.operationTitle=$$v},expression:"operationTitle"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-tooltip',{attrs:{"max-width":"300","color":"warning","disabled":_vm.isDetailsDisabled,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Сумма","append-icon":"mdi-currency-kzt","readonly":!_vm.isDetailsDisabled,"rules":_vm.operationRules,"hide-details":"","required":"","outlined":""},model:{value:(_vm.operationAmount),callback:function ($$v) {_vm.operationAmount=$$v},expression:"operationAmount"}},'v-text-field',attrs,false),on))]}}],null,false,1015872712)},[_c('span',{staticClass:"tooltip-text"},[_vm._v(" По умолчанию данное поле отключено. Заполняя дополнительные поля рассчёт идёт автоматически."),_c('br'),_vm._v(" Если у данной операции не должно быть дополнительных полей отметьте галочку ниже ")])])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Данная операция не имеет подпунктов")])]},proxy:true}],null,false,3053456480),model:{value:(_vm.isDetailsDisabled),callback:function ($$v) {_vm.isDetailsDisabled=$$v},expression:"isDetailsDisabled"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.submitForm,"loading":_vm.submitForm},on:{"click":function($event){_vm.addFinanceOperation({
                    type: 0,
                    title: _vm.operationTitle,
                    amount: _vm.operationAmount,
                    month: JSON.stringify(_vm.month),
                    year: JSON.stringify(_vm.year),
                  })}}},[_vm._v(" Сохранить ")]),_c('v-btn',{attrs:{"color":"error","text":"","disabled":_vm.submitForm,"loading":_vm.submitForm},on:{"click":_vm.closeAddOperationDialog}},[_vm._v(" Отмена ")])],1)],1)],1)],2),_c('div',{staticClass:"io-item"},[_c('div',{staticClass:"name"},[_vm._v("Текущие расходы")]),_c('div',{staticClass:"sum"},[_vm._v(_vm._s(parseFloat(_vm.totalCredit).toLocaleString('ru-RU'))+" ₸")]),_vm._l((_vm.credits),function(credit){return _c('v-card',{key:credit.finance_operation.id,staticClass:"accardeon"},[_c('v-card',{staticClass:"finance-operation-item",on:{"click":function($event){return _vm.toggleAccordeons(credit.finance_operation.id)}}},[_c('div',{staticClass:"left"},[_c('v-icon',{attrs:{"color":"primary","size":"36px"}},[_vm._v(" "+_vm._s(_vm.openedAccordeons.includes(credit.finance_operation.id) ? "mdi-chevron-up" : "mdi-chevron-down")+" ")]),_vm._v(" "+_vm._s(credit.finance_operation.title)+" ")],1),_c('div',{staticClass:"right"},[_c('div',{staticStyle:{"padding":"8px"}},[_vm._v(" "+_vm._s(parseFloat(credit.finance_operation.amount).toLocaleString('ru-RU'))+" ₸ ")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#aaabb9","size":"30px"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('EditFinanceOperation',{attrs:{"operationType":credit.finance_operation.type,"operationId":credit.finance_operation.id,"operationYear":_vm.year,"operationMonth":_vm.month,"operationTitle":credit.finance_operation.title,"operationAmount":credit.finance_operation.amount,"operationDetails":credit.details},on:{"emitUpdateOperation":_vm.updateOperation}})],1),_c('v-list-item',[_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.toggleDeleteOperation(credit)}}},[_vm._v("Удалить")])],1)],1)],1)],1)]),_c('v-expand-transition',[(_vm.openedAccordeons.includes(credit.finance_operation.id))?_c('div',{staticClass:"operation-details"},[_c('v-divider'),_vm._l((credit.details),function(detail){return _c('div',{key:detail.id,staticClass:"operation-details-item"},[_c('div',{staticClass:"operation-details-title"},[_vm._v(" "+_vm._s(detail.title)+" ")]),_c('div',{staticClass:"operation-details-amount"},[_vm._v(" "+_vm._s(parseFloat(detail.amount).toLocaleString('ru-RU'))+" ₸ ")])])}),_c('v-row',{staticClass:"add-detail-button",attrs:{"no-gutters":""}},[_c('v-dialog',{attrs:{"max-width":"600px"},on:{"click:outside":function($event){return _vm.closeAddDetailDialog(credit.finance_operation.id)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" + Добавить поле ")])]}}],null,true),model:{value:(_vm.addDetailDialog[credit.finance_operation.id]),callback:function ($$v) {_vm.$set(_vm.addDetailDialog, credit.finance_operation.id, $$v)},expression:"addDetailDialog[credit.finance_operation.id]"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Добавить поле в "+_vm._s(("\"" + (credit.finance_operation.title) + "\"")))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"addDetailForm",refInFor:true,attrs:{"disabled":_vm.submitForm}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"label":"Описание","rules":_vm.operationRules,"hide-details":"","required":"","outlined":""},model:{value:(_vm.detailTitle),callback:function ($$v) {_vm.detailTitle=$$v},expression:"detailTitle"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Сумма","append-icon":"mdi-currency-kzt","type":"number","rules":_vm.operationRules,"hide-details":"","required":"","outlined":""},model:{value:(_vm.detailAmount),callback:function ($$v) {_vm.detailAmount=$$v},expression:"detailAmount"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.submitForm,"loading":_vm.submitForm},on:{"click":function($event){return _vm.addOperationDetail({
                            finance_operation: credit.finance_operation.id,
                            title: _vm.detailTitle,
                            amount: _vm.detailAmount,
                            forOperationUpdate: credit.finance_operation,
                          })}}},[_vm._v(" Добавить ")]),_c('v-btn',{attrs:{"color":"error","text":"","disabled":_vm.submitForm,"loading":_vm.submitForm},on:{"click":_vm.closeAddDetailDialog}},[_vm._v(" Отмена ")])],1)],1)],1)],1)],2):_vm._e()])],1)}),_c('v-dialog',{attrs:{"max-width":"600px"},on:{"click:outside":_vm.closeAddOperationDialog},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"add-finance-operation",attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" Добавить расход ")])]}}],null,false,3006445998),model:{value:(_vm.addOutcomeDialog),callback:function ($$v) {_vm.addOutcomeDialog=$$v},expression:"addOutcomeDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Добавление расхода")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"addOperationForm",attrs:{"disabled":_vm.submitForm}},[_c('v-row',{staticClass:"add-finance-operation-field",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"label":"Введите наименование расхода","hide-details":"","required":"","outlined":""},model:{value:(_vm.operationTitle),callback:function ($$v) {_vm.operationTitle=$$v},expression:"operationTitle"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-tooltip',{attrs:{"max-width":"300","color":"warning","disabled":_vm.isDetailsDisabled,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Сумма","append-icon":"mdi-currency-kzt","readonly":!_vm.isDetailsDisabled,"rules":_vm.operationRules,"hide-details":"","required":"","outlined":""},model:{value:(_vm.operationAmount),callback:function ($$v) {_vm.operationAmount=$$v},expression:"operationAmount"}},'v-text-field',attrs,false),on))]}}],null,false,1015872712)},[_c('span',{staticClass:"tooltip-text"},[_vm._v(" По умолчанию данное поле отключено. Заполняя дополнительные поля рассчёт идёт автоматически."),_c('br'),_vm._v(" Если у данной операции не должно быть дополнительных полей отметьте галочку ниже ")])])],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("Данная операция не имеет подпунктов")])]},proxy:true}],null,false,3053456480),model:{value:(_vm.isDetailsDisabled),callback:function ($$v) {_vm.isDetailsDisabled=$$v},expression:"isDetailsDisabled"}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.submitForm,"loading":_vm.submitForm},on:{"click":function($event){_vm.addFinanceOperation ({
                    type: 1,
                    title: _vm.operationTitle,
                    amount: _vm.operationAmount,
                    month: JSON.stringify(_vm.month),
                    year: JSON.stringify(_vm.year),
                  })}}},[_vm._v(" Сохранить ")]),_c('v-btn',{attrs:{"color":"error","text":"","disabled":_vm.submitForm,"loading":_vm.submitForm},on:{"click":_vm.closeAddOperationDialog}},[_vm._v(" Отмена ")])],1)],1)],1)],2)])]):_vm._e(),(_vm.activetab === '3')?_c('div',{staticClass:"finance-box"},[_c('vc-donut',{staticStyle:{"padding":"80px 30px"},attrs:{"sections":_vm.sections,"total":_vm.total,"size":200,"thickness":6}},[_c('h3',[_vm._v(_vm._s(_vm.total)+" ₸")]),_c('div',{staticClass:"price",staticStyle:{"font-size":"14px"}},[_vm._v("за м"),_c('sup',[_c('small',[_vm._v("2")])])])]),_c('v-row',{staticStyle:{"display":"flex","flex-direction":"column","padding":"0 30px"},attrs:{"no-gutters":""}},[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.tariffs),function(tariff){return _c('div',{key:tariff.id,staticStyle:{"justify-content":"space-around"}},[_c('div',{staticClass:"pick-executor mr-3 mb-4",staticStyle:{"width":"382px !important"}},[_c('div',{staticClass:"d-flex left align-center"},[_c('div',{staticClass:"rounded-circle pa-1 mr-1 flex-nowrap",staticStyle:{"resize":"none","height":"6px !important"},style:({color:tariff.color,background:tariff.color})}),_c('EditFinanceName',{staticClass:"mr-2",attrs:{"tariff":tariff},on:{"update":_vm.updateTariff}})],1),_c('v-row',{staticClass:"justify-end",attrs:{"no-gutters":""}},[_c('EditFinance',{staticClass:"mr-2",attrs:{"tariff":tariff},on:{"update":_vm.updateTariff}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#aaabb9","size":"30px"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"color":"#ff7171","text":""},on:{"click":function($event){return _vm.toggleDeleteTariff(tariff)}}},[_vm._v("Удалить")])],1)],1)],1)],1)],1)])}),0),(_vm.addTariff)?_c('div',{staticClass:"create-tariff pick-executor mr-3 mb-4"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"rounded-circle pa-1 mr-1 flex-nowrap",staticStyle:{"resize":"none","height":"6px !important","cursor":"pointer"},style:({color:_vm.pickedColor,background:_vm.pickedColor}),on:{"click":function($event){_vm.pickColor=!_vm.pickColor}}},'div',attrs,false),on))]}}],null,false,1327909099),model:{value:(_vm.colorPickerHint),callback:function ($$v) {_vm.colorPickerHint=$$v},expression:"colorPickerHint"}},[_c('span',[_vm._v("Выберите цвет")])]),(_vm.pickColor)?_c('v-color-picker',{attrs:{"show-swatches":"","hide-canvas":"","hide-inputs":"","hide-sliders":"","value":_vm.pickedColor},on:{"input":function($event){_vm.pickColor=false}},model:{value:(_vm.pickedColor),callback:function ($$v) {_vm.pickedColor=$$v},expression:"pickedColor"}}):_vm._e(),_c('input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.pickColor),expression:"!pickColor"},{name:"model",rawName:"v-model",value:(_vm.tariffTitle),expression:"tariffTitle"}],staticStyle:{"margin":"0px"},attrs:{"type":"text","placeholder":"Введите название тарифа"},domProps:{"value":(_vm.tariffTitle)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tariffTitle=$event.target.value}}}),_c('div',{staticClass:"price"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tariffPrice),expression:"tariffPrice"}],staticStyle:{"margin":"0px"},attrs:{"type":"text","placeholder":"Сумма"},domProps:{"value":(_vm.tariffPrice)},on:{"input":function($event){if($event.target.composing){ return; }_vm.tariffPrice=$event.target.value}}}),_c('v-icon',{staticStyle:{"padding-right":"4px"},attrs:{"size":"15","color":"#8e90a3"}},[_vm._v(" mdi-currency-kzt ")])],1),(_vm.addTariff)?_c('div',{staticClass:"save-btn",staticStyle:{"width":"30px","height":"30px"},on:{"click":function($event){return _vm.addNewTariff({
              title:_vm.tariffTitle,
              color:_vm.pickedColor,
              price:_vm.tariffPrice
            })}}},[_vm._v(" ✓ ")]):_vm._e(),(_vm.addTariff)?_c('div',{staticClass:"save-btn ml-2",staticStyle:{"width":"30px","height":"30px"},on:{"click":function($event){_vm.addTariff=false}}},[_vm._v(" X ")]):_vm._e()],1):_vm._e(),(!_vm.addTariff)?_c('div',{staticClass:"save-btn mb-4",staticStyle:{"width":"382px","font-size":"20px","height":"47px"},on:{"click":function($event){(_vm.addTariff = true) && (_vm.colorPickerHint = true)}}},[_vm._v(" + ")]):_vm._e()],1)],1):_vm._e(),(_vm.showDeleteOperation)?_c('div',{staticStyle:{"z-index":"10000","position":"absolute"}},[_c('DeletionConfirm',{attrs:{"title":"Вы действительно хотите удалить эту операцию?","description":("" + (_vm.debit.finance_operation.title))},on:{"close":_vm.toggleDeleteOperation,"delete":function($event){return _vm.deleteFinanceOperation({
          id: _vm.debit.finance_operation.id,
          type: _vm.debit.finance_operation.type,
          deletedAmount: _vm.debit.finance_operation.amount,
        })}}})],1):_vm._e(),(_vm.showDeleteTariff)?_c('div',{staticStyle:{"z-index":"10000","position":"absolute"}},[_c('DeletionConfirm',{attrs:{"title":"Вы действительно хотите удалить этот тариф?","description":("" + (_vm.tariff.title))},on:{"close":_vm.toggleDeleteTariff,"delete":function($event){return _vm.deleteTariff(_vm.tariff.id)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }