<template>
  <v-dialog
    v-model="addDepartmentToComplexDialog"
    @click:outside="closeDialog"
    width="415px"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-on="on"
        v-bind="attrs"
        color="primary"
        @click="openDialog"
      >
        Добавить отдел
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="jc-sb">
        <span class="text-h5"> Доступные отделы </span>
        <v-btn icon @click="closeDialog">
          <v-icon> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <div class="card-wrapper">
        <v-skeleton-loader
          v-if="loadingDepartment"
          type="list-item-avatar-two-line"
          width="350px"
        ></v-skeleton-loader>
        <v-container v-if="!loadingDepartment">
          <template v-if="availableDepartmentsList.length > 0">
            <v-card
              v-for="department in availableDepartmentsList"
              :key="department.id"
              class="department-item jc-sb"
              @click="selectedDepartment = department.id"
            >
              <div class="d-flex align-center">
                <v-avatar class="avatar">
                  <v-img src="@/assets/images/service.svg"></v-img>
                </v-avatar>
                <div class="text">
                  <div class="department-name"> {{ department.title }} </div>
                  <div class="department-subtitle"> Нажмите, чтобы добавить отдел </div>
                </div>
              </div>
              <v-icon v-if="selectedDepartment == department.id">
                mdi-check-bold
              </v-icon>
            </v-card>
          </template>
          <template v-if="availableDepartmentsList.length === 0">
            <div class="text-h5 no-departments"> Нет доступных отделов </div>
          </template>
        </v-container>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="addDepartmentToComplex(selectedDepartment)"
          :disabled="selectedDepartment === '' || buttonLoading"
          :loading="buttonLoading"
          color="primary"
        >
          Добавить отдел
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getDepartmentsApi,
  getDepartmentsOfComplexApi,
  addDepartmentToComplexApi,
} from "@/api/departments";

export default {
  data: () => ({
    allDepartmentsList: [],
    departmentsOfComplex: [],
    selectedDepartment: '',
    addDepartmentToComplexDialog: false,
    buttonLoading: false,
    loadingDepartment: true,
  }),
  computed: {
    availableDepartmentsList() {
      return this.allDepartmentsList.filter(
        (allDepartments) =>
          !this.departmentsOfComplex.find(
            (complexDepartment) => complexDepartment.department_id === allDepartments.id
          )
      );
    },
  },
  methods: {
    openDialog() {
      this.addDepartmentToComplexDialog = true;
      this.getAllDepartments();
      this.getDepartmentsOfComplex();
    },
    closeDialog() {
      this.selectedDepartment = "";
      this.addDepartmentToComplexDialog = false;
    },
    async getAllDepartments() {
      this.loadingDepartment = true;
      try {
        let res = await getDepartmentsApi();
        this.allDepartmentsList = [...res.data];
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingDepartment = false;
      };
    },
    async getDepartmentsOfComplex() {
      try {
        let res = await getDepartmentsOfComplexApi();
        this.departmentsOfComplex = [...res.data];
      } catch (err) {
        console.log(err);
      };
    },
    async addDepartmentToComplex(data) {
      try {
        this.buttonLoading = true;
        await addDepartmentToComplexApi({ department: data });
        this.$store.commit('SHOW_SNACKBAR', 'departmentToComplexSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Отдел успешно добавлен');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        this.$emit('updateDepartmentList');
      } catch (err) {
        let errorMessage = err.response.data.message;
        if (errorMessage) {
          this.$store.commit('SHOW_SNACKBAR', 'departmentToComplexErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', errorMessage);
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        }
      } finally {
        this.closeDialog();
        this.buttonLoading = false;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.v-skeleton-loader {
  margin: 0 auto;
}
.v-card--link:focus:before {
  border-radius: 8px;
}
.department-item {
  margin: 0 auto 10px !important;
}
.avatar {
  margin-right: 20px;
}
.department-name {
  padding: 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #2a2e52;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.department-subtitle {
  margin-top: 3px;
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5b6cff;
}
.container {
  padding: 10px 24px !important;
  justify-content: center;
}
.no-departments {
  padding: 15px;
  height: 96px;
  color: #7f8297;
}
.v-dialog > .v-card > .v-card__actions {
  padding: 0 24px 16px;
}
</style>