import { http, objectToFormData } from './http';
import store from '@/store/index';
import placeholder from "lodash/fp/placeholder";

export const getMembers = async () => {
  store.commit('ADD_LOADING', 'buildings.getMembers');
  const url = '/v1/buildings/get_members';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'buildings.getMembers');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'buildings.getMembers');
  return res
}

export const getBuildingTypesApi = async () => {
  store.commit('ADD_LOADING', 'buildings.getBuildingTypesApi');
  const url = '/v1/buildings/building_types';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'buildings.getBuildingTypesApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'buildings.getBuildingTypesApi');
  return res
}

export const getProvidersApi = async () => {
  store.commit('ADD_LOADING', 'buildings.getProvidersApi');
  const url = '/v1/buildings/providers';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'buildings.getProvidersApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'buildings.getProvidersApi');
  return res
}

export const getBlockEnterencesApi = async (id, params) => {
  store.commit('ADD_LOADING', 'buildings.getBlockEnterencesApi');
  const url = `/v1/buildings/get_apartments/${id}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers, params })
    .catch((e) => {
      store.commit('DEL_LOADING', 'buildings.getBlockEnterencesApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'buildings.getBlockEnterencesApi');
  return res
}
// export const getBlockEnterencesApi = async (id, params) => {
//   store.commit('ADD_LOADING', 'buildings.getBlockEnterencesApi');
//   const url = `/v1/buildings/get_entrances/${id}/`;
//   const headers = {
//     'Authorization': `Bearer ${store.state.user.access}`,
//   };
//   if (store.state.currentComplex) {
//     headers['CurrentComplex'] = store.state.currentComplex;
//   }
//   const res = await http.get(url, { headers, params })
//       .catch((e) => {
//         store.commit('DEL_LOADING', 'buildings.getBlockEnterencesApi');
//         return Promise.reject(e);
//       });
//   store.commit('DEL_LOADING', 'buildings.getBlockEnterencesApi');
//   console.log('ahahahha');
//   return res
// }
export const getBlockApartamentsApi = async (id, params) => {
  store.commit('ADD_LOADING', 'buildings.getBlockApartamentsApi');
  const url = `/v1/buildings/get_apartments/${id}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers, params })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.getBlockApartamentsApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.getBlockApartamentsApi');
  return res
}


export const addChessBlock = async (data) => {
  store.commit('ADD_LOADING', 'buildings.addBlock');
  const url = '/v1/companies/blocks';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data,{ headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.addBlock');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.addBlock');
  return res
}

export const addNoteToOwner = async (data) => {
  const url = '/v1/buildings/add_note_to_owner';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data, { headers })
      .catch((e) => {
        return Promise.reject(e);
      });
  return res
}

export const addEntranceApi = async (data) => {
  store.commit('ADD_LOADING', 'buildings.addEntranceApi');
  const url = '/v1/buildings/add_entrance/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'buildings.addEntranceApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'buildings.addEntranceApi');
  return res
}

export const addApartamentApi = async (data) => {
  store.commit('ADD_LOADING', 'buildings.addApartamentApi');
  const url = '/v1/buildings/add_apartment/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data, { headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.addApartamentApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.addApartamentApi');
  return res
}

export const deleteApartamentApi = async (id) => {
  store.commit('ADD_LOADING', 'buildings.deleteApartamentApi');
  const url = `/v1/buildings/delete_building/${id.id}/`;
  console.log(url);
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.delete(url, { headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.deleteApartamentApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.deleteApartamentApi');
  return res
}

export const addFloorApi = async (data) =>{
  store.commit('ADD_LOADING', 'buildings.addFloorApi');
  const url = '/v1/buildings/add_floor/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data, { headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.addFloorApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.addFloorApi');
  return res
}

export const updateBlockApi = async (data) =>{
  store.commit('ADD_LOADING', 'buildings.updateBlockApi');
  const url = '/v1/companies/blocks/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.put(url, data.data, { headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.updateBlockApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.updateBlockApi');
  return res
}

export const deleteFloorApi = async (data) =>{
  store.commit('ADD_LOADING', 'buildings.deleteFloorApi');
  const url = '/v1/buildings/delete_floor/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.delete(url,  { headers,data })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.deleteFloorApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.deleteFloorApi');
  return res
}

/**
 * make multipart/form-data request
 * @param {*} data
 */
export const addBuildingOwner = async (data) => {
  store.commit('ADD_LOADING', 'buildings.addBuildingOwner');
  const url = '/v1/buildings/add_building_owner';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
    'Content-Type': 'multipart/form-data',
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const formDataParams = objectToFormData(data);
  const res = await http.post(url, formDataParams, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'buildings.addBuildingOwner');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'buildings.addBuildingOwner');
  return res;
};

export const addPersonalAccount = async (payload) => {
  console.log(payload)
  store.commit('ADD_LOADING', 'buildings.addPersonalAccount');
  const url = '/v1/buildings/add_personal_account';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, payload,{ headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.addPersonalAccount');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.addPersonalAccount');
  return res
}

export const updatePersonalAccount = async (payload) => {
  console.log(payload.personal_account)
  store.commit('ADD_LOADING', 'buildings.updatePersonalAccount');
  const url = `/v1/buildings/update_personal_account/${payload.old_personal_account}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, payload,{ headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.updatePersonalAccount');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.updatePersonalAccount');
  return res
}

export const updateBuildingOwner = async (payload) => {
  store.commit('ADD_LOADING', 'buildings.updatePersonalAccount');
  const url = `/v1/buildings/update_building_owner/${payload.building}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.put(url, payload,{ headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.updatePersonalAccount');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.updatePersonalAccount');
  return res
}

export const getBuildingOwnerApi = async (id) => {
  store.commit('ADD_LOADING', 'buildings.getBuildingOwnerApi');
  const url = `/v1/buildings/get_building_owner/${id}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.getBuildingOwnerApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.getBuildingOwnerApi');
  return res
}

export const deleteEntranceApi = async (params) => {
  store.commit('ADD_LOADING', 'buildings.deleteEntranceApi');
  const url = `/v1/buildings/delete_entrance/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.delete(url, { headers, params })
    .catch((e) => {
      store.commit('DEL_LOADING', 'buildings.deleteEntranceApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'buildings.deleteEntranceApi');
  return res
}

export const getParkingListApi = async () => {
  store.commit('ADD_LOADING', 'buildings.getParkingListApi');
  const url = '/v1/buildings/get_parking_list';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'buildings.getParkingListApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'buildings.getParkingListApi');
  return res
}
//TODO: need to know API
export const createParking = async (payload) => {
  store.commit('ADD_LOADING', 'buildings.createParking');
  const url = '/v1/buildings/building';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, payload,{ headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.createParking');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.createParking');
  return res
}

export const addParking = async (payload) => {
  store.commit('ADD_LOADING', 'buildings.add_parking');
  const url = '/v1/buildings/add_parking';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, payload,{ headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.add_parking');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.add_parking');
  return res
}

export const addParkingOwner = async (payload) => {
  store.commit('ADD_LOADING', 'buildings.add_parking_owner');
  const url = '/v1/buildings/parking_owner';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, payload,{ headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.add_parking_owner');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.add_parking_owner');
  return res
}

export const deleteParking = async (params) => {
  store.commit('ADD_LOADING', 'buildings.deleteParking');
  const url = `/v1/buildings/delete_parking?${params}`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.delete(url, { headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'buildings.deleteParking');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'buildings.deleteParking');
  return res
}
