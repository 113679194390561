import Vue from 'vue'
import Moment from 'moment';
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import '@/assets/styles.scss'
import store from './store'
import vuetify from './plugins/vuetify'
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import OneSignalVue from 'onesignal-vue'

// plugin section
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(Donut)
Vue.use(VueVirtualScroller)
Vue.use(OneSignalVue)

// prototype section
Vue.prototype.$moment = Moment

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
