var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-btn',{attrs:{"color":"#5b6cff","text":""},on:{"click":function($event){$event.stopPropagation();_vm.editOperationDialog = true, _vm.init()}}},[_vm._v(" Изменить ")]),_c('v-dialog',{attrs:{"width":"600px"},model:{value:(_vm.editOperationDialog),callback:function ($$v) {_vm.editOperationDialog=$$v},expression:"editOperationDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.operationTitle)+" ")])]),_c('v-card-text',[_c('v-form',{ref:"editOperationForm"},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"rules":_vm.operationRules,"hide-details":"","required":"","outlined":""},model:{value:(_vm.newOperationTitle),callback:function ($$v) {_vm.newOperationTitle=$$v},expression:"newOperationTitle"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-tooltip',{attrs:{"max-width":"300","color":"warning","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"append-icon":"mdi-currency-kzt","type":"number","rules":_vm.operationRules,"hide-details":"","readonly":"","required":"","outlined":""},model:{value:(_vm.sumOfDetails),callback:function ($$v) {_vm.sumOfDetails=$$v},expression:"sumOfDetails"}},'v-text-field',attrs,false),on))]}}])},[_c('span',{staticClass:"tooltip-text"},[_vm._v(" Данное поле нельзя редактировать напрямую. Заполняя дополнительные поля рассчёт идёт автоматически ")])])],1)],1),_c('hr',{staticClass:"divider"}),_vm._l((_vm.newOperationDetails),function(detail){return _c('v-row',{key:detail.id,attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"rules":_vm.operationRules,"hide-details":"","required":"","outlined":""},model:{value:(detail.title),callback:function ($$v) {_vm.$set(detail, "title", $$v)},expression:"detail.title"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-currency-kzt","type":"number","rules":_vm.operationRules,"hide-details":"","required":"","outlined":""},model:{value:(detail.amount),callback:function ($$v) {_vm.$set(detail, "amount", $$v)},expression:"detail.amount"}})],1)],1)})],2)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.disableButton},on:{"click":function($event){return _vm.updateOperation({
            operationType: _vm.operationType,
            operationId: _vm.operationId,
            operationYear: _vm.operationYear,
            operationMonth: _vm.operationMonth,
            newOperationTitle: _vm.newOperationTitle,
            sumOfDetails: _vm.sumOfDetails,
            newOperationDetails: _vm.newOperationDetails,
          })}}},[_vm._v(" Сохранить ")]),_c('v-btn',{attrs:{"color":"error","text":"","disabled":_vm.disableButton},on:{"click":function($event){_vm.editOperationDialog = false}}},[_vm._v(" Отмена ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }