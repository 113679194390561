import { http } from './http'
import store from '@/store/index'

export const contractorApi = async () => {
  store.commit('ADD_LOADING', 'contractors.contractorApi');
  const url = '/v1/contractors';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'contractors.contractorApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'contractors.contractorApi');
  return res;
};

export const addContractorApi = async (data) => {
  store.commit('ADD_LOADING', 'contractors.addContractorApi');
  const url = '/v1/contractors/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data,{ headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'contractors.addContractorApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'contractors.addContractorApi');
  return res;
};

export const updateContractorApi = async (data) => {
  store.commit('ADD_LOADING', 'contractors.updateContractorApi');
  const url = '/v1/contractors/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.put(url, data,{ headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'contractors.updateContractorApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'contractors.updateContractorApi');
  return res;
};

export const contractorIdApi = async (id) => {
  store.commit('ADD_LOADING', 'contractors.contractorIdApi');
  const url = `/v1/contractors/${id}/`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'contractors.contractorIdApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'contractors.contractorIdApi');
  return res;
};

export const deleteContractorApi = async (data) => {
  store.commit('ADD_LOADING', 'contractors.deleteContractorApi');
  const url = '/v1/contractors/';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.delete(url, { headers,data })
      .catch((e) => {
        store.commit('DEL_LOADING', 'contractors.deleteContractorApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'contractors.deleteContractorApi');
  return res;
};