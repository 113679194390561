<template>
  <div class="content">
    <div class="user-row side-paddings">
      <div class="tab-row">
        <router-link
            v-for="tab in tabs"
            :key="tab.name"
            :to="{ name: `Voting.${tab.name}` }"
            class="tab-item"
        >
          {{ tab.title }}

        </router-link>
      </div>
    </div>
    <Active/>
  </div>
</template>

<script>

import Active from '../components/voting/Active.vue';

export default {
  name: "Voting",
  components: {
    Active
  },
  data () {
    return {
      tabs: [
        {
          name: "active",
          title: "Активные",
          icon: "mdi-account-multiple"
        },
        {
          name: "archive",
          title: "Архив",
          icon: "mdi-calendar-clock"
        },
      ],
    };
  },
}
</script>

<style scoped>

</style>