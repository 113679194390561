<template>
  <v-row
    no-gutters
    class="justify-end"
    style="align-items: center;"
  >
    <div @click="toggleEditPrice" v-if="!editPrice">
      {{tariff.title}}
    </div>
    <input
      type="text"
      class="priceInput"
      v-if="editPrice"
      ref="inpt"
      :placeholder="tariff.title"
      v-model="newName"
    >
    <div
      class="create-block-btn mr-1"
      v-if="editPrice"
      @click="updateTariff({
        id:tariff.id,
        title:newName,
        color:tariff.color,
        price:tariff.price
      })"
    >
      <img src="@/assets/images/Active.svg" alt="">
    </div>
    <div
      class="cancel-block-btn"
      style="cursor: pointer"
      v-if="editPrice"
      @click="toggleEditPrice"
    >
      <img src="@/assets/images/cancel-btn.svg" alt="">
    </div>
  </v-row>
</template>

<script>
export default {
  name: "EditFinanceName",
  data() {
    return {
      editPrice:false,
      newName:'',
    };
  },
  props:{
    tariff:{
      type: Object,
      default: ()=>{},
    }
  },
  methods:{
    toggleEditPrice(){
      this.editPrice=!this.editPrice;
      if (this.editPrice){
        this.$nextTick(() => this.$refs.inpt.focus());
      }
      else{
        this.newName='';
      }
    },

    updateTariff(data){
      this.$emit('update',data);
      this.toggleEditPrice();
      this.prnewNameice='';
    }
  }
}

</script>

<style scoped>

</style>