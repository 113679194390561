<template>
  <div class="backdrop" @click.self="closeModal">
    <div class="modal-sm">
      <div class="delete-avatar">
        <img v-if="item.avatar" :src="item.avatar" alt="" />
        <img v-else src="@/assets/images/avatar.svg" alt="" />
      </div>
      <div class="delete-user">
        <div class="name" v-if="item.personal_account">{{ item.personal_account.owner }}</div>
        <div class="name" v-if="item.name">{{ item.name }}</div>
        <div class="address-row">
          <div class="address" v-if="item.block">
            <div class="type">Блок</div>
            <div class="num">{{ item.block.number }}</div>
          </div>
          <div class="address" v-if="item.entrance">
            <div class="type">Подъезд</div>
            <div class="num">{{ item.entrance }}</div>
          </div>
          <div class="address" v-if="item.apt">
            <div class="type">Квартира</div>
            <div class="num">{{ item.apt.number }}</div>
          </div>
          <div class="address" v-if="item.personal_account">
            <div class="type">Л/С</div>
            <div class="num">{{ item.personal_account.personal_account }}</div>
          </div>
        </div>
        <div class="flex-row jcc">
          <img src="@/assets/images/delete-icon.svg" alt="" />
          <div class="sure-text">
            Вы действительно хотите удалить {{ title[type] }}?
          </div>
        </div>
        <div class="btn-row">
          <div class="decline-btn" @click="closeModal">Отмена</div>
          <div class="delete-btn" @click="deleteItem(item)">Удалить</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { removeUserApi, removePersonalApi } from "@/api/users";
import { deleteContractorApi } from "../../api/contractors";
import { deleteStaffApi } from "../../api/staff";

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "residents",
    },
  },
  data: () => ({
    title: {
      residents: "жителя",
      staff: "сотрудника",
      contractors: "подрядчика",
    },
  }),
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async deleteItem(item) {
      try {
        if (this.type == "contractors") {
          await deleteContractorApi({ contractor_id: item.id });
        }
        if (this.type == "staff") {
          console.log(this.type);
          console.log("item", item);
          await deleteStaffApi({ user_id: item.userId });
        }
        if (this.type == "residents") {
          if (item.building_owner) {
            await removeUserApi(item.apt.apartment_id);
          }
          await removePersonalApi(item.personal_account.personal_account);
        }
        this.closeModal();
        this.$emit("closeResident");
        this.$emit("closeDetails");
        this.$emit("updateList");
        this.$store.commit('SHOW_SNACKBAR', 'deleteUserSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Пользователь успешно удалён');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'deleteUserErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при удалении пользователя');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
  },
};
</script>

<style>
</style>
