<template>
  <v-dialog
    v-model="addScheduleForStaffDialog"
    @click:outside="closeDialog"
    width="485px"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-on="on" v-bind="attrs" color="primary" @click="openDialog">
        Добавить сотрудника в график
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="justify-center"> Добавление в график </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="addWorkScheduleForm" class="add-schedule-form">
          <v-row no-gutters>
            <v-col cols="12">
              <SelectExecutor
                ref="executorForm"
                @selectedExecutor="setStaff"
                @validExecutor="validateStaff"
              />
            </v-col>
            <v-expand-transition>
              <v-form
                v-if="addScheduleForm"
                :disabled="onScheduleSubmit"
                ref="addScheduleForm"
              >
                <v-row no-gutters>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="addScheduleData.schedule"
                      :rules="scheduleRules"
                      :items="scheduleList"
                      item-text="title"
                      item-value="id"
                      label="Рабочий график"
                      hide-details
                      clearable
                      outlined
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      ref="startTimeMenu"
                      v-model="startTimeMenu"
                      transition="scale-transition"
                      :close-on-content-click="false"
                      :return-value.sync="addScheduleData.start_at"
                      :nudge-right="40"
                      min-width="290"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="addScheduleData.start_at"
                          :rules="scheduleRules"
                          label="Начало"
                          hide-details
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          required
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="startTimeMenu"
                        v-model="addScheduleData.start_at"
                        :allowed-minutes="allowedMinutes"
                        full-width
                        format="24hr"
                        @click:minute="$refs.startTimeMenu.save(addScheduleData.start_at)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="addScheduleData.wh"
                      :rules="scheduleRules"
                      :items="workHours"
                      label="Часы"
                      hide-details
                      outlined
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-end">
                    <v-btn
                    color="error"
                    text
                    :disabled="onScheduleSubmit"
                    @click="hideAddSchedule"
                    >
                      Отмена
                    </v-btn>
                    <v-btn
                      color="primary"
                      text
                      :disabled="onScheduleSubmit"
                      @click="submitSchedule"
                    >
                      {{ staffSchedule ? "Изменить график" : "Добавить график" }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
            <v-col cols="12">
              <v-alert :color="alertColor" outlined text>
                <v-skeleton-loader
                  v-if="scheduleLoading"
                  height="44px"
                  type="text@2"
                ></v-skeleton-loader>
                <span v-else-if="!scheduleLoading && staffSchedule">
                  График выбранного сотрудника: {{ staffSchedule.title }}<br/>
                  Время работы: {{ staffSchedule.start_at }}-{{ staffSchedule.end_at }}
                  <v-btn
                    class="add-schedule-btn"
                    color="primary"
                    text
                    :disabled="addScheduleForm"
                    @click="showAddSchedule"
                  > Изменить </v-btn>
                </span>
                <div v-else-if="!scheduleLoading && staffSchedule === undefined">
                  <span> У данного сотрудника нет графика работы! </span>
                  <v-btn
                    class="add-schedule-btn"
                    color="primary"
                    text
                    :disabled="addScheduleForm"
                    @click="showAddSchedule"
                  > Добавить </v-btn>
                </div>
                <span v-else>
                  Выберите сотрудника выше для отображения его графика работы
                </span>
              </v-alert>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="startDateFormattedMomentjs"
                    :disabled="
                      onWorkScheduleSubmit ||
                      staffSchedule === undefined ||
                      addScheduleForm
                    "
                    :rules="scheduleRules"
                    @click:clear="formData.start_date = undefined"
                    label="Начало периода*"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    clearable
                    outlined
                    readonly
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-if="addScheduleForStaffDialog"
                  v-model="formData.start_date"
                  :min="new Date().toISOString()"
                  :max="formData.end_date || undefined"
                  @input="startDateMenu = false"
                  first-day-of-week="1"
                  locale="ru-RU"
                  color="primary"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="endDateFormattedMomentjs"
                    :disabled="
                      onWorkScheduleSubmit ||
                      staffSchedule === undefined ||
                      addScheduleForm
                    "
                    :rules="scheduleRules"
                    @click:clear="formData.end_date = undefined"
                    label="Конец периода*"
                    v-bind="attrs"
                    v-on="on"
                    hide-details
                    clearable
                    outlined
                    readonly
                    required
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-if="addScheduleForStaffDialog"
                  v-model="formData.end_date"
                  :min="formData.start_date || new Date().toISOString()"
                  @input="endDateMenu = false"
                  first-day-of-week="1"
                  locale="ru-RU"
                  color="primary"
                  no-title
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="cancel-btn"
          :disabled="onWorkScheduleSubmit"
          @click="closeDialog"
        >
          Отмена
        </v-btn>
        <v-btn
          :disabled="
            onWorkScheduleSubmit ||
            scheduleLoading ||
            staffSchedule === undefined ||
            addScheduleForm
          "
          :loading="onWorkScheduleSubmit"
          @click="submitWorkSchedule"
        >
          Добавить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getScheduleApi,
  getStaffScheduleByStaffIdApi,
  addStaffScheduleApi,
  addStaffWorkScheduleApi
} from "@/api/schedule";
import SelectExecutor from "@/components/widgets/SelectExecutor.vue";

export default {
  components: {
    SelectExecutor,
  },
  data: () => ({
    addScheduleForStaffDialog: false,
    scheduleLoading: false,
    addScheduleForm: false,
    addWorkScheduleForm: false,
    startTimeMenu: false,
    allowedMinutes: [0, 15, 30, 45],
    startDateMenu: false,
    endDateMenu: false,
    onScheduleSubmit: false,
    onWorkScheduleSubmit: false,
    validStaff: false,
    addScheduleData: {},
    formData: {},
    scheduleList: [],
    staffSchedule: null,
    scheduleRules: [(v) => !!v || "Обязательное поле"],
  }),
  computed: {
    startDateFormattedMomentjs() {
      return this.formData.start_date
        ? this.$moment(this.formData.start_date).format("DD MMMM YYYY")
        : "";
    },
    endDateFormattedMomentjs() {
      return this.formData.end_date
        ? this.$moment(this.formData.end_date).format("DD MMMM YYYY")
        : "";
    },
    alertColor() {
      if (this.staffSchedule) return "success";
      else if (this.staffSchedule === undefined) return "danger";
      else return "primary";
    },
    workHours() {
      return Array.from({length: 24}, (_, i) => i + 1);
    },
  },
  methods: {
    openDialog() {
      this.addScheduleForStaffDialog = true;
    },
    closeDialog() {
      (this.formData = {}), this.$refs.addWorkScheduleForm.reset();
      this.$refs.executorForm.clearSelect();
      this.staffSchedule = null;
      this.hideAddSchedule();
      this.addScheduleForStaffDialog = false;
    },
    setStaff(staff_id) {
      if (staff_id !== null) {
        this.formData.staff_id = staff_id;
        this.scheduleLoading = true;
        this.getScheduleList();
        this.getStaffSchedule(staff_id);
      } else {
        this.staffSchedule = null;
        this.$refs.executorForm.clearSelect();
      }
    },
    validateStaff() {
      if (this.validStaff === false) this.validStaff = true;
    },
    async getScheduleList() {
      let res = await getScheduleApi();
      this.scheduleList = [...res.data];
    },
    async getStaffSchedule(id) {
      this.staffSchedule = null;
      let res = await getStaffScheduleByStaffIdApi(id);
      let staffSchedule = res.data.find((schedule) => schedule.staff === id);
      console.log("schedule", staffSchedule);
      if (staffSchedule) {
        // Calculation for end time of schedule
        let endTime = this.$moment(
          new Date("1970-01-01 " + staffSchedule.start_at)
        ).add(staffSchedule.wh, "hours");

        // Find schedule for staff in schedule list
        let scheduleFromList = this.scheduleList.find(
          (schedule) => schedule.id === JSON.stringify(staffSchedule.schedule)
        );

        this.staffSchedule = {
          id: scheduleFromList.id,
          title: scheduleFromList.title,
          start_at: staffSchedule.start_at.substring(0, 5),
          end_at: endTime._d.toTimeString().substring(0, 5),
          work_hour: staffSchedule.wh,
        };
        this.scheduleLoading = false;
      } else {
        this.staffSchedule = undefined;
        this.scheduleLoading = false;
      }
    },
    showAddSchedule() {
      this.addScheduleData.staff = this.formData.staff_id;
      this.addScheduleForm = true;
    },
    hideAddSchedule() {
      this.addScheduleData = {};
      this.addScheduleForm = false;
    },
    submitSchedule() {
      if (this.$refs.addScheduleForm.validate() === true) {
        this.addScheduleToStaff();
      } else {
        this.$store.commit('SHOW_SNACKBAR', 'correctFormErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Заполните форму правильно!');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
      }
    },
    async addScheduleToStaff() {
      try {
        this.onScheduleSubmit = true;
        await addStaffScheduleApi(this.addScheduleData);
        this.hideAddSchedule();
        this.setStaff(this.formData.staff_id);
        if (this.staffSchedule?.id) {
          this.$store.commit('SHOW_SNACKBAR', 'updateScheduleSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'График успешно обновлён');
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        } else {
          this.$store.commit('SHOW_SNACKBAR', 'addScheduleSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'График успешно добавлен');
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        };
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'addScheduleErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при добавлении графика');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      } finally {
        this.onScheduleSubmit = false;
      };
    },
    async submitWorkSchedule() {
      try {
        this.onWorkScheduleSubmit = true;
        this.$refs.executorForm.validateSelect();
        this.$refs.addWorkScheduleForm.validate();
        if (this.validStaff && this.$refs.addWorkScheduleForm.validate() === true) {
          let res = await addStaffWorkScheduleApi(this.formData);
          if (res.data.message) {
            this.$store.commit("SHOW_SNACKBAR", "scheduleErrorSnackbar");
            this.$store.commit("SET_SNACKBAR_TITLE", res.data.message);
            this.$store.commit("SET_SNACKBAR_COLOR", "error");
          } else {
            this.$store.commit("SHOW_SNACKBAR", "scheduleSuccessSnackbar");
            this.$store.commit(
              "SET_SNACKBAR_TITLE",
              "Сотрудник успешно добавлен в график"
            );
            this.$store.commit("SET_SNACKBAR_COLOR", "success");
            this.$emit("updateScheduleCalendar");
            (this.formData = {}), this.closeDialog();
          }
        } else {
          this.$store.commit("SHOW_SNACKBAR", "scheduleErrorSnackbar");
          this.$store.commit(
            "SET_SNACKBAR_TITLE",
            "Заполните форму полностью!"
          );
          this.$store.commit("SET_SNACKBAR_COLOR", "error");
        }
      } catch (err) {
        let errorMessage = err.response.data.message;
        this.$store.commit("SHOW_SNACKBAR", "scheduleErrorSnackbar");
        this.$store.commit("SET_SNACKBAR_TITLE", errorMessage);
        this.$store.commit("SET_SNACKBAR_COLOR", "error");
      } finally {
        this.onWorkScheduleSubmit = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card__text {
  padding: 18px 36px 6px !important;
}
.v-card__text .new-ticket-executor {
  margin-bottom: 0;
}
.add-schedule-btn {
  padding: 0 !important;
  height: unset !important;
  font-size: 16px;
}
.col-12 {
  padding: 6px !important;
}
.v-card__actions {
  padding: 24px 42px !important;
}
.v-card__actions > .v-btn {
  width: calc((100% - 12px) / 2) !important;
}
.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 12px;
}
</style>