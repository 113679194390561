<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="user-btn"
        v-bind="attrs"
        v-on="on"
      >
        <v-img 
          v-if="user.user.user_avatar"
          :src="user.user.user_avatar"
          lazy-src="@/assets/images/avatar.svg"
          style="flex: 0 auto;"
        ></v-img>
        <v-img v-else
          src="@/assets/images/avatar.svg"
          style="flex: 0 auto;"
        ></v-img>
        <v-icon color="#5b6cff">mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item class="account-item d-flex justify-space-between">
        <div class="name">
          Имя:
          {{ user.user && `${user.user.first_name} ${user.user.last_name}` }}
        </div>
      </v-list-item>
      <v-divider class="border"></v-divider>
      <v-list-item class="account-item d-flex justify-space-between">
        <div class="phone">
          Номер телефона: {{ user.user && user.user.phone }}
        </div>
      </v-list-item>
      <v-divider class="border"></v-divider>
      <v-list-item class="account-item d-flex justify-space-between">
        <div class="phone">Компания: {{ user.user && user.company.name }}</div>
      </v-list-item>
      <v-divider class="border"></v-divider>

      <v-list-item
        class="account-item d-flex justify-space-between"
        @click="$router.push({ name: 'ChangePassword' })"
        style="min-width: 210px"
      >
        <div class="log-out-btn">Сменить пароль</div>
      </v-list-item>
      <v-divider class="border"></v-divider>

      <v-list-item
        class="account-item d-flex justify-space-between"
        @click="logout"
        style="min-width: 210px"
      >
        <div class="log-out-btn">
          <img src="@/assets/images/log-out.svg" alt="" /> Выйти из аккаунта
        </div>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { unsubscribeFromPushAPI } from "@/api/one-signal";

export default {
  computed: {
    user() {
      return this.$store.state.user.user;
    },
  },
  methods: {
    logout() {
      this.$OneSignal.isPushNotificationsEnabled(function (isEnabled) {
        OneSignal.getUserId((user_id) => {
          if (isEnabled) {
            unsubscribeFromPushAPI(user_id);
            OneSignal.removeExternalUserId();
            OneSignal.setSubscription(false);
          }
        });
      });
      this.$store.dispatch("user/logout");
      this.$store.commit("DEL_CURRENT_COMPLEX");
      this.$router.push({ name: "Login" });
    },
  },
};
</script>