<template>
  <div class="content">
    <div class="user-row side-paddings" :class="{ details: showSideModal }">
      <div class="tab-row">
        <router-link
          v-for="tab in tabs"
          :key="tab.name"
          :to="{ name: `Employee.${tab.name}` }"
          class="tab-item"
        >
          {{ tab.title }}
        </router-link>
      </div>
      <CreateStaff
        v-if="type === 'staff'"
        :isAddingNewUser="true"
        :userObj="{}"
        @updateList="sendRefresh"
      />
      <AddScheduleForStaff
        v-if="type === 'schedule'"
        @updateScheduleCalendar="sendRefresh"
      />
      <AddDepartmentToComplex
        v-if="type === 'departments'"
        ref="addDepartmentToComplex"
        @updateDepartmentList="sendRefresh"
      />
      <CreateContractor
        v-if="type === 'contractors'"
        :isAddingNewUser="true"
        :userObj="{}"
        @updateList="sendRefresh"
      />
    </div>
    <router-view
      ref="employeeChildComponents"
      @openDepartmentAdding="emitOpenDepartmentAdding"
      @resizeTabsRow="resizeTabsRow"
      :filters="listFilter"
    />
    <div v-show="showDeleteUser">

      <DeleteUser
        :item="deleteItem"
        :type="type"
        :userId="deleteItem.userId"
        @close="toggleDeleteUser"
      />
    </div>
  </div>
</template>

<script>
import CreateStaff from "@/components/modals/CreateStaff.vue";
import AddScheduleForStaff from "@/components/modals/AddScheduleForStaff.vue";
import AddDepartmentToComplex from '@/components/modals/AddDepartmentToComplex.vue';
import CreateContractor from "@/components/modals/CreateContractor.vue";
import DeleteUser from "@/components/modals/DeleteUser.vue";

export default {
  components: {
    CreateStaff,
    AddScheduleForStaff,
    AddDepartmentToComplex,
    CreateContractor,
    DeleteUser,
  },
  data() {
    return {
      showDeleteUser: false,
      showSideModal: false,
      tabs: [
        {
          title: "Сотрудники",
          name: "staff",
        },
        {
          title: "Рабочий график",
          name: "schedule",
        },
        {
          title: "Отделы",
          name: "departments",
        },
        {
          title: "Подрядчики",
          name: "contractors",
        },
      ],
      deleteItem: {},
      listFilter: {},
    };
  },
  watch: {
    listFilter: {
      deep: true,
      handler() {
        /** set url parameters from listFilter data & fetch application list */
        const listFilter = {};
        Object.keys(this.listFilter).forEach((key) => {
          if (!!this.listFilter[key]) {
            listFilter[key] = this.listFilter[key];
          }
        });
        if (this.$route.query.listFilter !== JSON.stringify(listFilter)) {
          let query = { ...this.$route.query };
          if (Object.keys(listFilter).length > 0) {
            query.listFilter = JSON.stringify(listFilter);
          } else {
            delete query.listFilter;
          }
          this.$router.push({ ...this.$route, query });
        }
      },
    },
  },
  computed: {
    type() {
      return this.$route.name.split(".")[1];
    },
  },
  methods: {
    toggleDeleteUser(item) {
      this.deleteItem = item || {};
      this.showDeleteUser = !this.showDeleteUser;
    },
    init() {
      /** fill listFilter from route query */
      this.listFilter = JSON.parse(this.$route.query.listFilter || "{}");
    },
    sendRefresh() {
      this.$refs.employeeChildComponents.init();
    },
    emitOpenDepartmentAdding() {
      this.$refs.addDepartmentToComplex.openDialog();
      // this.$refs.addDepartmentToComplex.loadAvailableStaff();
    },
    resizeTabsRow(){
      this.showSideModal = !this.showSideModal;
    },
  },
};
</script>