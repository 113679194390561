<template>
  <div class="content">
    <v-row no-gutters class="side-paddings" :class="{ details: showSideModal }">
      <div class="tab-row">
        <div
          class="tab-item"
          :class="{
            active: !newsFilter.type,
          }"
          @click="$delete(newsFilter, 'type'), (currenType = -1), (page = 1)"
        >
          Все
        </div>
        <div
          v-for="item in types"
          :key="item.id"
          class="tab-item"
          :class="{
            active: newsFilter.type === item.id,
          }"
          @click="
            $set(newsFilter, 'type', item.id),
              (currenType = item.id - 1),
              (page = 1)
          "
        >
          {{ item.title }}
        </div>
      </div>
      <v-btn
        @click="showAddNews"
      >
        Создать
      </v-btn>
    </v-row>
    <div class="news-container">
      <div class="grey-bg">
        <div class="news-row" v-if="newsLoading">
          <v-skeleton-loader type="image" class="skeleton-news"></v-skeleton-loader>
          <v-skeleton-loader type="image" class="skeleton-news"></v-skeleton-loader>
          <v-skeleton-loader type="image" class="skeleton-news"></v-skeleton-loader>
        </div>
        <div v-else>
          <div class="text-h5 no-news" v-if="news.length === 0">
            Не найдено ни одной записи
          </div>
          <div class="news-row" v-else :class="{ details: showSideModal }">
            <div
              v-for="item in news"
              :key="item.id"
              class="news-item"
              @click.stop="toggleRetrieveNews(item.id, true, item)"
            >
              <div class="news-title">{{ item.title }}</div>
              <div class="flex-row">
                <div class="news-type">
                  {{ typeTitle[item.type.id || item.type] }}
                </div>
                <div class="date">
                  {{ $moment(item.created_at).format("DD.MM.YYYY в HH:mm") }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <v-pagination
          v-model="page"
          :length="pagesData.pages"
          total-visible="7"
          @input="changePage"
          @next="changePage"
          @previous="changePage"
          :disabled="changeNewsPage"
        ></v-pagination>
      </div>
    </div>
    <div>
      <AddNews @update="updateNewsList" v-if="addNews" @close="showAddNews" />
      <div v-if="retrieveNews">
        <div v-if="pollNews" class="chess-side-modal news-info">
          <div class="side-modal-wrap news" v-if="newsId">
            <v-row no-gutters class="pl-0 jc-sb" style="border-bottom: 0px">
              <div class="back-btn-news" @click="closeRetrieveNews">
                <img src="@/assets/images/back.svg" alt="" />
                Закрыть
              </div>
              <div
                v-if="
                  newsId.type ==
                    types.find((item) => item.type === 'poll').id &&
                  !newsId.is_closed
                "
                class="end-btn-news"
                @click="endPoll({ id: newsId.id }), (retrieveNews = false)"
              >
                Завершить опрос
              </div>
              <div
                v-if="
                  newsId.type ==
                    types.find((item) => item.type === 'poll').id &&
                  newsId.is_closed
                "
                class="closed-btn-news"
              >
                Опрос закрыт
              </div>
            </v-row>
            <h2 class="title" style="margin-bottom: 16px">
              {{ newsId.title }}
            </h2>
            <div class="flex-row" style="margin-bottom: 24px">
              <div class="news-type">
                {{ typeTitle[newsId.type ? newsId.type : 0] }}
              </div>
              <div class="date" style="margin-left: 13px">
                {{ $moment(newsId.created_at).format("DD.MM.YYYY в HH:mm") }}
              </div>
            </div>
            <div
              v-if="
                newsId.type == types.find((item) => item.type === 'poll').id
              "
              style="
                border-bottom: 1px solid #f0f0f3;
                border-top: 1px solid #f0f0f3;
                margin-top: 22px;
              "
            >
              <h2 style="margin-top: 22px">Результаты</h2>
              <div class="flex-row" style="margin-top: 14px">
                <div class="vote-btn">
                  Я за!
                  <br />
                  {{ newsId.poll_result.for_count }}
                </div>
                <div class="vote-btn">
                  Против
                  <br />
                  {{ newsId.poll_result.opposite_count }}
                </div>
                <div class="vote-btn">
                  Воздержусь
                  <br />
                  {{ newsId.poll_result.neutral_count }}
                </div>
              </div>
            </div>

            <!--          <div class="photo" style="max-height: 50px">-->
            <!--            <div v-for="image in newsId.images" :key="image.id" class="photo-item">-->
            <!--              <img :src="image" alt="">-->
            <!--            </div>-->
            <!--          </div>-->
            <div
              v-if="newsId.images && newsId.images.length != 0"
              style="color: #aaabba; font-weight: normal"
            >
              Вложения:
            </div>
            <div v-if="newsId.images && newsId.images.length != 0" class="show-attachment">
              <div v-for="image in newsId.images" :key="image.id">
                <div class="attachments-img">
                  <picture class="image">
                    <source :srcset="image" alt="" />
                    <img src="@/assets/images/avatar.svg" alt="" />
                  </picture>
                  <div class="middle">
                    <div v-if="image">
                      <v-btn @click.stop="(dialog = true), (photo = image)">
                        <div class="inner-text">Открыть</div>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>

              <v-dialog v-model="dialog" height="100vh">
                <v-card class="photo_container">
                  <v-card-actions class="big_photo">
                    <v-spacer></v-spacer>
                    <picture class="image" style="position: relative">
                      <source :srcset="photo" alt="" />
                      <img src="@/assets/images/avatar.svg" alt="" />
                    </picture>
                    <v-btn
                      color="primary"
                      text
                      @click="dialog = false"
                      style="position: absolute"
                      >✕</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <div class="text scrollable mt-8">
              {{ newsId.body }}
            </div>
          </div>
        </div>
        <v-dialog
          v-model="newsDialog"
          @click:outside="closeRetrieveNews"
          class="newsModal"
          width="900px"
        >
          <v-card height="80vh" style="overflow-y: hidden" v-if="!pollNews">
            <v-row no-gutters style="height: 100%; width: 100%">
              <div class="left pt-6 pl-6 pr-6">
                <v-row no-gutters class="align-center pb-6">
                  <div class="news-type-title">
                    {{ types[currentNews.type - 1].title }}
                  </div>
                  <div class="date-grey">
                    {{
                      $moment(currentNews.created_at).format(
                        "Создано DD.MM.YYYY в HH:mm"
                      )
                    }}
                  </div>
                </v-row>
                <h3 class="pb-3">{{ currentNews.title }}</h3>
                <v-carousel
                  :show-arrows="currentNews.images.length > 1"
                  hide-delimiter-background
                  height="55%"
                  v-if="currentNews.images.length != 0"
                >
                  <v-carousel-item
                    v-for="image in currentNews.images"
                    :key="image.id"
                  >
                    <img style="width: 100%" :src="image" alt="" />
                  </v-carousel-item>
                </v-carousel>
                <div class="text">{{ currentNews.body }}</div>
              </div>
              <div class="right">
                <v-row
                  no-gutters
                  style="
                    width: 100%;
                    border-bottom: 1px solid #f0f0f3;
                    height: 72px;
                  "
                  class="pa-6 justify-space-between"
                >
                  <h3>Комментарии</h3>
                  <div
                    class="close"
                    style="top: 13px; cursor: pointer"
                    @click.self="closeRetrieveNews"
                  >
                    &#10005;
                  </div>
                  <!-- <v-btn icon>
                    <v-icon @click="closeRetrieveNews">mdi-close</v-icon>
                  </v-btn> -->
                </v-row>
                <div style="overflow-y: auto; height: calc(100% - 72px - 66px)">
                  <div v-if="!loading">
                    <div
                      class="pl-6 pr-6"
                      v-for="comment in comments.results"
                      :key="comment.id"
                    >
                      <div class="comment-item mt-4 mb-4">
                        <div
                          class="user-info"
                          style="justify-content: space-between"
                        >
                          <div class="user-info">
                            <div class="chat-avatar avatar-img">
                              <v-img
                                v-if="comment.author.user_avatar"
                                :src="comment.author.user_avatar"
                                lazy-src="@/assets/images/avatar.svg"
                              ></v-img>
                              <v-img
                                v-else
                                src="@/assets/images/avatar.svg"
                              ></v-img>
                            </div>
                            <div class="mid-text">
                              <div class="name">
                                {{ comment.author.first_name }}
                                {{ comment.author.last_name }}
                              </div>
                              <div
                                class="user-status"
                                :class="{ ruler: comment.authoRuler }"
                              >
                                {{ comment.author.position }}
                              </div>
                            </div>
                          </div>
                          <div class="ml-0 date">
                            {{ $moment(comment.created_at).format("DD.MM.YYYY в HH:mm") }}
                          </div>
                        </div>
                        <div class="text">{{ comment.comment }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-if="loading || loadingMore" class="pl-6 pr-6">
                    <v-skeleton-loader
                      class="mt-4 comment-item"
                      type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                    <v-skeleton-loader
                      class="mt-4 comment-item"
                      type="list-item-avatar-two-line"
                    ></v-skeleton-loader>
                  </div>
                  <div
                    v-if="comments.count != 0 && comments.pages != pageCount && !loading"
                    style="font-size: 14px; cursor: pointer"
                    @click="
                      getMoreComments(currentNews.id, comments.next_page_url)
                    "
                    class="mt-2 mb-4 noComments-messsage"
                  >
                    Показать еще ({{ comments.count }})
                  </div>
                  <div
                    v-if="comments.count == 0 && !loading"
                    class="d-flex align-center justify-center"
                    style="height: 100%"
                  >
                    <div class="d-flex align-center flex-column">
                      <img width="60px" src="@/assets/images/forum.svg" alt="" />
                      <div class="noComments-messsage">
                        Жители не прокоментировали
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="enter-message" style="right: 0; width: 40%">
                <v-form ref="commentForm">
                  <v-text-field
                    v-model="newComment"
                    :rules="newCommentRules"
                    placeholder="Введите сообщение..."
                    hide-details
                    clearable
                    required
                    @keydown.enter.prevent.exact="
                      addComment({
                        app_label: 'news',
                        model: 'news',
                        object_id: currentNews.id,
                        comment: newComment,
                      })
                    "
                  ></v-text-field>
                </v-form>
                <div
                  @click="
                    addComment({
                      data: {
                        app_label: 'news',
                        model: 'news',
                        object_id: currentNews.id,
                        comment: newComment,
                      },
                    })
                  "
                >
                  <img src="@/assets/images/send.svg" alt="" />
                </div>
              </div>
            </v-row>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import {
  newsApi,
  newsTypeApi,
  newsIdApi,
  getNewsPhotoApi,
  newsEndApi,
} from "@/api/news";
import { getNewsCommentsApi } from "../api/news";
import { submitCommentApi } from "../api/comments";
export default {
  components: {
    AddNews: () => import("@/components/news/add"),
  },
  data() {
    return {
      newsLoading: true,
      news: [],
      page: 1,
      currenType: -1,
      pagesData: [],
      newsId: undefined,
      types: [],
      comments: [],
      newsFilter: {},
      app_label: "news",
      model: "news",
      object_id: 0,
      pageSize: 2,
      pageCount: 1,
      newComment: "",
      showSideModal: false,
      retrieveNews: false,
      changeNewsPage: false,
      pollNews: false,
      loading: false,
      loadingMore: false,
      addNews: false,
      dialog: false,
      newsDialog: false,
      currentNews: {},
      photo: "",
      newCommentRules: [(v) => !!v || "Обязательное поле"],
    };
  },
  computed: {
    typeTitle() {
      return Object.fromEntries(this.types.map((t) => [t.id, t.title]));
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        this.getNews(JSON.parse(to.query.newsFilter || "{}"));
      },
    },
    newsFilter: {
      deep: true,
      handler() {
        /** set url parameters from newsFilter data & fetch list */
        const filters = {};
        Object.keys(this.newsFilter).map((key) => {
          if (!!this.newsFilter[key]) {
            filters[key] = this.newsFilter[key];
          }
        });
        if (this.$route.query.newsFilter !== JSON.stringify(filters)) {
          let query = { ...this.$route.query };
          if (Object.keys(filters).length > 0) {
            query.newsFilter = JSON.stringify(filters);
          } else {
            delete query.newsFilter;
          }
          if (this.$route.query.newsFilter !== query.newsFilter)
            this.$router.push({ ...this.$route, query });
        }
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    async getNews(params) {
      const res = await newsApi(params, this.page, 10);
      this.news = res.data.results;
      this.pagesData = res.data;
      this.newsLoading = false;
      this.changeNewsPage = false;
    },
    async getNewsType() {
      const res = await newsTypeApi();
      this.types = res.data;
    },
    async endPoll(data) {
      await newsEndApi(data);
    },
    async getComments() {
      this.loading = true;
      await getNewsCommentsApi(
        this.currentNews.id,
        this.app_label,
        this.pageSize,
        this.pageCount,
        this.model
      ).then((res) => {
        this.comments = res.data;
        console.log("comments", this.comments);
      });
      this.loading = false;
    },
    async getMoreComments() {
      this.loadingMore = true;
      this.pageCount++;
      const res = await getNewsCommentsApi(
        this.currentNews.id,
        this.app_label,
        this.pageSize,
        this.pageCount,
        this.model
      );
      this.comments.results.push(...res.data.results);
      this.loadingMore = false;
    },
    async addComment(data) {
      this.newComment = this.newComment.trim();
      if (this.$refs.commentForm.validate() === true && this.newComment !== '') {
        this.loading = true;
        this.pageCount = 1;
        await submitCommentApi(data).then((res) => {
          this.newComment = "";
          this.comments = [];
          this.getComments();
        });
        this.loading = false;
        this.$refs.commentForm.reset();
      } else {
        alert("Введите корректный комментарий!");
      }
    },
    changePage() {
      // TODO: CODE REVIEW
      this.changeNewsPage = true;
      if (this.currenType != -1) {
        this.types[this.currenType].type = this.currenType + 1;
        console.log("ID: ", this.types[this.currenType].id);
        this.getNews(this.types[this.currenType]);
      } else {
        this.getNews(0);
      }
    },
    init() {
      this.getNewsType();
      this.getNews();
      this.newsFilter = JSON.parse(this.$route.query.newsFilter || "{}");
      this.$delete(this.newsFilter, "type");
    },
    async updateNewsList(type) {
      this.$set(this.newsFilter, 'type', type);
      this.currenType = type - 1;
      this.page = 1;
      this.addNews = false;
      this.showSideModal = false;
    },
    toggleRetrieveNews(id, open, item) {
      this.retrieveNews = open || !this.retrieveNews;
      if (id && typeof id !== "object") {
        this.getNewsId(id, item);
      }
      if (item.type != 3) {
        console.log("news", this.retrieveNews);
        this.currentNews = item;
        this.newsDialog = open;
        this.getComments();
        this.pollNews = false;
      } else {
        this.pollNews = true;
        this.showSideModal = true;
      }
    },
    closeRetrieveNews() {
      this.retrieveNews = false;
      if (this.pollNews === true) this.showSideModal = false;
      this.newsDialog = false;
      this.pollNews = false;
      this.comments = [];
      this.newsId = {};
      this.currentNews = {};
      this.pageCount = 1;
    },
    showAddNews() {
      if (this.retrieveNews) {
        this.closeRetrieveNews();
      };
      this.addNews = !this.addNews;
      this.showSideModal = !this.showSideModal;
    },
    async getNewsId(id, item) {
      const res = await newsIdApi(id);
      // const imgIds = res.data[0].images?.split(',') || [];
      const getImages = []; // promises set
      // imgIds.forEach((imgId) => {
      //   getImages.push(this.getImage(imgId));
      // });
      const images = await Promise.all(getImages);
      this.newsId = {
        ...res.data,
      };
      // this.newsId.poll_result=item.poll_result;
      console.log("Selected post info", res.data);
    },
    async getImage(id) {
      const res = await getNewsPhotoApi(id);
      console.log(res.data);
      return res.data.map((img) => ({
        id: img.id,
        src: img.photo,
      }))[0];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  height: 80vh;
}

.date-grey {
  font-size: 14px;
}
.v-dialog {
  min-height: 80vh !important;
}
.news-type-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  background: #f0f0f3;
  padding: 10px 15px;
  border-radius: 4px;
  margin-right: 10px;
}
.v-card {
  height: 100vh;
}
.left {
  border-right: 1px solid #f0f0f3;
  height: 100%;
  width: 60%;
}
.right {
  height: 100%;
  width: 40%;
  //padding: 24px;
}
</style>
