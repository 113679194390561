<template>
  <v-dialog
    v-model="dialogStaff"
    max-width="500px"
    scrollable
    @click:outside="closeDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="isAddingNewUser" v-on="on" v-bind="attrs">
        Добавить сотрудника
      </v-btn>
      <v-btn
        v-if="!isAddingNewUser"
        v-on="on"
        v-bind="attrs"
        color="#eff0ff"
        elevation="0"
        width="45%"
        style="color: #5b6cff; font-size: 14px"
        @click="dialogOpen"
      >
        Редактировать
      </v-btn>
    </template>
    <v-card>
      <div style="position: absolute">
        <v-avatar class="avatar-background">
          <v-img
            v-if="previewAvatarUrl != null"
            :src="previewAvatarUrl"
          ></v-img>
          <v-img v-else src="@/assets/images/avatar.svg"></v-img>
        </v-avatar>
        <v-file-input
          v-model="previewAvatar"
          @change="onChangeAvatar"
          accept="image/*"
          prepend-icon="mdi-camera-outline"
          hide-input
          class="avatar-input"
        ></v-file-input>
      </div>
      <v-card-title class="create-staff-title">
        <p v-if="isAddingNewUser"> Добавление сотрудника </p>
        <p v-if="isEditingStaff"> Редактирование сотрудника </p>
      </v-card-title>
      <div class="form-container">
        <v-card flat>
          <v-card-text>
            <v-container>
              <v-form ref="newUser">
                <v-row class="staff-field">
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.first_name"
                      :rules="newUserRules"
                      label="Имя*"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.last_name"
                      :rules="newUserRules"
                      label="Фамилия*"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" v-if="isAddingNewUser">
                    <v-select
                      v-model="departmentOfStaff.department_id"
                      :items="departmentsOfComplex"
                      item-text="department_title"
                      item-value="department_id"
                      label="Отдел"
                      hide-details
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6" v-if="isAddingNewUser">
                    <v-text-field
                      v-model="formData.position"
                      :rules="newUserRules"
                      label="Должность*"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="isEditingStaff">
                    <v-text-field
                      v-model="formData.position"
                      :rules="newUserRules"
                      label="Должность*"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.phone"
                      :rules="phoneRules && newUserRules"
                      v-mask="'+# (###) ###-##-##'"
                      placeholder="+7 (___) ___-__-__"
                      label="Телефон*"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.email"
                      label="E-mail"
                      :rules="emailRules"
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.id_number"
                      v-mask="'#########'"
                      :rules="idNumberRules"
                      label="Номер УДВ"
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.idn"
                      v-mask="'############'"
                      :rules="idnRules"
                      label="ИИН"
                      hide-details
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
              <v-form ref="scheduleForm">
                <v-row class="staff-field">
                  <v-col cols="12">
                    <v-divider></v-divider>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="schedule-title">График сотрудника</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <span class="schedule-title">Рабочее время</span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      v-model="scheduleOfStaff.schedule"
                      :items="scheduleList"
                      item-text="title"
                      item-value="id"
                      label="Рабочий график"
                      hide-details
                      clearable
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-menu
                      ref="startTimeMenu"
                      v-model="startTimeMenu"
                      transition="scale-transition"
                      :close-on-content-click="false"
                      :return-value.sync="scheduleOfStaff.start_at"
                      :nudge-right="40"
                      min-width="290"
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="scheduleOfStaff.start_at"
                          label="Начало"
                          hide-details
                          outlined
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="startTimeMenu"
                        v-model="scheduleOfStaff.start_at"
                        :allowed-minutes="allowedMinutes"
                        full-width
                        format="24hr"
                        @click:minute="$refs.startTimeMenu.save(scheduleOfStaff.start_at)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select
                      v-model="scheduleOfStaff.wh"
                      :items="workHours"
                      label="Часы"
                      hide-details
                      outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      class="contract-input"
                      v-model="formData.contract"
                      label="Трудовой договор"
                      placeholder="Прикрепить"
                      persistent-placeholder
                      prepend-icon="mdi-file-outline"
                      hide-details
                      dense
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
      <v-card-actions>
        <v-btn class="cancel-btn" @click="closeDialog"> Отмена </v-btn>
        <v-btn
          v-if="isAddingNewUser"
          @click="submitNewUser"
          :disabled="onStaffSubmit"
          :loading="onStaffSubmit"
        >
          Добавить
        </v-btn>
        <v-btn
          v-if="!isAddingNewUser"
          @click="updateUser(formData)"
          :disabled="onStaffSubmit"
          :loading="onStaffSubmit"
        >
          Готово
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { staffApi, registerStaffApi, updateStaffApi } from "@/api/staff";
import { getDepartmentsApi, getDepartmentsOfComplexApi, addStaffToDepartmentApi } from "@/api/departments";
import { getScheduleApi, addStaffScheduleApi } from "@/api/schedule";

export default {
  props: {
    isAddingNewUser: {
      type: Boolean,
      default: false,
    },
    isEditingStaff: {
      type: Boolean,
      default: false,
    },
    userObj: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    onStaffSubmit: false,
    formData: {},
    dialogStaff: false,
    startTimeMenu: false,
    previewAvatar: null,
    previewAvatarUrl: null,
    departmentsOfComplex: [],
    departmentOfStaff: {},
    scheduleList: [],
    scheduleOfStaff: {},
    allowedMinutes: [0, 15, 30, 45],
    newUserRules: [(v) => !!v || "Обязательное поле"],
    phoneRules: [(v) => (v && v.length == 18) || "Неправильный формат номера"],
    idnRules: [(v) => !v || v.length === 12 || "Неправильный формат ИИН"],
    idNumberRules: [
      (v) => !v || v.length === 9 || "Неправильный номер УДВ",
    ],
    emailRules: [
      (v) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || !v || "Неправильный формат E-mail";
      },
    ],
    scheduleRules: [(v) => !v || "Обязательное поле"],
  }),
  computed: {
    workHours() {
      return Array.from({length: 24}, (_, i) => i + 1);
    },
  },
  async created() {
    const departmentList = await getDepartmentsOfComplexApi();
    this.departmentsOfComplex = departmentList.data;
    const scheduleList = await getScheduleApi();
    this.scheduleList = scheduleList.data;
  },
  methods: {
    onChangeAvatar() {
      this.previewAvatarUrl = URL.createObjectURL(this.previewAvatar);
      this.formData.avatar = this.previewAvatar;
      // this.avatarIcon = "";

      // const [file] = e.target.files;
      // this.$set(this.formData, 'avatar', file);
      // this.$v.formData.avatar.$touch();
      // this.readFile(file);
    },

    submitNewUser() {
      if (this.$refs.newUser.validate() === true) {
        if (this.scheduleOfStaff.schedule && this.scheduleOfStaff.start_at && this.scheduleOfStaff.wh) {
          this.addNewStaff(this.formData);
        } else if (this.scheduleOfStaff.schedule || this.scheduleOfStaff.start_at || this.scheduleOfStaff.wh) {
          this.$store.commit('SHOW_SNACKBAR', 'scheduleFormErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Заполните рабочий график полностью!');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        } else {
          this.addNewStaff(this.formData);
        };
      } else {
        this.$store.commit('SHOW_SNACKBAR', 'correctFormErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Заполните форму правильно!');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
      }
    },
    async updateUser(data) {
      if (this.isEditingStaff) {
        const imageFormData = new FormData();
        imageFormData.append("first_name", data.first_name);
        imageFormData.append("last_name", data.last_name);
        imageFormData.append("phone", data.phone.replace(/[^0-9.]/g, ""));
        imageFormData.append("position", data.position);
        if (data.id_number !== null && data.id_number !== "") imageFormData.append("id_number", data.id_number);
        if (data.idn !== null && data.idn !== "") imageFormData.append("idn", data.idn);
        if (data.email !== null && data.email !== "") imageFormData.append("email", data.email);
        // imageFormData.append("avatar",data.avatar);
        // imageFormData.append("address",data.address);
        imageFormData.append("user_id", data.user_id);
        if (this.$refs.newUser.validate() === true) {
          try {
            await updateStaffApi(imageFormData);
            if (this.scheduleOfStaff.schedule) await addStaffScheduleApi(this.scheduleOfStaff);
            this.$store.commit('SHOW_SNACKBAR', 'updateStaffSuccessSnackbar');
            this.$store.commit(
              'SET_SNACKBAR_TITLE',
              `Сотрудник "${data.first_name} ${data.last_name}" успешно обновлён`
            );
            this.$store.commit('SET_SNACKBAR_COLOR', 'success');
            this.closeDialog();
            this.$emit("update", data.user_id);
          } catch (err) {
            let errorMessage;
            if (err.response.data.message) {
              errorMessage = err.response.data.message;
            }else {
              errorMessage = "Ошибка при редактировании сотрудника";
            };
            this.$store.commit('SHOW_SNACKBAR', 'newStaffErrorSnackbar');
            this.$store.commit('SET_SNACKBAR_TITLE', errorMessage);
            this.$store.commit('SET_SNACKBAR_COLOR', 'error');
            throw err;
          };
        } else {
          this.$store.commit('SHOW_SNACKBAR', 'correctFormErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Заполните форму правильно!');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        };
      };
    },
    async addNewStaff(data) {
      try {
        this.onStaffSubmit = true;
        let _data = { ...data };
        _data.phone = _data.phone.replace(/[^0-9.]/g, "");
        let res = await registerStaffApi(_data);
        if (res.data.message) {
          this.$store.commit('SHOW_SNACKBAR', 'newStaffErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', res.data.message);
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        } else {
          if (this.departmentOfStaff.department_id) {
            this.departmentOfStaff.staff_id = res.data.id;
            await addStaffToDepartmentApi(this.departmentOfStaff);
          };
          if (this.scheduleOfStaff.schedule && this.scheduleOfStaff.start_at && this.scheduleOfStaff.wh) {
            this.scheduleOfStaff.staff = res.data.id;
            await addStaffScheduleApi(this.scheduleOfStaff);
          };
          this.$store.commit('SHOW_SNACKBAR', 'newStaffSuccessSnackbar');
          this.$store.commit(
            'SET_SNACKBAR_TITLE',
            `Сотрудник "${_data.first_name} ${_data.last_name}" успешно добавлен`
          );
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
          this.closeDialog();
          this.$emit("updateList");
        };
      } catch (err) {
        let errorMessage = err.response.data.message;
        this.$store.commit('SHOW_SNACKBAR', 'newStaffErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', errorMessage);
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      } finally {
        this.onStaffSubmit = false;
      };
    },
    closeDialog() {
      this.formData = {};
      this.departmentOfStaff = {};
      this.scheduleOfStaff = {};
      this.$refs.newUser.resetValidation();
      this.$refs.scheduleForm.resetValidation();
      this.dialogStaff = false;
    },
    dialogOpen() {
      if (this.isEditingStaff) {
        this.formData.email = this.userObj.email;
        this.formData.position = this.userObj.position;
        this.formData.phone = this.userObj.phone;
        this.formData.idn = this.userObj.idn;
        this.formData.address = this.userObj.address;
        this.formData.contract = this.userObj.contract;
        this.formData.company = this.userObj.name;
        this.formData.id_number = this.userObj.udv;
        this.formData.avatar = this.userObj.avatar;
        this.formData.user_id = this.userObj.userId;
        this.formData.first_name = this.userObj.name.split(" ")[0];
        this.formData.last_name = this.userObj.name.split(" ")[1];
        this.scheduleOfStaff.staff = this.userObj.userId;
        if (this.userObj.schedule) {
          this.scheduleOfStaff.schedule = this.userObj.schedule.id;
          this.scheduleOfStaff.start_at = this.userObj.schedule.start_at;
          this.scheduleOfStaff.wh = this.userObj.schedule.work_hour;
        };
        console.log("formdata", this.formData);
      } else {
        this.formData = {};
        this.$refs.newUser.reset();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 8px;
  background: transparent;
}
.v-card > .avatar-input {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}
.v-card__text {
  padding: 0;
}
.container {
  padding: 24px 36px !important;
}
.col-12,
.col-md-6 {
  padding: 6px;
}
.form-container {
  height: 501px; // should be constant, else will show scroll on v-card
  overflow-y: auto;
  background: #ffffff;
}
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px !important;
}
.v-card__actions {
  padding: 6px 42px 42px !important;
  background: #ffffff;
}
.v-card__actions .v-btn {
  width: calc((100% - 12px) / 2); // 12px margin betweem buttons
}
.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 12px;
}
</style>