<template>
  <v-dialog
    v-model="dialogContractor"
    max-width="500px"
    scrollable
    @click:outside="closeDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="isAddingNewUser" v-on="on" v-bind="attrs" color="#5b6cff">
        Добавить подрядчика
      </v-btn>
      <v-btn
        v-if="!isAddingNewUser"
        v-on="on"
        v-bind="attrs"
        color="#eff0ff"
        elevation="0"
        width="45%"
        style="color: #5b6cff; font-size: 14px"
        @click="dialogOpen"
      >
        Редактировать
      </v-btn>
    </template>
    <v-card>
      <div style="position: absolute">
        <v-avatar class="avatar-background">
          <v-img
            v-if="previewAvatarUrl != null"
            :src="previewAvatarUrl"
          ></v-img>
          <v-img v-else src="@/assets/images/company.svg"></v-img>
        </v-avatar>
        <v-file-input
          v-model="previewAvatar"
          @change="onChangeAvatar"
          accept="image/*"
          prepend-icon="mdi-camera-outline"
          hide-input
          class="avatar-input"
        ></v-file-input>
      </div>
      <v-card-title class="create-contractor-title">
        <p v-if="isAddingNewUser"> Добавление подрядчика </p>
        <p v-if="isEditingContractor"> Редактирование подрядчика </p>
      </v-card-title>
      <div class="form-container">
        <v-card flat>
          <v-card-text>
            <v-container>
              <v-form ref="newUser">
                <v-row class="contractor-field">
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.company"
                      :rules="newUserRules"
                      label="Название компании"
                      color="#5b6cff"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.service"
                      :rules="newUserRules"
                      label="Название услуги"
                      color="#5b6cff"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.bin"
                      :rules="binRules && newUserRules"
                      v-mask="'############'"
                      label="БИН"
                      color="#5b6cff"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.address"
                      :rules="newUserRules"
                      label="Юр. адрес"
                      color="#5b6cff"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.phone"
                      :rules="phoneRules && newUserRules"
                      v-mask="'+7 (###) ###-##-##'"
                      placeholder="+7 (___) ___-__-__"
                      label="Телефон*"
                      color="#5b6cff"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.email"
                      :rules="emailRules && newUserRules"
                      label="E-mail"
                      color="#5b6cff"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.first_name"
                      :rules="newUserRules"
                      label="Имя руководителя"
                      color="#5b6cff"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="formData.last_name"
                      :rules="newUserRules"
                      label="Фамилия"
                      color="#5b6cff"
                      hide-details
                      outlined
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      class="contract-input"
                      v-model="formData.contract"
                      label="Трудовой договор"
                      placeholder="Прикрепить"
                      persistent-placeholder
                      prepend-icon="mdi-file-outline"
                      color="#5b6cff"
                      hide-details
                      dense
                    ></v-file-input>
                  </v-col>
                  <div style="margin: 0 auto">
                    <div class="switch-button">
                      Разовая услуга
                      <label class="switch">
                        <input
                          type="checkbox"
                          :checked="formData.is_onetime"
                          @change="
                            (e) =>
                              $set(formData, 'is_onetime', e.target.checked)
                          "
                        />
                        <span class="slider"></span>
                      </label>
                    </div>
                  </div>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
        </v-card>
      </div>
      <v-card-actions>
        <v-btn class="cancel-btn" @click="closeDialog"> Отмена </v-btn>
        <v-btn
          v-if="isAddingNewUser"
          @click="submitNewUser"
          :disabled="loading"
        >
          <div v-show="loading">
            <v-progress-circular
              indeterminate
              color="#5b6cff"
              size="30"
              width="3"
            ></v-progress-circular>
          </div>
          <div v-show="!loading">Добавить</div>
        </v-btn>
        <v-btn
          v-if="!isAddingNewUser"
          @click="updateUser(formData)"
          :disabled="loading"
        >
          <div v-show="loading">
            <v-progress-circular
              indeterminate
              color="#5b6cff"
              size="30"
              width="3"
            ></v-progress-circular>
          </div>
          <div v-show="!loading">Готово</div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { addContractorApi, updateContractorApi } from "@/api/contractors";
import { getScheduleApi } from "@/api/schedule";

export default {
  props: {
    isAddingNewUser: {
      type: Boolean,
      default: false,
    },
    isEditingContractor: {
      type: Boolean,
      default: false,
    },
    userObj: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    loading: false,
    formData: {},
    dialogContractor: false,
    previewAvatar: null,
    previewAvatarUrl: null,
    scheduleList: [],
    newUserRules: [(v) => !!v || "Обязательное поле"],
    phoneRules: [(v) => (v && v.length == 18) || "Неправильный формат номера"],
    binRules: [(v) => (v && v.length == 12) || "Неправильный формат БИН"],
    emailRules: [
      (v) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(v) || !v || "Неправильный формат E-mail";
      },
    ],
  }),
  async created() {
    const scheduleList = await getScheduleApi();
    this.scheduleList = scheduleList.data;
  },
  methods: {
    onChangeAvatar() {
      this.previewAvatarUrl = URL.createObjectURL(this.previewAvatar);
      this.formData.avatar = this.previewAvatar;
      // this.avatarIcon = "";

      // const [file] = e.target.files;
      // this.$set(this.formData, 'avatar', file);
      // this.$v.formData.avatar.$touch();
      // this.readFile(file);
    },

    submitNewUser() {
      if (this.$refs.newUser.validate() === true) {
        this.addNewContractor(this.formData);
      } else {
        alert("Заполните форму правильно!");
      }
    },
    async updateUser(data) {
      if (this.isEditingContractor) {
        let _data = { ...data };
        _data.chief = (data.first_name || "-") + " " + (data.last_name || "-");
        _data.name = data.company;
        _data.phone = _data.phone.replace(/[^0-9.]/g, "");
        _data.idn = data.bin;
        _data.service = data.service;
        _data.address = data.address;
        _data.email = data.email;
        _data.contract = data.contract;
        _data.is_onetime = data.is_onetime;
        console.log("_data", _data);
        if (this.$refs.newUser.validate() === true) {
          await updateContractorApi(_data);
          this.closeDialog();
          this.$emit("update", data.contractor_id);
        } else {
          alert("Заполните форму правильно!");
        }
      }
    },
    async addNewContractor(data) {
      let _data = { ...data };
      _data.phone = _data.phone.replace(/[^0-9.]/g, "");
      _data.chief = (data.first_name || "-") + " " + (data.last_name || "-");
      _data.name = data.company;
      _data.idn = data.bin;
      await addContractorApi(_data);
      console.log("New contractor:", _data);
      this.closeDialog();
      this.$emit("updateList");
    },
    closeDialog() {
      this.formData = {};
      this.$refs.newUser.reset();
      this.dialogContractor = false;
    },
    dialogOpen() {
      if (this.isEditingContractor) {
        this.formData.email = this.userObj.email;
        this.formData.contractor_id = this.userObj.id;
        this.formData.phone = this.userObj.phone;
        this.formData.bin = this.userObj.bin;
        this.formData.address = this.userObj.address;
        this.formData.company = this.userObj.name;
        this.formData.service = this.userObj.service;
        this.formData.avatar = this.userObj.avatar;
        this.formData.first_name = this.userObj.chief.split(" ")[0];
        this.formData.last_name = this.userObj.chief.split(" ")[1];
        this.formData.is_onetime = this.userObj.is_onetime;
        console.log("formdata", this.formData);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  border-radius: 8px;
  background: transparent;
}
.v-card > .avatar-input {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}
.v-card__text {
  padding: 0;
}
.container {
  padding: 24px 36px !important;
}
.col-12,
.col-md-6 {
  padding: 6px;
}
.form-container {
  height: 458px; // should be constant, else will show scroll on v-card
  overflow-y: auto;
  background: #ffffff;
}
.v-text-field--outlined > .v-input__control > .v-input__slot {
  min-height: 40px !important;
}
.v-card__actions {
  padding: 6px 42px 42px !important;
  background: #ffffff;
}
.v-card__actions .v-btn {
  width: calc((100% - 12px) / 2); // 12px margin betweem buttons
}
.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 12px;
}
</style>