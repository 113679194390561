import { http } from './http'
import store from '@/store/index'

export const getCommentsApi = async (params,size,page) => {
  // store.commit('ADD_LOADING', 'comments.getCommentsApi')
  const url = `/v1/comments/comment/?page_size=${size}&page=${page}&ordering=-created_at`
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers, params })
  // store.commit('DEL_LOADING', 'comments.getCommentsApi')
  return res
}
export const submitCommentApi = async (data) => {
  console.log(data.data)
  // store.commit('ADD_LOADING', 'comments.submitCommentApi')
  const url = `/v1/comments/comment/`
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`
  }
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.post(url, data.data,{ headers })
      // .catch((e) => {
      //   store.commit('DEL_LOADING', 'applications.submitCommentApi');
      //   Promise.reject(e);
      // });
  // store.commit('DEL_LOADING', 'applications.submitCommentApi');
  console.log('new comment',res.data)
  return res
}



