import { http, objectToFormData } from './http'
import store from '@/store/index'

export const staffApi = async (params) => {
  store.commit('ADD_LOADING', 'staff.staffApi');
  const url = '/v1/staff';
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers, params })
    .catch((e) => {
      store.commit('DEL_LOADING', 'staff.staffApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'staff.staffApi');
  console.log(res.data)
  return res;
};

export const getStaffByIdApi = async (id) => {
  store.commit('ADD_LOADING', 'staff.getStaffByIdApi');
  const url = `/v1/staff?user_id=${id}`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.get(url, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'staff.getStaffByIdApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'staff.getStaffByIdApi');
  return res;
};

/**
 * make multipart/form-data request
 * @param {*} data 
 */
export const registerStaffApi = async (data) => {
  console.log(data);
  store.commit('ADD_LOADING', 'staff.registerStaffApi');
  const url = `/v1/register_staff`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
    'Content-Type': 'multipart/form-data',
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const formDataParams = objectToFormData(data);
  const res = await http.post(url, formDataParams, { headers })
    .catch((e) => {
      store.commit('DEL_LOADING', 'staff.registerStaffApi');
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', 'staff.registerStaffApi');
  return res;
};


export const deleteStaffApi = async (data) => {
  console.log(data);
  store.commit('ADD_LOADING', 'staff.deleteStaffApi');
  const url = `/v1/delete_staff`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const formDataParams = objectToFormData(data);
  const res = await http.delete(url,{ headers, data })
      .catch((e) => {
        store.commit('DEL_LOADING', 'staff.deleteStaffApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'staff.deleteStaffApi');
  return res;
};

export const updateStaffApi = async (data) => {
  store.commit('ADD_LOADING', 'staff.updateStaffApi');
  const url = `/v1/register_staff`;
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
    'Content-Type': 'multipart/form-data',
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const formDataParams = objectToFormData(data);
  const res = await http.put(url,data,{ headers })
      .catch((e) => {
        store.commit('DEL_LOADING', 'staff.updateStaffApi');
        return Promise.reject(e);
      });
  store.commit('DEL_LOADING', 'staff.updateStaffApi');
  return res;
};


