<template>
  <div class="content">
    <v-row no-gutters class="side-paddings">
      <div class="tab-row">
        <div
          class="tab-item"
          @click="activetab = '1'"
          :class="[activetab === '1' ? 'active' : '']"
        >
          Финансовый поток
        </div>
<!--        <div-->
<!--          class="tab-item"-->
<!--          @click="activetab = '2'"-->
<!--          :class="[activetab === '2' ? 'active' : '']"-->
<!--        >-->
<!--          Финансовые отчеты-->
<!--        </div>-->
        <div
          class="tab-item"
          @click="activetab = '3'"
          :class="[activetab === '3' ? 'active' : '']"
        >
          Тарифы
        </div>
      </div>
    </v-row>
    <div v-if="activetab === '1'" class="finance">
      <div class="finance-balance">
          <div class="finance-balance-item">
            <div class="left">Остаток на текущем счете</div>
            <div class="saving-balance">
              <div style="margin-right: 10px;">
                {{ parseFloat(savingBalance).toLocaleString('ru-RU') }} &#8376;
              </div>
              <v-menu offset-y>
                <template v-slot:activator="{on,attrs}">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                  >
                    <v-icon color="#aaabb9" size="30px">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>
                    <EditSavingBalance
                      ref="savingBalanceForm"
                      :savingBalance="savingBalance"
                      @emitUpdateSavingBalance="updateSavingBalance"
                    />
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <!-- <div class="finance-balance-item">
            <div class="left">Остаток на сберегательном счете</div>
            <div class="right">0 &#8376;</div>
          </div>
          <div class="finance-balance-item">
            <div class="left">Задолженность на управление и содержание</div>
            <div class="right">0 &#8376;</div>
          </div>
          <div class="finance-balance-item">
            <div class="left">Задолженность для накоплений на кап. ремонт</div>
            <div class="right">0 &#8376;</div>
          </div> -->
      </div>
      <div class="finance-date">
        <v-menu
          v-model="datePickerDialog"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              label="Выберите месяц"
              prepend-inner-icon="mdi-calendar"
              :value="computedDateFormattedMomentjs"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
              outlined
              background-color="#eff0ff"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="
              selectedDate = new Date(date),
              getFilterByPeriod,
              datePickerDialog = false
            "
            type="month"
          ></v-date-picker>
        </v-menu>
        <div v-if="isClosed" class="share-with-residents">
          <span class="share-reminder">Внесённые изменения сохранены</span>
        </div>
        <div v-else class="share-with-residents">
          <span class="share-reminder">Последние изменения не опубликованы</span>
          <v-btn
            @click="shareWithResidents"
            :disabled="debits.length === 0 || credits.length === 0"
          >
            Опубликовать для жителей
          </v-btn>
        </div>
      </div>
      <div class="finance-operations">
        <div class="io-item">
          <div class="name">Текущие доходы</div>
          <div class="sum">{{ parseFloat(totalDebit).toLocaleString('ru-RU') }} &#8376;</div>
          <v-card
            v-for="debit in debits"
            :key="debit.finance_operation.id"
            class="accardeon"
          >
            <v-card
              class="finance-operation-item"
              @click="toggleAccordeons(debit.finance_operation.id)"
            >
              <div class="left">
                <v-icon color="primary" size="36px">
                  {{ openedAccordeons.includes(debit.finance_operation.id) ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
                {{ debit.finance_operation.title }}
              </div>
              <div class="right">
                <div style="padding: 8px;">
                  {{ parseFloat(debit.finance_operation.amount).toLocaleString('ru-RU') }} &#8376;
                </div>
                <v-menu offset-y>
                  <template v-slot:activator="{on,attrs}">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon color="#aaabb9" size="30px">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <EditFinanceOperation
                        :operationType="debit.finance_operation.type"
                        :operationId="debit.finance_operation.id"
                        :operationYear="year"
                        :operationMonth="month"
                        :operationTitle="debit.finance_operation.title"
                        :operationAmount="debit.finance_operation.amount"
                        :operationDetails="debit.details"
                        @emitUpdateOperation="updateOperation"
                      />
                    </v-list-item>
                    <v-list-item>
                      <v-btn color="error" text @click="toggleDeleteOperation(debit)">Удалить</v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-card>
            <v-expand-transition>
              <div
                v-if="openedAccordeons.includes(debit.finance_operation.id)"
                class="operation-details"
              >
                <v-divider></v-divider>
                <div
                  v-for="detail in debit.details"
                  :key="detail.id"
                  class="operation-details-item"
                >
                  <div class="operation-details-title">
                    {{ detail.title }}
                  </div>
                  <div class="operation-details-amount">
                    {{ parseFloat(detail.amount).toLocaleString('ru-RU') }} &#8376;
                  </div>
                </div>
                <v-row no-gutters class="add-detail-button">
                  <v-dialog
                    v-model="addDetailDialog[debit.finance_operation.id]"
                    @click:outside="closeAddDetailDialog(debit.finance_operation.id)"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        color="primary"
                      >
                        + Добавить поле
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Добавить поле в {{ `"${debit.finance_operation.title}"` }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-form ref="addDetailForm" :disabled="submitForm">
                            <v-row no-gutters>
                              <v-col cols="12" md="8">
                                <v-text-field
                                  v-model="detailTitle"
                                  label="Описание"
                                  :rules="operationRules"
                                  hide-details
                                  required
                                  outlined
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="detailAmount"
                                  label="Сумма"
                                  append-icon="mdi-currency-kzt"
                                  type="number"
                                  :rules="operationRules"
                                  hide-details
                                  required
                                  outlined
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          :disabled="submitForm"
                          :loading="submitForm"
                          @click="addOperationDetail({
                            finance_operation: debit.finance_operation.id,
                            title: detailTitle,
                            amount: detailAmount,
                            forOperationUpdate: debit.finance_operation,
                          })"
                        >
                          Добавить
                        </v-btn>
                        <v-btn
                          color="error"
                          text
                          :disabled="submitForm"
                          :loading="submitForm"
                          @click="closeAddDetailDialog"
                        >
                          Отмена
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </div>
            </v-expand-transition>
          </v-card>
          <v-dialog
            v-model="addIncomeDialog"
            @click:outside="closeAddOperationDialog"
            max-width="600px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                color="primary"
                class="add-finance-operation"
              >
                Добавить доход
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Добавление дохода</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="addOperationForm" :disabled="submitForm">
                    <v-row no-gutters class="add-finance-operation-field">
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="operationTitle"
                          label="Введите наименование дохода"
                          :rules="operationRules"
                          hide-details
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-tooltip
                          max-width="300"
                          color="warning"
                          :disabled="isDetailsDisabled"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="operationAmount"
                              label="Сумма"
                              append-icon="mdi-currency-kzt"
                              :readonly="!isDetailsDisabled"
                              :rules="operationRules"
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                              required
                              outlined
                            ></v-text-field>
                          </template>
                          <span class="tooltip-text">
                            По умолчанию данное поле отключено. Заполняя
                            дополнительные поля рассчёт идёт автоматически.<br/>
                            Если у данной операции не должно быть дополнительных
                            полей отметьте галочку ниже
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-checkbox v-model="isDetailsDisabled">
                  <template v-slot:label>
                    <span>Данная операция не имеет подпунктов</span>
                  </template>
                </v-checkbox>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  :disabled="submitForm"
                  :loading="submitForm"
                  @click="addFinanceOperation({
                    type: 0,
                    title: operationTitle,
                    amount: operationAmount,
                    month: JSON.stringify(month),
                    year: JSON.stringify(year),
                  })"
                >
                  Сохранить
                </v-btn>
                <v-btn
                  color="error"
                  text
                  :disabled="submitForm"
                  :loading="submitForm"
                  @click="closeAddOperationDialog"
                >
                  Отмена
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div class="io-item">
          <div class="name">Текущие расходы</div>
          <div class="sum">{{ parseFloat(totalCredit).toLocaleString('ru-RU') }} &#8376;</div>
          <v-card
            v-for="credit in credits"
            :key="credit.finance_operation.id"
            class="accardeon"
          >
            <v-card
              class="finance-operation-item"
              @click="toggleAccordeons(credit.finance_operation.id)"
            >
              <div class="left">
                <v-icon color="primary" size="36px">
                  {{ openedAccordeons.includes(credit.finance_operation.id) ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
                {{ credit.finance_operation.title }}
              </div>
              <div class="right">
                <div style="padding: 8px;">
                  {{ parseFloat(credit.finance_operation.amount).toLocaleString('ru-RU') }} &#8376;
                </div>
                <v-menu offset-y>
                  <template v-slot:activator="{on,attrs}">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon color="#aaabb9" size="30px">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <EditFinanceOperation
                        :operationType="credit.finance_operation.type"
                        :operationId="credit.finance_operation.id"
                        :operationYear="year"
                        :operationMonth="month"
                        :operationTitle="credit.finance_operation.title"
                        :operationAmount="credit.finance_operation.amount"
                        :operationDetails="credit.details"
                        @emitUpdateOperation="updateOperation"
                      />
                    </v-list-item>
                    <v-list-item>
                      <v-btn color="error" text @click="toggleDeleteOperation(credit)">Удалить</v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-card>
            <v-expand-transition>
              <div
                v-if="openedAccordeons.includes(credit.finance_operation.id)"
                class="operation-details"
              >
                <v-divider></v-divider>
                <div
                  v-for="detail in credit.details"
                  :key="detail.id"
                  class="operation-details-item"
                >
                  <div class="operation-details-title">
                    {{ detail.title }}
                  </div>
                  <div class="operation-details-amount">
                    {{ parseFloat(detail.amount).toLocaleString('ru-RU') }} &#8376;
                  </div>
                </div>
                <v-row no-gutters class="add-detail-button">
                  <v-dialog
                    v-model="addDetailDialog[credit.finance_operation.id]"
                    @click:outside="closeAddDetailDialog(credit.finance_operation.id)"
                    max-width="600px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        text
                        color="primary"
                      >
                        + Добавить поле
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">Добавить поле в {{ `"${credit.finance_operation.title}"` }}</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-form ref="addDetailForm" :disabled="submitForm">
                            <v-row no-gutters>
                              <v-col cols="12" md="8">
                                <v-text-field
                                  v-model="detailTitle"
                                  label="Описание"
                                  :rules="operationRules"
                                  hide-details
                                  required
                                  outlined
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="detailAmount"
                                  label="Сумма"
                                  append-icon="mdi-currency-kzt"
                                  type="number"
                                  :rules="operationRules"
                                  hide-details
                                  required
                                  outlined
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          :disabled="submitForm"
                          :loading="submitForm"
                          @click="addOperationDetail({
                            finance_operation: credit.finance_operation.id,
                            title: detailTitle,
                            amount: detailAmount,
                            forOperationUpdate: credit.finance_operation,
                          })"
                        >
                          Добавить
                        </v-btn>
                        <v-btn
                          color="error"
                          text
                          :disabled="submitForm"
                          :loading="submitForm"
                          @click="closeAddDetailDialog"
                        >
                          Отмена
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </div>
            </v-expand-transition>
          </v-card>
          <v-dialog
            v-model="addOutcomeDialog"
            @click:outside="closeAddOperationDialog"
            max-width="600px"
            >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                text
                color="primary"
                class="add-finance-operation"
              >
                Добавить расход
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">Добавление расхода</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="addOperationForm" :disabled="submitForm">
                    <v-row no-gutters class="add-finance-operation-field">
                      <v-col cols="12" md="8">
                        <v-text-field
                          v-model="operationTitle"
                          label="Введите наименование расхода"
                          hide-details
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="4">
                        <v-tooltip
                          max-width="300"
                          color="warning"
                          :disabled="isDetailsDisabled"
                          top
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="operationAmount"
                              label="Сумма"
                              append-icon="mdi-currency-kzt"
                              :readonly="!isDetailsDisabled"
                              :rules="operationRules"
                              v-bind="attrs"
                              v-on="on"
                              hide-details
                              required
                              outlined
                            ></v-text-field>
                          </template>
                          <span class="tooltip-text">
                            По умолчанию данное поле отключено. Заполняя
                            дополнительные поля рассчёт идёт автоматически.<br/>
                            Если у данной операции не должно быть дополнительных
                            полей отметьте галочку ниже
                          </span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-checkbox v-model="isDetailsDisabled">
                  <template v-slot:label>
                    <span>Данная операция не имеет подпунктов</span>
                  </template>
                </v-checkbox>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  :disabled="submitForm"
                  :loading="submitForm"
                  @click="addFinanceOperation ({
                    type: 1,
                    title: operationTitle,
                    amount: operationAmount,
                    month: JSON.stringify(month),
                    year: JSON.stringify(year),
                  })"
                >
                  Сохранить
                </v-btn>
                <v-btn
                  color="error"
                  text
                  :disabled="submitForm"
                  :loading="submitForm"
                  @click="closeAddOperationDialog"
                >
                  Отмена
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
    <!-- <div v-if="activetab === '2'" class="finance-box"></div> -->
    <div v-if="activetab === '3'" class="finance-box">
      <vc-donut
        :sections="sections"
        :total="total"
        :size="200"
        :thickness="6"
        style="padding: 80px 30px;"
      >
        <h3>{{total}} &#8376;</h3>
        <div class="price" style="font-size: 14px">за м<sup><small>2</small></sup></div>
      </vc-donut>
      <v-row no-gutters style="display: flex; flex-direction: column; padding: 0 30px;">
        <v-row no-gutters>
          <div v-for="tariff in tariffs" :key="tariff.id" style="justify-content: space-around;">
            <div class="pick-executor mr-3 mb-4" style="width: 382px !important;">
              <div class="d-flex left align-center">
                <div
                  class="rounded-circle pa-1 mr-1 flex-nowrap"
                  style="resize: none;height: 6px !important;"
                  :style="{color:tariff.color,background:tariff.color}"
                ></div>
                <EditFinanceName
                  :tariff="tariff"
                  class="mr-2"
                  @update="updateTariff"
                />
              </div>
              <v-row
                no-gutters
                class="justify-end"
              >
                <EditFinance
                  :tariff="tariff"
                  class="mr-2"
                  @update="updateTariff"
                />
                <v-menu offset-y>
                  <template v-slot:activator="{on,attrs}">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                    >
                      <v-icon color="#aaabb9" size="30px">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-btn color="#ff7171" text @click="toggleDeleteTariff(tariff)">Удалить</v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </div>
          </div>
        </v-row>
        
        <div v-if="addTariff" class="create-tariff pick-executor mr-3 mb-4">
          <v-tooltip v-model="colorPickerHint" top>
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                v-on="on"
                class="rounded-circle pa-1 mr-1 flex-nowrap"
                style="resize: none;height: 6px !important; cursor: pointer"
                :style="{color:pickedColor,background:pickedColor}"
                @click="pickColor=!pickColor"
              ></div>
            </template>
            <span>Выберите цвет</span>
          </v-tooltip>
          <v-color-picker
            v-if="pickColor"
            show-swatches
            hide-canvas
            hide-inputs
            hide-sliders
            :value="pickedColor"
            v-model="pickedColor"
            @input="pickColor=false"
          ></v-color-picker>
          <input v-show="!pickColor" v-model="tariffTitle" style="margin:0px" type="text" placeholder="Введите название тарифа">
          <div class="price">
            <input style="margin: 0px" v-model="tariffPrice" type="text" placeholder="Сумма">
            <v-icon
              size="15"
              color="#8e90a3"
              style="padding-right: 4px;"
            >
              mdi-currency-kzt
            </v-icon>
          </div>
          <div
            class="save-btn"
            @click="addNewTariff({
              title:tariffTitle,
              color:pickedColor,
              price:tariffPrice
            })"
            v-if="addTariff" style="width: 30px;height: 30px"
          >
            &#10003;
          </div>
          <div
            class="save-btn ml-2"
            @click="addTariff=false"
            v-if="addTariff" style="width: 30px;height: 30px"
          >
            X
          </div>
        </div>
        <div
          class="save-btn mb-4" v-if="!addTariff" @click="(addTariff = true) && (colorPickerHint = true)"
          style="width: 382px;font-size: 20px;height: 47px;"
        >
          +
        </div>
      </v-row>
    </div>
    <div v-if="showDeleteOperation" style="z-index: 10000; position: absolute">
      <DeletionConfirm
        title="Вы действительно хотите удалить эту операцию?"
        :description="`${debit.finance_operation.title}`"
        @close="toggleDeleteOperation"
        @delete="deleteFinanceOperation({
          id: debit.finance_operation.id,
          type: debit.finance_operation.type,
          deletedAmount: debit.finance_operation.amount,
        })"
      />
    </div>
    <div v-if="showDeleteTariff" style="z-index: 10000; position: absolute">
      <DeletionConfirm
        title="Вы действительно хотите удалить этот тариф?"
        :description="`${tariff.title}`"
        @close="toggleDeleteTariff"
        @delete="deleteTariff(tariff.id)"
      />
    </div>
  </div>
</template>
<script>
import {
  getFinanceOperationApi,
  addFinanceOperationApi,
  updateFinanceOperationApi,
  deleteFinanceOperationApi,
  addOperationDetailApi,
  updateOperationDetailApi,
  deleteOperationDetailApi,
  getFilterByPeriodApi,
  getTariffApi,
  addTariffApi,
  getSavingBalanceApi,
  updateSavingBalanceApi,
  closeApi,
  deleteTariffApi,
  updateTariffApi,
} from '@/api/finance';
import cloneDeep from 'lodash/cloneDeep';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';
import EditSavingBalance from '@/components/modals/EditSavingBalance.vue'
import EditFinance from "./EditFinance";
import EditFinanceName from "./EditFinanceName";
import DeletionConfirm from '@/components/modals/DeletionConfirm.vue';
import EditFinanceOperation from '@/components/modals/EditFinanceOperation.vue'

export default {
  data: () => ({
    activetab: "1",
    date: "",
    selectedDate: "",
    pickedColor:"#5B6CFF",
    tariffTitle:"",
    tariffPrice:"",
    operationTitle:"",
    operationAmount:"0",
    detailTitle: "",
    detailAmount: "",
    isDetailsDisabled: false,
    submitForm: false,
    operationsByPeriod: [],
    sections: [],
    total:0,
    formArray: [],
    tariffs: [],
    datePickerDialog: false,
    addTariff:false,
    pickColor:false,
    openedAccordeons: [],
    savingBalance: null,
    showDeleteOperation: false,
    showDeleteTariff: false,
    editOperation: false,
    addDetailDialog: {},
    addIncomeDialog: false,
    addOutcomeDialog: false,
    operationRules: [ v => !!v || 'Обязательное поле' ],
  }),
  components:{
    EditSavingBalance,
    EditFinance,
    EditFinanceName,
    DeletionConfirm,
    EditFinanceOperation,
  },
  computed: {
    year() {
      return this.selectedDate ? this.selectedDate.getFullYear() : null;
    },
    month() {
      return this.selectedDate ? this.selectedDate.getMonth() + 1 : null;
    },
    computedDateFormattedMomentjs () { // formatting date inside datepicker input
      return this.selectedDate ? this.$moment(this.selectedDate).format("MMMM YYYY") : '';
    },
    debits() { // filters income operations
      return this.operationsByPeriod.filter((d) => d.finance_operation?.type === '0');
    },
    credits() { // filters outcome operations
      return this.operationsByPeriod.filter((d) => d.finance_operation?.type === '1');
    },
    totalDebit() {
      return this.debits.reduce((acc, cur) => {
        let sum = acc;
        sum += Number.parseFloat(cur.finance_operation.amount);
        return sum
      }, 0);
    },
    totalCredit() {
      return this.credits.reduce((acc, cur) => {
        let sum = acc;
        sum += Number.parseFloat(cur.finance_operation.amount);
        return sum
      }, 0);
    },
    isClosed() {
      if (this.operationsByPeriod && this.operationsByPeriod.length > 0) {
        return this.operationsByPeriod[0].finance_operation.closed;
      } else {
        return false;
      };
    },
  },
  watch: {
    selectedDate() {
      this.getFilterByPeriod();
      localStorage.setItem('storedDate', this.year + '-' + this.month);
    },
    isDetailsDisabled() {
      this.operationAmount = "0";
    },
  },
  created() {
    this.init(); // method for update data
    // Saving picked date to local storage
    let storedDate = localStorage.getItem('storedDate');
    if (!storedDate) {
      this.selectedDate = new Date();
      localStorage.setItem('storedDate', this.year + '-' + this.month);
    } else {
      this.selectedDate = new Date(storedDate.substring(0, 4), (storedDate.substring(5) - 1));
    };
  },
  methods: {
    async getSavingBalance() {
      const res = await getSavingBalanceApi();
      this.savingBalance = res.data.saving_balance;
    },

    async updateSavingBalance(data) {
      try {
        if (data !== "") {
          await updateSavingBalanceApi({ balance: data });
          this.init();
          this.$refs.savingBalanceForm.editSavingBalance = false;
          this.$store.commit('SHOW_SNACKBAR', 'updateSavingBalanceSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Счёт успешно обновлён');
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        } else {
          this.$store.commit('SHOW_SNACKBAR', 'updateSavingBalanceErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Поле не может быть пустым!');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        }
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'updateSavingBalanceErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при обновлении счёта');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },

    async getFilterByPeriod() {
      if (this.year && this.month) {
        const res = await getFilterByPeriodApi({ year: this.year, month: this.month });
        this.operationsByPeriod = res.data;
      };
    },

    async shareWithResidents() {
      const data = {
        year: JSON.stringify(this.year),
        month: JSON.stringify(this.month),
      };
      try {
        await closeApi(data);
        this.$store.commit('SHOW_SNACKBAR', 'closeMonthSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Изменения успешно опубликованы');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        this.init();
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'closeMonthErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при публикации изменений');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
      };
    },

    async addFinanceOperation(data){
      this.operationTitle = this.operationTitle.trim();
      if (this.$refs.addOperationForm.validate() === true && this.operationTitle !== '') {
        try {
          this.submitForm = true;
          await addFinanceOperationApi(data);
          this.closeAddOperationDialog();
          this.init();
          this.$store.commit('SHOW_SNACKBAR', 'addOperationSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Операция успешно добавлена');
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        } catch (err) {
          this.$store.commit('SHOW_SNACKBAR', 'addOperationErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при добавлении операции');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
          throw err;
        } finally {
          this.submitForm = false;
        };
      } else {
        this.$store.commit('SHOW_SNACKBAR', 'addOperationErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Поле не может быть пустым!');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
      };
    },
    closeAddOperationDialog() {
      this.operationTitle = "";
      this.operationAmount = "0";
      this.$refs.addOperationForm.resetValidation();
      if (this.addIncomeDialog) this.addIncomeDialog = false;
      if (this.addOutcomeDialog) this.addOutcomeDialog = false;
    },
    toggleDeleteOperation(debitIndex) {
      this.showDeleteOperation = !this.showDeleteOperation;
      this.debit = debitIndex;
    },
    toggleDeleteTariff(tariffIndex) {
      this.showDeleteTariff = !this.showDeleteTariff;
      this.tariff = tariffIndex;
    },
    async deleteFinanceOperation(data){
      try {
        // Deleting whole finance operation
        await deleteFinanceOperationApi({ id: data.id });
        this.$store.commit('SHOW_SNACKBAR', 'deleteOperationSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Операция успешно удалена');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        this.init();
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'deleteOperationErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при удалении операции');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },

    async addOperationDetail(data) {
      this.detailTitle = this.detailTitle.trim();
      data.title = data.title.trim();
      const operationObject = data.forOperationUpdate;
      delete data.forOperationUpdate;
      // Adding detail itself
      if (this.$refs.addDetailForm[
          this.openedAccordeons.indexOf(data.finance_operation)
        ].validate() === true && this.detailTitle !== '') {
        try {
          this.submitForm = true;
          await addOperationDetailApi(data);
          // Updating finance operation by adding detail
          const updateOperationFromDetail = {
            type: operationObject.type,
            id: operationObject.id,
            year: JSON.stringify(this.year),
            month: JSON.stringify(this.month),
            title: operationObject.title,
            amount: parseInt(operationObject.amount) + parseInt(this.detailAmount),
          };
          await updateFinanceOperationApi(updateOperationFromDetail);
          this.closeAddDetailDialog();
          this.init();
          this.$store.commit('SHOW_SNACKBAR', 'addDetailSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Поле успешно добавлено');
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        } catch (err) {
          this.$store.commit('SHOW_SNACKBAR', 'addDetailErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при добавлении поля');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
          throw err;
        } finally {
          this.submitForm = false;
        };
      } else {
        this.$store.commit('SHOW_SNACKBAR', 'addDetailErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Поле не может быть пустым!');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
      };
    },
    closeAddDetailDialog() {
      this.detailTitle = this.detailAmount = "";
      this.$refs.addDetailForm[0].resetValidation();
      this.addDetailDialog = {};
    },
    async updateOperation(newData) {
      try {
        const newDataOfOperation = {
          details: newData.newOperationDetails,
          finance_operation: {
            type: newData.operationType,
            id: newData.operationId,
            year: JSON.stringify(newData.operationYear),
            month: JSON.stringify(newData.operationMonth),
            title: newData.newOperationTitle,
            amount: newData.sumOfDetails,
          },
        };
        // Updating operation
        newDataOfOperation.details.forEach(detail => {
          updateOperationDetailApi(detail)
            .catch(error => {
              console.log('Error while updating operation', error);
              return error;
            });
        });
        // Updating finance operation
        await updateFinanceOperationApi(newDataOfOperation.finance_operation);
        this.init();
        this.$store.commit('SHOW_SNACKBAR', 'updateOperationSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Операция успешно обновлена');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'updateOperationErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при обновлении операции');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
    async addNewTariff(data){
      await addTariffApi(data);
      this.tariffPrice="";
      this.tariffTitle="";
      this.addTariff=false;
      this.init();
    },
    async getTariff(){
      this.sections=[];
      this.total=0;
      const res = await getTariffApi();
      this.tariffs=res.data;
      for(let i=0;i<this.tariffs.length;i++){
        this.total+=parseInt(this.tariffs[i].price);
        this.sections.push({
          title:this.tariffs[i].title,
          value:parseInt(this.tariffs[i].price),
          color:this.tariffs[i].color,
        });
      };
      console.log('Tariffs:',this.sections);
    },
    async updateTariff(data){
      await updateTariffApi(data).then(() => this.init());
    },
    async deleteTariff(id){
      await deleteTariffApi({id:id}).then(() => this.init());
    },
    toggleAccordeons(field) {
      if (this.openedAccordeons.includes(field)) {
        this.openedAccordeons = this.openedAccordeons.filter((f) => f !== field);
      } else {
        this.openedAccordeons.push(field);
      };
    },
    init() {
      this.getSavingBalance();
      this.getFilterByPeriod();
      this.getTariff();
    },
  }
};
</script>

<style lang="scss" scoped>
.v-card__title {
  padding: 16px 30px 8px !important;
}
.v-card__text {
  padding: 0px 12px !important;
}
.container {
  padding: 0px 12px !important;
}
.tooltip-text {
  color: #000000;
  font-weight: 500;
}
.col-12, .col-md-8, .col-md-4 {
  padding: 6px !important;
}
.v-card__actions {
  padding: 8px 30px 16px !important;
}
.v-color-picker {
  position: absolute;
  z-index: 1;
}
</style>