import { http, objectToFormData } from './http';
import store from '@/store/index';

const baseCall = async (url, method = 'get', name, data, headersProp = {}) => {
  store.commit('ADD_LOADING', name);
  const headers = {
    'Authorization': `Bearer ${store.state.user.access}`,
    ...headersProp,
  };
  if (store.state.currentComplex) {
    headers['CurrentComplex'] = store.state.currentComplex;
  }
  const res = await http.request({ method, url, data, headers })
    .catch((e) => {
      store.commit('DEL_LOADING', name);
      return Promise.reject(e);
    });
  store.commit('DEL_LOADING', name);
  return res;
};


export const getSavingBalanceApi = async () => {
  const name = 'finance.getSavingBalanceApi';
  const url = '/v1/finance/saving_balance';
  return baseCall(url, 'get', name)
}

export const updateSavingBalanceApi = async (data) => {
  const name = 'finance.updateSavingBalanceApi';
  const url = '/v1/finance/update_saving_balance';
  return baseCall(url, 'put', name, data)
}

export const getFilterByPeriodApi = async ({ year, month }) => {
  const name = 'finance.getFilterByPeriodApi';
  const url = `/v1/finance/filter_by_period?year=${year || ''}&month=${month || ''}`;
  return baseCall(url, 'get', name)
}

export const closeApi = async (data) => {
  const name = 'finance.addClosepi';
  const url = '/v1/finance/close';
  return baseCall(url, 'post', name, data)
}

export const addFinanceOperationApi = async (data) => {
  const name = 'finance.addFinanceOperationApi';
  const url = '/v1/finance/finance_operation';
  return baseCall(url, 'post', name, data)
}

export const getFinanceOperationApi = async () => {
  const name = 'finance.getFinanceOperationApi';
  const url = '/v1/finance/finance_operation';
  return baseCall(url, 'get', name)
}

export const updateFinanceOperationApi = async (data) => {
  const name = 'finance.updateOperationApi';
  const url = '/v1/finance/finance_operation';
  return baseCall(url, 'put', name, data)
}

export const deleteFinanceOperationApi = async (data) => {
  const name = 'finance.deleteFinanceOperationApi';
  const url = '/v1/finance/finance_operation';
  return baseCall(url, 'delete', name, data)
}

export const addOperationDetailApi = async (data) => {
  const name = 'finance.addOperationDetailApi';
  const url = '/v1/finance/operation_detail';
  return baseCall(url, 'post', name, data)
}

export const getOperationDetailApi = async () => {
  const name = 'finance.getOperationDetailApi';
  const url = '/v1/finance/operation_detail';
  return baseCall(url, 'get', name)
}

export const updateOperationDetailApi = async (data) => {
  const name = 'finance.updateOperationDetailApi';
  const url = '/v1/finance/operation_detail';
  return baseCall(url, 'put', name, data)
}

export const deleteOperationDetailApi = async (data) => {
  const name = 'finance.deleteOperationDetailApi';
  const url = '/v1/finance/operation_detail';
  return baseCall(url, 'delete', name, data)
}

export const addTariffApi = async (data) => {
  const name = 'finance.addTariffApi';
  const url = '/v1/finance/tariff';
  return baseCall(url, 'post', name, data)
}

export const getTariffApi = async () => {
  const name = 'finance.getTariffApi';
  const url = '/v1/finance/tariff';
  return baseCall(url, 'get', name)
}

export const updateTariffApi = async (data) => {
  const name = 'finance.updateTariffApi';
  const url = '/v1/finance/tariff';
  return baseCall(url, 'put', name, data)
}

export const deleteTariffApi = async (data) => {
  const name = 'finance.deleteTariffApi';
  const url = '/v1/finance/tariff';
  return baseCall(url, 'delete', name, data)
}
