<template>
  <div v-if="ticketInformation">
    <div
      v-if="!$vuetify.breakpoint.xsOnly"
      @click.self="routeBack"
      class="backdrop"
    >
      <div class="modal">
        <div class="top-row side-paddings">
          <h2 class="ticket-title">Заявка №{{ ticketInformation.id }}</h2>
          <div class="close" @click.self="routeBack">&#10005;</div>
        </div>
        <div class="slide-row tabs-row">
          <v-slide-group
            show-arrows="always"
            class="slide-row"
            id="slide_group"
            value="status"
          >
            <v-slide-item
              class="slide-row2"
              v-if="statusLoading"
              v-for="n in 5"
              :key="n"
            >
              <v-skeleton-loader
                max-height="45px"
                type="sentences"
              ></v-skeleton-loader>
            </v-slide-item>
            <v-slide-item
              class="slide-row2"
              v-for="status in statuses"
              :key="status.id"
              :class="{
                new2: status.status == 'Новый',
                pending_bg: status.status == 'В ожидании',
                in_process_bg: status.status == 'В процессе',
                expired_bg: status.status == 'Просрочен',
                done_bg: status.status == 'Решен',
                rejected_bg: status.status == 'Отклонен',
              }"
            >
              <div class="slide-row">
                <div class="item-top-row">
                  <div
                    class="slide-status"
                    :class="{
                      new: status.status == 'Новый',
                      pending: status.status == 'В ожидании',
                      in_process: status.status == 'В процессе',
                      expired: status.status == 'Просрочен',
                      done: status.status == 'Решен',
                      rejected: status.status == 'Отклонен',
                    }"
                  >
                    {{ status.status }}
                  </div>
                  <div class="date">
                    {{
                      $moment(status.updated_at).format("DD.MM.YYYY в HH:mm")
                    }}
                  </div>
                </div>
                <div class="executor">
                  Изменил
                  {{ status.user.first_name + " " + status.user.last_name }}
                </div>
              </div>
            </v-slide-item>
          </v-slide-group>
        </div>
        <div class="flex-row">
          <div class="left">
            <div class="box-wrap">
              <div class="tenant-info">
                <div class="avatar-img">
                  <picture>
                    <source
                      :srcset="ticketInformation.addresserAvatar"
                      alt=""
                    />
                    <img src="@/assets/images/avatar.svg" alt="" />
                  </picture>
                </div>
                <div class="mid-text" style="flex-wrap: wrap">
                  <div class="address-row">
                    <div class="name" style="font-weight: 600; font-size: 14px">
                      {{ ticketInformation.addresserName }}
                    </div>
                    <div
                      v-if="ticketInformation.addresserPhone"
                      class="phone"
                      style="margin-left: 24px"
                    >
                      +{{ ticketInformation.addresserPhone }}
                    </div>
                  </div>
                  <div class="address-row">
                    <div
                      v-if="ticketInformation.addresserBlock"
                      class="address"
                    >
                      <div class="type">ЖК:</div>
                      <div class="num">
                        {{ ticketInformation.addresserBlock.complex }}
                      </div>
                    </div>
                    <div
                      v-if="ticketInformation.addresserBlock"
                      class="address"
                    >
                      <div class="type">Блок:</div>
                      <div class="num">
                        {{ ticketInformation.addresserBlock.number }}
                      </div>
                    </div>
                    <div class="address" v-if="ticketInformation.address">
                      <div class="type">Подъезд:</div>
                      <div class="num">
                        {{ ticketInformation.address.entrance }}
                      </div>
                    </div>
                    <div v-if="ticketInformation.address" class="address">
                      <div class="type">Этаж:</div>
                      <div class="num">{{ ticketInformation.address.floor }}</div>
                    </div>
                    <div v-if="ticketInformation.address" class="address">
                      <div class="type">Кв:</div>
                      <div class="num">{{ ticketInformation.address.number }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box-wrap">
              <div
                class="modal-tab-row justify-space-between"
                style="flex-wrap: nowrap"
              >
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      v-bind="attrs"
                      v-on="on"
                      :class="{
                        new: ticketInformation.status.title == 'Новый',
                        pending: ticketInformation.status.title == 'В ожидании',
                        in_process:
                          ticketInformation.status.title == 'В процессе',
                        expired: ticketInformation.status.title == 'Просрочен',
                        done: ticketInformation.status.title == 'Решен',
                        rejected: ticketInformation.status.title == 'Отклонен',
                      }"
                      >&bull;{{ ticketInformation.status.title }}
                    </v-btn>
                  </template>
                  <v-list v-for="status in statusList" :key="status.id">
                    <v-list-item
                      @click="setStatus(ticketInformation.id, status.id)"
                    >
                      <v-list-item-title>{{ status.title }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div
                  class="modal-tab-item"
                  style="margin: 0px 10px !important; cursor: default"
                  v-if="ticketInformation.department"
                >
                  {{ ticketInformation.department }}
                </div>
                <div
                  class="modal-tab-item"
                  style="margin: 0px 10px !important; cursor: default"
                  v-else
                >
                  Не назначен
                </div>
                <div class="flex-row jc-sb">
                  <div class="date-grey">
                    {{ $moment(ticketInformation.created_at).format("DD.MM.YYYY в HH:mm") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="box-wrap">
              <h4 class="ticket-title-sm" style="margin: 0px 0px 15px">
                Описание
              </h4>
              <div class="description-text">
                <div
                  class="pick-executor"
                  style="
                    min-height: 80px;
                    max-height: 150px;
                    align-items: start;
                    padding: 13px 18px;
                    overflow: auto;
                  "
                >
                  {{ ticketInformation.description }}
                </div>
              </div>
              <div
                v-if="
                  ticketInformation.attachments &&
                  ticketInformation.attachments.length > 0
                "
                class="show-attachment"
              >
                <div
                  v-for="attachments in ticketInformation.attachments"
                  :key="attachments"
                >
                  <div class="attachments-img">
                    <picture class="image">
                      <source :srcset="attachments" alt="" />
                      <img src="@/assets/images/avatar.svg" alt="" />
                    </picture>
                    <div class="middle">
                      <div v-if="attachments">
                        <v-btn
                          @click.stop="
                            (attachmentDialog = true), (photo = attachments)
                          "
                        >
                          <div class="inner-text">Открыть</div>
                        </v-btn>
                      </div>
                    </div>
                  </div>
                  <v-dialog v-model="attachmentDialog" width="500">
                    <v-card class="photo_container">
                      <v-card-actions class="big_photo">
                        <v-spacer></v-spacer>
                        <picture class="image" style="position: relative">
                          <source :srcset="photo" alt="" />
                          <img src="@/assets/images/avatar.svg" alt="" />
                        </picture>
                        <v-btn
                          color="primary"
                          text
                          @click="attachmentDialog = false"
                          style="position: absolute"
                          >✕</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
              <div v-else class="modal_font">
                Нет вложений
              </div>
            </div>
            <div class="box-wrap" style="padding: 0px 30px">
              <h4 class="ticket-title-sm" style="margin: 15px 0px">
                Исполнитель
              </h4>
              <div class="pick-executor">
                {{ ticketInformation.executorName }}
                <div
                  class="change_executor"
                  style="cursor: pointer"
                  @click="togglePickExecutor"
                >
                  Изменить исполнителя
                </div>
              </div>
              <div class="switch-button" style="justify-content: flex-end">
                Важная заявка
                <label class="switch"
                  ><input
                    type="checkbox"
                    :checked="ticketInformation.important"
                    @click="
                      updateTicketImportance(
                        ticketInformation.id,
                        !ticketInformation.important
                      )
                    "
                  />
                  <span class="slider"></span>
                </label>
              </div>
            </div>
          </div>
          <div class="right">
            <Comments
              v-model="comments"
              :loading="loading"
              :comments="comments"
              :comments_info="comments_info"
              :ticketInformation="ticketInformation"
              :ticketReport="ticketReport"
              @addComment="newComment"
              @getNextComments="showNextComments"
            />
          </div>
        </div>
      </div>
    </div>
    <v-card v-else class="mobile-ticket-card">
      <div v-if="!showChat" class="modal">
        <div class="ticket-form">
          <div class="slide-row tabs-row">
            <v-slide-group
              class="slide-row"
              id="slide_group"
              value="status"
            >
              <v-slide-item
                class="slide-row2"
                v-if="statusLoading"
                v-for="n in 5"
                :key="n"
              >
                <v-skeleton-loader
                  max-height="45px"
                  type="sentences"
                ></v-skeleton-loader>
              </v-slide-item>
              <v-slide-item
                class="slide-row2"
                v-for="status in statuses"
                :key="status.id"
                :class="{
                  new2: status.status == 'Новый',
                  pending_bg: status.status == 'В ожидании',
                  in_process_bg: status.status == 'В процессе',
                  expired_bg: status.status == 'Просрочен',
                  done_bg: status.status == 'Решен',
                  rejected_bg: status.status == 'Отклонен',
                }"
              >
                <div class="slide-row">
                  <div class="item-top-row">
                    <div
                      class="slide-status"
                      :class="{
                        new: status.status == 'Новый',
                        pending: status.status == 'В ожидании',
                        in_process: status.status == 'В процессе',
                        expired: status.status == 'Просрочен',
                        done: status.status == 'Решен',
                        rejected: status.status == 'Отклонен',
                      }"
                    >
                      {{ status.status }}
                    </div>
                    <div class="date">
                      {{
                        $moment(status.updated_at).format("DD.MM.YYYY в HH:mm")
                      }}
                    </div>
                  </div>
                  <div class="executor">
                    Изменил
                    {{ status.user.first_name + " " + status.user.last_name }}
                  </div>
                </div>
              </v-slide-item>
            </v-slide-group>
          </div>
          <v-divider></v-divider>
          <v-card-title class="jc-sb">
            <h2 class="ticket-title">Заявка №{{ ticketInformation.id }}</h2>
            <span class="date-grey">
              {{ $moment(ticketInformation.created_at).format("DD.MM.YYYY в HH:mm") }}
            </span>
          </v-card-title>
          <v-card-text>
            <div class="addresser-info">
              <v-avatar color="mr-6">
                <v-img v-if="ticketInformation.addresserAvatar"
                  :src="ticketInformation.addresserAvatar"
                  lazy-src="@/assets/images/avatar.svg"
                ></v-img>
                <v-img v-else
                  src="@/assets/images/avatar.svg"
                ></v-img>
              </v-avatar>
              <div class="d-flex flex-column">
                <span class="addresser-name">{{ ticketInformation.addresserName }}</span>
                <span class="addresser-phone">
                  {{ ticketInformation.addresserPhone | VMask('+# (###) ###-##-##') }}
                </span>
                <div v-if="ticketInformation.address">
                  <v-chip label class="address-chip mr-1">
                    <pre class="address-title">Блок: </pre>
                    <span class="address-number">{{ ticketInformation.addresserBlock.number }}</span>
                  </v-chip>
                  <v-chip label class="address-chip">
                    <pre class="address-title">Квартира: </pre>
                    <span class="address-number">{{ ticketInformation.address.number }}</span>
                  </v-chip>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-title class="description-title">
            <h4 class="ticket-title-sm">Описание</h4>
          </v-card-title>
          <v-card-text>
            <div class="description-text">
              {{ ticketInformation.description }}
            </div>
            <div
              v-if="
                ticketInformation.attachments &&
                ticketInformation.attachments.length > 0
              "
              class="show-attachment"
            >
              <div
                v-for="attachments in ticketInformation.attachments"
                :key="attachments"
              >
                <div class="attachments-img">
                  <picture class="image">
                    <source :srcset="attachments" alt="" />
                    <img src="@/assets/images/avatar.svg" alt="" />
                  </picture>
                  <div class="middle">
                    <div v-if="attachments">
                      <v-btn
                        @click.stop="
                          (attachmentDialog = true), (photo = attachments)
                        "
                      >
                        <div class="inner-text">Открыть</div>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <v-dialog v-model="attachmentDialog" max-width="500">
                  <v-card class="photo_container">
                    <v-card-actions class="big_photo">
                      <v-spacer></v-spacer>
                      <picture class="image" style="position: relative">
                        <source :srcset="photo" alt="" />
                        <img src="@/assets/images/avatar.svg" alt="" />
                      </picture>
                      <v-btn
                        color="primary"
                        text
                        @click="attachmentDialog = false"
                        style="position: absolute"
                        >✕</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </div>
            <div
              v-else
              class="modal_font"
            >
              Нет вложений
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-title class="executor-title">
            <h4 class="ticket-title-sm">Исполнитель</h4>
          </v-card-title>
          <v-card-text>
            <div class="pick-executor">
              {{ ticketInformation.executorName }}
              <div
                class="change_executor"
                @click="togglePickExecutor"
              >
                Изменить исполнителя
              </div>
            </div>
            <div class="switch-button justify-end">
              Важная заявка
              <label class="switch"
                ><input
                  type="checkbox"
                  :checked="ticketInformation.important"
                  @click="
                    updateTicketImportance(
                      ticketInformation.id,
                      !ticketInformation.important
                    )
                  "
                />
                <span class="slider"></span>
              </label>
            </div>
          </v-card-text>
          <v-divider></v-divider>
        </div>
        <v-card-text class="chat-btn-wrapper">
          <v-btn @click="showChat = true">Перейти в чат</v-btn>
        </v-card-text>
      </div>
      <div v-else>
        <Comments
          v-model="comments"
          :loading="loading"
          :comments="comments"
          :comments_info="comments_info"
          :ticketInformation="ticketInformation"
          :ticketReport="ticketReport"
          @addComment="newComment"
          @getNextComments="showNextComments"
          @showTicket="showChat = false"
        />
      </div>
    </v-card>
    <div v-if="showPickExecutor">
      <PickExecutor
        @close="togglePickExecutor"
        @select="updateExecutor"
        :ticketNumber="id"
      />
    </div>
  </div>
</template>
<script>
import {
  getApplicationIdApi,
  getStatusListApi,
  setStatusApi,
  updateExecutorApi,
  getApplicationAttachmentApi,
  getAppStatusByIDApi,
  getTicketReportApi,
} from "@/api/applications";
import { getCommentsApi, submitCommentApi } from "@/api/comments";
import Comments from "../comments/Comments";

export default {
  components: {
    PickExecutor: () => import("@/components/modals/PickExecutor"),
    Comments,
  },
  props: {
    id: {
      type: String,
    },
  },
  data: () => ({
    ticketInformation: undefined,
    ticketReport: undefined,
    tab: null,
    attachmentDialog: false,
    loading: true,
    statusLoading: true,
    showChat: false,
    comments: [],
    page: 1,
    slider_position: 0,
    comments_info: {},
    attachments: [],
    statuses: [],
    showPickExecutor: false,
    photo: "",
    comment: "",
    statusList: [],
  }),
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getApplicationId();
      this.getStatusList();
      this.getComments();
      this.getAppStatusByID();
      this.getTicketReport();
    },
    togglePickExecutor() {
      this.showPickExecutor = !this.showPickExecutor;
    },
    async updateExecutor(id) {
      await updateExecutorApi({ ticket: this.id, executor: id });
      this.getApplicationId();
    },
    async setStatus(app, id) {
      this.statusLoading = true;
      await setStatusApi(app, id);
      this.getApplicationId();
      this.getAppStatusByID();
      let slide_content = document.getElementsByClassName(
        "v-slide-group__content"
      )[0].style.transform;
      console.log("content", slide_content);
      setTimeout(() => {
        document.getElementsByClassName(
          "v-slide-group__content"
        )[0].style.transform = "translateX(0px)";
        document.getElementsByClassName(
          "v-slide-group__content"
        )[0].style.transform = "translateX(0px)";
      }, 100);
    },
    async newComment(data) {
      const res = await submitCommentApi({ data });
      if (res.status === 200) {
        this.page = 1;
        this.comments = [];
        this.comments_info = {};
        this.loading = true;
        this.getComments();
        console.log("Updated page", this.page);
        console.log("Updated comments", this.comments);
        console.log("Updated response", res);
      } else {
        //failed
      }
    },
    async getApplicationId() {
      const res = await getApplicationIdApi(this.id)
        .then((res) => {
          console.log("ticketinfo",res.data)
          this.ticketInformation = {
            ...res.data,
            type: res.data.type?.title,
            created_at: res.data.created_at,
            addresserBlock: res.data?.block,
            addresserApt: res.data?.address,
            addresserPhone: res.data.addresser?.phone,
            addresserName: `${res.data?.addresser.first_name} ${res.data?.addresser.last_name}`,
            executorName: `${res.data?.executor.first_name} ${res.data?.executor.last_name}`,
            addresserAvatar: res.data.addresser?.user_avatar?.avatar,
            important: res.data.important,
            attachments: res.data.attachments,
          };
        })
        .catch((error) => {
          console.log("error", error);
          alert("Заявка с указанным номером не существует!");
          this.routeBack();
        });
    },
    async getStatusList() {
      let res = await getStatusListApi();
      this.statusList = [...res.data];
    },
    async getComments() {
      const params = {
        app_label: "tickets",
        model: "ticket",
        object_id: this.id,
      };
      const commentsPerLoad = 5; // amount of requested comments
      const res = await getCommentsApi(params, commentsPerLoad, this.page);
      this.comments.push(...res.data.results);
      this.comments_info = res.data;
      this.loading = false;
    },
    // async getApplicationAttachment(){
    //   const res = await getApplicationAttachmentApi();
    //   this.attachments = res.data;
    //   // this.attachments=res.data.map((a)=> ({
    //   //
    //   // }))
    // },
    async getAppStatusByID() {
      const res = await getAppStatusByIDApi(this.id);
      this.statuses = res.data.reverse();
      this.statusLoading = false;
    },
    async getTicketReport() {
      let res = await getTicketReportApi(this.id);
      this.ticketReport = res.data
      console.log("report", this.ticketReport)
    },
    showNextComments() {
      if (this.comments_info.next) {
        this.page++;
        this.getComments();
      }
      console.log("Next page:", this.comments_info.next);
    },
    async updateTicketImportance(data) {
      await updateTicketImportanceAPI({ data });
      this.getApplicationId();
    },
    routeBack() {
      this.$router.push({ name: "Tickets" });
    },
  },
};
</script>

<style lang="scss" scoped>
.date {
  margin-left: 20%;
  white-space: nowrap;
}
.theme--light.v-icon.v-icon.v-icon--disabled {
  color: rgba(0, 0, 0, 0.1) !important;
}
</style>