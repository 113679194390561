<template>
  <div class="content">
    <v-row no-gutters class="side-paddings">
      <div class="tab-row">
        <router-link
        class="tab-item"
        v-for="tab in tabs"
        :key="tab.name"
        :to="{ name: `Security.${tab.name}` }"
        >
          {{ tab.title }}
        </router-link>
      </div>
    </v-row>
    <router-view/>
  </div>
</template>


<script>
  export default {
    components: {
      CCTV: () => import('@/components/security/cctv'),
      Pass: () => import('@/components/security/pass'),
    },
    data() {
      return {
        tabs: [
          // {
          //   name: 'cctv',
          //   title: "Видеонаблюдение",
          // },
          {
            name: 'pass',
            title: "Пропуск на территорию",
          },
        ],
      };
    },
  }
</script>

<style>
</style>