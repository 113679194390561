import Vue from 'vue'
import Vuex from 'vuex'
import user from './user';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: [],
    currentComplex: null,
    snackbar: [],
    snackbarTitle: '',
    snackbarColor: '',
  },
  mutations: {
    ADD_LOADING (state, data) {
      state.loading.push(data)
    },
    DEL_LOADING (state, data) {
      state.loading = state.loading.filter((n) => n !== data)
    },
    SET_CURRENT_COMPLEX (state, data) {
      state.currentComplex = data;
    },
    DEL_CURRENT_COMPLEX (state) {
      localStorage.removeItem('currentComplex');
      state.currentComplex = {};
    },
    SHOW_SNACKBAR (state, data) {
      state.snackbar.push(data);
      if (state.snackbar.length > 1) {
        state.snackbar = [];
        setTimeout(() => {
          state.snackbar.push(data);
        }, 100);
      };
    },
    SET_SNACKBAR_TITLE (state, data) {
      state.snackbarTitle = data;
    },
    SET_SNACKBAR_COLOR (state, data) {
      state.snackbarColor = data;
    },
    HIDE_SNACKBAR (state) {
      state.snackbar = [],
      state.snackbarTitle = '';
      state.snackbarColor = '';
    },
  },
  actions: {
    setCurrentComplex ({ commit }, data) {
      commit('SET_CURRENT_COMPLEX', data);
      localStorage.setItem('currentComplex', data);
    }
  },
  modules: {
    user
  }
})
