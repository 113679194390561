<template>
  <v-card
  width="695px"
  height="116px"
  style="border-radius: 8px"
  >
    <div class="up-side">
      <div class="vote-item-title">{{title}}</div>
    </div>
    <div class="down-side">
      <v-row style="margin: 0;align-items: center;text-align: center" class="justify-space-between">
        <div class="vote-item-info" style="width: 125px">
          <div class="grey-text">Начало: </div>
          <div class="default-text">{{start_date}}</div>
        </div>
        <div class="vote-item-info" style="width: 125px">
          <div class="grey-text">Конец: </div>
          <div class="default-text">{{end_date}}</div>
        </div>
        <div class="vote-item-info" style="border:0">
          <div class="grey-text">Проголосовало: </div>
          <div :class="{
            green_vouted: vouted_count > 70,
            red_vouted: vouted_count < 30,
            yellow_vouted: vouted_count >= 30 && vouted_count <= 70
          }">{{vouted_count}}%</div>
        </div>
        <v-btn
        width="250px"
        height="37px"
        style="max-height: 37px; border-radius: 0px 0px 8px 0px;"
        @click="openVouteDialog"
        >Открыть голосование</v-btn>
      </v-row>
    </div>
    <v-dialog
        v-model="voteDialog"
        @click:outside="voteDialog= false"
        width="900px"
        scrollable
    >
      <v-card
      width="900px"
      >
        <div class="vote-dialog">
          <div class="left-side">
            <div class="vote-item-title" >Лист №47 голосования при проведении онлайн опроса собственников квартир/нежилых помещений</div>
            <v-divider></v-divider>
            <div class="info-row">
              <div class="vote-item-info" style="border: 0">
                <div class="grey-text">Статус: </div>
                <div class="green_vouted">Активный</div>
              </div>
              <div class="vote-item-info" style="border: 0">
                <div class="grey-text">Начало: </div>
                <div class="default-text">{{start_date}}</div>
              </div>
              <div class="vote-item-info" style="border: 0">
                <div class="grey-text">Конец: </div>
                <div class="default-text">{{end_date}}</div>
              </div>
            </div>
            <v-divider></v-divider>
            <div class="left-text">
              <div class="grey-text">Местонахождение многоквартирного жилого дома:</div>
              <div class="default-text pb-5">г. Алматы, р-н Алмалинский, ул. Айманов 40</div>
              <div class="grey-text">Ответственные лица:</div>
              <div class="default-text">Нурсултан Байымбетов</div>
              <div class="default-text">Талгат Торегелдиев</div>
              <div class="grey-text pb-5" style="font-size:12px">Назначаемые из числа собственников квартир/нежилого помещения</div>
              <div class="d-flex">
                <div class="default-text">Арман Байжанов: </div>
                <div class="red_vouted">Отсутствует</div>
              </div>
              <div class="d-flex">
                <div class="default-text">Гулнур Кайырхан: </div>
                <div class="red_vouted">Отсутствует</div>
              </div>
              <div class="d-flex pb-5">
                <div class="default-text">Дархан Раманов: </div>
                <div class="green_vouted">Есть</div>
              </div>
              <v-divider class="pb-5"></v-divider>
              <div class="grey-text" style="font-size:18px">Вопросы внесенные для обсуждения:</div>
              <div class="d-flex align-center">
                <div class="num">1</div>
                <div class="default-text pb-5">Выбрать форму управления объектом кондоминиума Объединение Собственников Имущества “Брюссель 2”.  г. Алматы, р-н Алмалинский, ул. Айманова 40</div>
              </div>
              <v-divider class="pb-5"></v-divider>
              <div class="d-flex align-center">
                <div class="num">2</div>
                <div class="default-text">Утвердить размер ежемесячных расходов на управление объектом кондаминиума и содержание общего имущества объекта кСондоминиума в размере 62 тенге за квадратный метр полезной площади квартир и 124 тенге за квадратный </div>
              </div>
            </div>
          </div>

          <div class="right-side">
            <div class="d-flex justify-space-between">
              <div>
                <div class="vote-item-title">Проголосовавшие</div>
                <div class="d-flex pl-6" style="margin-bottom: 4px">
                  <div class="green_vouted" style="font-size:16px">85 </div>
                  <div class="grey-text "> из 120</div>
                </div>
              </div>
              <div style="cursor: pointer" class="close pr-5 pt-5" @click="voteDialog=false">
                <v-icon>mdi-close</v-icon>
              </div>
            </div>
            <v-divider class="pb-5"></v-divider>
            <div v-for="n in 6" class="voted-person ml-5 mb-5" @click="voterDialog=true">
              <div class="avatar-img">
                  <img src="@/assets/images/avatar.svg" alt="" />
              </div>
              <div class="text-block">
                <div class="default-text">Айбек Сарыбаев</div>
                <div class="grey-text">121 квартира</div>
              </div>
            </div>
            <div style="margin-top: auto">
              <v-divider></v-divider>
              <v-btn @click="addVoteDialog=true" class="vote-btn">Добавить голос</v-btn>
            </div>
        </div>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog
        v-model="voterDialog"
        @click:outside="voterDialog= false"
        scrollable
        width="500px"
    >
      <v-card
      style="overflow: auto"
      >
        <div class="left-side">
          <div class="d-flex align-center justify-center">
            <div class="vote-item-title" >Проголосовавши</div>
          </div>
          <v-divider></v-divider>
          <div style="height: 80px" class="info-row">
            <div class="avatar-img">
              <img src="@/assets/images/avatar.svg" alt="" />
            </div>
            <div class="text-block">
              <div class="default-text">Айбек Сарыбаев</div>
              <div class="grey-text">121 квартира</div>
            </div>
            <v-btn class="download-btn">
              <img src="../../assets/images/download.svg" alt="">
              Скачать фото</v-btn>
          </div>
          <v-divider></v-divider>
          <div class="left-text">
            <div class="grey-text">Местонахождение многоквартирного жилого дома:</div>
            <div class="default-text pb-5">г. Алматы, р-н Алмалинский, ул. Айманов 40</div>
            <div class="grey-text">Ответственные лица:</div>
            <div class="default-text">Нурсултан Байымбетов</div>
            <div class="default-text">Талгат Торегелдиев</div>
            <div class="grey-text pb-5" style="font-size:12px">Назначаемые из числа собственников квартир/нежилого помещения</div>
            <div class="grey-text">Подписи ответсвенных лиц:</div>
            <div class="d-flex">
              <div class="default-text">Арман Байжанов: </div>
              <div class="red_vouted">Отсутствует</div>
            </div>
            <div class="d-flex">
              <div class="default-text">Гулнур Кайырхан: </div>
              <div class="red_vouted">Отсутствует</div>
            </div>
            <div class="d-flex pb-5">
              <div class="default-text">Дархан Раманов: </div>
              <div class="green_vouted">Есть</div>
            </div>
            <div class="grey-text">Ф.И.О собственника квартиры/нежилого помещения</div>
            <div class="default-text pb-5">Айбек Сарыбаев</div>
            <div class="grey-text">Адрес собственника квартиры/нежилого помещения</div>
            <div class="default-text">Республика Казахстан</div>
            <v-divider class="pb-5"></v-divider>
            <div class="grey-text" style="font-size:18px">Вопросы внесенные для обсуждения:</div>
            <div class="d-flex align-center">
              <div class="num">1</div>
              <div style="border-right: 1px solid #E1E1E1" class="default-text pb-5">Выбрать форму управления объектом кондоминиума Объединение Собственников Имущества “Брюссель 2”.  г. Алматы, р-н Алмалинский, ул. Айманова 40</div>
              <div class="pl-3 green_vouted">За</div>
            </div>
            <v-divider class="pb-5"></v-divider>
            <div class="d-flex align-center">
              <div class="num">2</div>
              <div style="border-right: 1px solid #E1E1E1" class="default-text">Утвердить размер ежемесячных расходов на управление объектом кондаминиума и содержание общего имущества объекта кСондоминиума в размере 62 тенге за квадратный метр полезной площади квартир и 124 тенге за квадратный </div>
              <div class="pl-3 red_vouted">Против</div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="addVoteDialog"
        @click:outside="addVoteDialog= false"
        width="1080px"
        scrollable
    >
      <v-card
      width="1080px"
      height="700px"
      >
        <div class="d-flex align-center justify-center pt-5">
          <div class="vote-item-title">Добавить бумажный бюллетень</div>
          <div style="cursor: pointer" class="close" @click="addVoteDialog=false">
            <v-icon>mdi-close</v-icon>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="d-flex">
        <div class="step-column">
          <div class="d-flex pt-5">
            <img src="../../assets/images/Step.svg" class="mr-5" alt="">
            <div class="step-title">Фотография</div>
          </div>
          <div class="grey-text">Добавте фотографию бумажного листа ответов жителя</div>
          <v-btn class="add-photo-btn">Добавить фото</v-btn>
        </div>
        <div class="step-column">
          <div class="d-flex pt-5">
            <img src="../../assets/images/Step.svg" class="mr-5" alt="">
            <div class="step-title">Список вопросов</div>
          </div>
          <div class="grey-text pb-3">Заполните вопросы по ответам на бумажном листе</div>
          <v-divider
          style="width: 100%;height: 1px"
          ></v-divider>
          <div class="pt-7 pb-5">
            <v-card
            width="280px"
            height="220px"
            class="question"
            >
              <div class="step-title" style="margin: 10px 15px">Вопрос 1</div>
              <div class="question-body">
                <div class="default-text" style="margin: 10px 15px">Выбрать форму управления объектом кондоминиума Объединение Собственников Имущества “Брюссель 2”.  г. Алматы, р-н Алмалинский, ул. Айманова 40</div>
              </div>
              <div class="d-flex">
                <v-btn class="make-vote-btn red">Против</v-btn>
                <v-btn class="make-vote-btn yellow">Воздержусь</v-btn>
                <v-btn class="make-vote-btn green">За</v-btn>
              </div>

            </v-card>
          </div>
          <div class="pt-7 pb-5">
            <v-card
                width="280px"
                height="220px"
                class="question"
            >
              <div class="step-title" style="margin: 10px 15px">Вопрос 1</div>
              <div class="question-body">
                <div class="default-text" style="margin: 10px 15px">Выбрать форму управления объектом кондоминиума Объединение Собственников Имущества “Брюссель 2”.  г. Алматы, р-н Алмалинский, ул. Айманова 40</div>
              </div>
              <div class="d-flex">
                <v-btn class="make-vote-btn red">Против</v-btn>
                <v-btn class="make-vote-btn yellow">Воздержусь</v-btn>
                <v-btn class="make-vote-btn green">За</v-btn>
              </div>

            </v-card>
          </div>
          <div class="pt-7 pb-5">
            <v-card
                width="280px"
                height="220px"
                class="question"
            >
              <div class="step-title" style="margin: 10px 15px">Вопрос 1</div>
              <div class="question-body">
                <div class="default-text" style="margin: 10px 15px">Выбрать форму управления объектом кондоминиума Объединение Собственников Имущества “Брюссель 2”.  г. Алматы, р-н Алмалинский, ул. Айманова 40</div>
              </div>
              <div class="d-flex">
                <v-btn class="make-vote-btn red">Против</v-btn>
                <v-btn class="make-vote-btn yellow">Воздержусь</v-btn>
                <v-btn class="make-vote-btn green">За</v-btn>
              </div>

            </v-card>
          </div>
        </div>
        <div class="step-column">
          <div class="d-flex pt-5">
            <img src="../../assets/images/Step.svg" class="mr-5" alt="">
            <div class="step-title">Добавить голос</div>
          </div>
          <div class="grey-text pb-3">Введите данные голосующего жителя</div>
          <div>
            <v-text-field
                label="Имя"
                color="#5b6cff"
                outlined
                required
                style="width: 280px"
            ></v-text-field>
          <v-text-field
              label="Фамилия"
              color="#5b6cff"
              outlined
              required
              style="width: 280px"
          ></v-text-field>
          <v-text-field
              label="Номер квартиры"
              color="#5b6cff"
              outlined
              required
              style="width: 280px"
          ></v-text-field>
          <v-text-field
              label="Телефон"
              color="#5b6cff"
              outlined
              required
              style="width: 280px"
          ></v-text-field>
          <v-text-field
              label="Номер лицевого счета"
              color="#5b6cff"
              outlined
              required
              style="width: 280px"
          ></v-text-field>
          </div>
          <v-btn style="margin-top: auto" class="vote-btn">Добавить</v-btn>
        </div>
        </div>
      </v-card>

    </v-dialog>
  </v-card>

</template>

<script>
export default {
  name: "VoteItem",
  props: {
    title: {
      type: String,
      default: "",
    },
    start_date: {
      type: String,
      default: "",
    },
    end_date: {
      type: String,
      default: "",
    },
    vouted_count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      voteDialog: false,
      addVoteDialog: false,
      voterDialog: false,
    }
  },
  methods: {
    openVouteDialog() {
      this.voteDialog = true;
    },
  },
}
</script>

<style scoped>

.vote-item-title{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  color: #000000;
  padding: 12px;
}

.question-body{
  max-height: 128px;
  overflow: auto;
  background-color: #F8F8F8;
  border-top: 1px solid #E6E6E6 !important;
  border-bottom: 1px solid #E6E6E6 !important;

}

.download-btn{
  background-color: #DBDEFF !important;
  color: #5B6CFF !important;
  margin-left: auto;
}

.num{
  font-size:30px;
  color:#5B6CFF;
  padding-right: 20px;
}

.make-vote-btn{
  font-size: 12px !important;
  width: 93.5px;
  background-color: transparent !important;
  box-shadow: none;

}
.red{
  color: #FF5B5B !important;
  border-radius: 0 0 0 8px !important;
  border-right: 1px solid #E1E1E1 !important;
}
.yellow{
  color: #FFB400 !important;
  border-radius: 0 !important;
  border-right: 1px solid #E1E1E1 !important;

}
.green{
  color: #5B6CFF !important;
  border-radius: 0 0 8px 0 !important;
}

.vote-btn{
  width:310px;
  height:50px;
  border-radius: 8px;
  margin: 20px;
}

.add-photo-btn{
  width:150px;
  border-radius: 8px !important;
  background-color: transparent !important;
  color: #5B6CFF !important;
  border: 1px dotted #5B6CFF !important;
  margin: auto 0;
}

.text-block .grey-text{
  font-size:14px;
}

.text-block .default-text{
  font-size: 16px;
}

.left-text{
  padding: 13px 24px;
}

.left-text .grey-text{
  padding-bottom: 8px;
  font-size: 16px;
}

.step-column{
  width: 34%;
  height: 630px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  border-right: 1px solid #E6E6E6;
}

.step-column .grey-text{
  text-align: center;
  width: 230px;
}

.right-side .grey-text{
  padding-bottom: 8px;
  font-size: 16px;
}

.right-side{
  width: 350px;
  display: flex;
  flex-direction: column;
}

.question{
  border-radius: 8px !important;
  border: 1px solid #E6E6E6 !important;
  box-shadow: none !important;
}

.step-title{
  font-size: 18px;
}

.vote-dialog{
  display: flex;
}

.left-text .default-text{
  font-size: 16px;
}

.info-row .vote-item-info{
  border:0;
  padding-right: 20px;
  margin: 0;
}

.voted-person{
  width: 310px;
  height: 64px;
  background-color: #F8F8F8;
  border-radius: 8px;
  border: 1px solid #E1E1E1;
  display: flex;
  align-items: center;
}

.info-row{
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0px 24px;
}

.left-side .vote-item-title{
  margin: 13px 24px;
  padding: 0;
}

.right-side .vote-item-title{
  margin: 13px 24px 0px 24px;
  padding: 0;
}

.left-side{
  border-right: 1px solid #e0e0e0;
  max-width: 550px;
}

.vote-item-info{
  display: flex;
  margin-left: 10px;
  border-right: 1px solid #e0e0e0;
  height: 45px;
  align-items: center;
  justify-content: center;
}

.grey-text{
  color: #7F8297;
  font-size: 14px;
}

.default-text{
  color: #000000;
  font-size: 14px;
}

.green_vouted{
  color: #00C853;
}

.red_vouted{
  color: #FF5252;
}

.yellow_vouted{
  color: #FFEB3B;
}

.down-side{
  border-top: 1px solid #e0e0e0;
  background-color: #F8F8F8;
  height: 45px;
  min-height: 45px;
}

</style>