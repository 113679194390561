<template>
  <div class="content">
    <div class="user-row side-paddings" :class="{ details: showSideModal }">
      <div class="tab-row">
        <router-link
          v-for="tab in tabs"
          :key="tab.name"
          :to="{ name: `Users.${tab.name}` }"
          class="tab-item"
        >
          {{ tab.title }}
        </router-link>
      </div>
      <!-- <div class="search">-->
      <!--   <input type="text" placeholder="Поиск..." v-model="listFilter.search">-->
      <!-- </div>-->
      <!-- <div class="switch-box">-->
      <!--   Только должники-->
      <!--   <label class="switch">-->
      <!--     <input type="checkbox" :value="true" v-model="listFilter.debt">-->
      <!--     <span class="slider"></span>-->
      <!--   </label>-->
      <!-- </div>-->
    </div>
    <router-view @resizeTabsRow="resizeTabsRow" :filters="listFilter" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      tabs: [
        {
          title: "Лицевые счета",
          name: "residents",
        },
      ],
      listFilter: {},
      showSideModal: false,
    };
  },
  watch: {
    listFilter: {
      deep: true,
      handler() {
        /** set url parameters from listFilter data & fetch application list */
        const listFilter = {};
        Object.keys(this.listFilter).forEach((key) => {
          if (!!this.listFilter[key]) {
            listFilter[key] = this.listFilter[key];
          }
        });
        if (this.$route.query.listFilter !== JSON.stringify(listFilter)) {
          let query = { ...this.$route.query };
          if (Object.keys(listFilter).length > 0) {
            query.listFilter = JSON.stringify(listFilter);
          } else {
            delete query.listFilter;
          }
          this.$router.push({ ...this.$route, query });
        }
      },
    },
  },
  computed: {
    type() {
      return this.$route.name.split(".")[1];
    },
  },
  methods: {
    init() {
      /** fill listFilter from route query */
      this.listFilter = JSON.parse(this.$route.query.listFilter || "{}");
    },
    resizeTabsRow() {
      this.showSideModal = !this.showSideModal;
    },
  },
};
</script>