<template>
  <v-form ref="selectExecutor">
    <v-autocomplete
      v-model="selectedExecutor"
      @change="$emit('selectedExecutor', selectedExecutor)"
      :items="executorList"
      :rules="newTicketRules"
      label="Сотрудник"
      placeholder="Выберите сотрудника"
      color="primary"
      class="new-ticket-executor"
      item-text="fullName"
      item-value="user.id"
      hide-details
      clearable
      outlined
      required
    >
      <template v-slot:item="executor">
        <v-list-item-avatar>
          <v-img
            v-if="executor.item.user.user_avatar"
            :src="executor.item.user.user_avatar"
            lazy-src="@/assets/images/avatar.svg"
          ></v-img>
          <v-img v-else src="@/assets/images/avatar.svg"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ executor.item.fullName }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-chip label class="executor-address-chip">
              <span class="executor-number">{{ executor.item.position }}</span>
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-form>
</template>

<script>
import { staffApi } from "@/api/staff";

export default {
  data: () => ({
    executorList: undefined,
    selectedExecutor: undefined,
    newTicketRules: [(v) => !!v || "Обязательное поле"],
  }),
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getResidents();
    },
    async getResidents() {
      let res = await staffApi();
      this.executorList = [...res.data];
      this.executorList.forEach((executor) => {
        executor.fullName = executor.user.first_name + ' ' + executor.user.last_name;
      });
    },
    validateSelect() {
      if (this.$refs.selectExecutor.validate() === true) {
        this.$emit('validExecutor');
      };
    },
    clearSelect() {
      this.selectedExecutor = "",
      this.$refs.selectExecutor.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>