<template>
  <v-form ref="selectSender">
    <v-autocomplete
      v-model="senderId"
      @change="$emit('selectedSender', selectedSender)"
      :items="senderList"
      :rules="newTicketRules"
      placeholder="Выберите отправителя"
      color="primary"
      class="new-ticket-sender"
      item-text="fullName"
      item-value="id"
      hide-details
      clearable
      outlined
      required
    >
      <template v-slot:item="sender">
        <v-list-item-avatar>
          <v-img
            v-if="sender.item.user_avatar"
            :src="sender.item.user_avatar"
            lazy-src="@/assets/images/avatar.svg"
          ></v-img>
          <v-img v-else src="@/assets/images/avatar.svg"></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ sender.item.fullName }}</v-list-item-title>
          <v-list-item-subtitle>
            <v-chip label class="sender-address-chip mr-1">
              <pre class="sender-address">Блок: </pre>
              <span class="sender-number">{{ sender.item.block }}</span>
            </v-chip>
            <v-chip label class="sender-address-chip">
              <pre class="sender-address">Квартира: </pre>
              <span class="sender-number">{{ sender.item.number }}</span>
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </v-form>
</template>

<script>
import { residentApi } from "@/api/residents";

export default {
  data: () => ({
    senderList: undefined,
    senderId: undefined,
    newTicketRules: [(v) => !!v || "Обязательное поле"],
  }),
  computed: {
    selectedSender() {
      return this.senderList.find((sender) => this.senderId === sender.id);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getResidents();
    },
    async getResidents() {
      let res = await residentApi();
      this.senderList = [...res.data];
      this.senderList.forEach((sender) => {
        sender.fullName = sender.first_name + ' ' + sender.last_name;
      });
    },
    validateSelect() {
      if (this.$refs.selectSender.validate() === true) {
        this.$emit('validSender');
      };
    },
    clearSelect() {
      this.senderId = "",
      this.$refs.selectSender.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>