import {
  authUrl,
  refreshUrl,
  logoutUrl,
  managerProfile
} from '@/api/index'
import {getComplexByManager} from "@/api/companies";

export default {
  namespaced: true,
  state: {
    user: JSON.parse(localStorage.getItem('managerProfile') || null),
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
  },
  mutations: {
    SET_USER (state, user) {
      state.user = user
    },
    SET_TOKENS (state, { access, refresh }) {
      state.access = access
      state.refresh = refresh
      localStorage.setItem('access', access)
      localStorage.setItem('refresh', refresh)
    }
  },
  actions: {
    async login ({ commit }, credentials = {}) {
      const res = await authUrl(credentials)
      commit('SET_TOKENS', res.data)
      console.log('login', res.data)
    },
    async refresh ({ commit }, refresh = '') {
      const res = await refreshUrl(refresh)
      commit('SET_TOKENS', res.data)
      console.log('refresh', res.data)
    },
    async logout ({ commit }, data = {}) {
      const res = await logoutUrl()
      commit('SET_TOKENS', {})
      commit('SET_USER', {})
      localStorage.removeItem('managerProfile')
      localStorage.removeItem('access')
      localStorage.removeItem('refresh')
    },
    async managerProfile ({ commit }) {
      const res = await managerProfile()
        .catch((e) => {
          console.log(e);
        });
      localStorage.setItem('managerProfile', JSON.stringify(res.data))
      commit('SET_USER', res?.data || {});
    },
    async setDefaultComplex() {
      await getComplexByManager()
          .then((res) => {
            console.dir(res.data)
            // if(res.data.current == null && res.data.others.length > 0){
              localStorage.setItem('currentComplex', parseInt(res.data.others[0].id));
              this.$store.commit('SET_CURRENT_COMPLEX', parseInt(res.data.others[0].id));
            // }
          })
          .catch(() => {});
    },
  },
}
