<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="510px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      @click:outside="closeDialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$vuetify.breakpoint.mdAndDown"
          v-bind="attrs"
          v-on="on"
          fab
          class="create-ticket-fab"
          :disabled="submitTicket"
        >
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
        <v-btn
          v-else
          v-bind="attrs"
          v-on="on"
          text
          color="white"
          class="save-btn"
          :disabled="submitTicket"
          @click="setTicketType"
          height="50px"
        >
          Создать заявку
        </v-btn>
      </template>
      <v-card class="new-ticket">
        <v-card-title class="justify-center">
          <span class="text-h5">Новая заявка</span>
          <v-btn icon @click="closeDialog">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="ticketDescription">
            <div class="tab-row">
              <div
                v-for="type in ticketTypes"
                :key="type.id"
                class="tab-item"
                :class="{ active: formData.type === type.id }"
                @click="$set(formData, 'type', type.id)"
              >
                {{ type.title }}
              </div>
            </div>
            <h4 class="ticket-title-sm">Выберите службу</h4>
            <v-select
              v-model="formData.department"
              :rules="newTicketRules"
              :items="departmentsOfComplex"
              item-text="department_title"
              item-value="department_id"
              label="Служба*"
              color="primary"
              class="new-ticket-department"
              :menu-props="{ bottom: true, offsetY: true }"
              hide-details
              clearable
              required
              outlined
            ></v-select>
            <h4 class="ticket-title-sm">Выберите отправителя</h4>
            <SelectSender
              ref="senderForm"
              @selectedSender="setSender"
              @validSender="validateSender"
            />
            <h4 class="ticket-title-sm">Выберите исполнителя</h4>
            <SelectExecutor
              ref="executorForm"
              @selectedExecutor="setExecutor"
              @validExecutor="validateExecutor"
            />
            <h4 class="ticket-title-sm">Описание</h4>
            <v-textarea
              v-model="formData.description"
              :rules="newTicketRules"
              placeholder="Введите ваш комментарий"
              hide-details
              required
              outlined
            ></v-textarea>
            <div class="switch-button">
              Важная заявка
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="formData.important"
                  @change="(e) => $set(formData, 'important', e.target.checked)"
                />
                <span class="slider"></span>
              </label>
            </div>
          </v-form>
          <v-btn class="save-btn" @click="addTicket" :disabled="submitTicket"
            >Создать заявку
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SelectSender from "@/components/widgets/SelectSender.vue";
import SelectExecutor from "@/components/widgets/SelectExecutor.vue";
import {
  getApplicationType,
  addApplicationApi,
  setStatusApi,
} from "@/api/applications";
import { Locations } from "@/models/locations";
import { staffApi } from "@/api/staff";
import { getUser } from "@/api";

export default {
  components: {
    SelectSender,
    SelectExecutor,
    apartmentIcon: () => import("@/components/ui/icons/apartment"),
    entranceIcon: () => import("@/components/ui/icons/entrance"),
    otherIcon: () => import("@/components/ui/icons/other"),
    parkingIcon: () => import("@/components/ui/icons/parking"),
    yardIcon: () => import("@/components/ui/icons/yard"),
    commerceIcon: () => import("@/components/ui/icons/commerce"),
  },
  props: {
    ticketTypes: Array,
    departmentsOfComplex: Array,
  },
  data() {
    return {
      submitTicket: false,
      dialog: false,
      formData: {
        type: "",
        department: "",
        status: 2,
        executor: "",
        addresser: "",
        building_id: "",
        description: "",
        important: false,
        attachments: [],
      },
      newTicketRules: [(v) => !!v || "Обязательное поле"],
      validSender: false,
      validExecutor: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    closeDialog() {
      this.dialog = false;
      this.formData = {
        type: "",
        department: "",
        status: 2,
        executor: "",
        addresser: "",
        building_id: "",
        description: "",
        important: false,
        attachments: [],
      };
      this.formData.type = this.ticketTypes[0]?.id;
      this.$refs.senderForm.clearSelect();
      this.$refs.executorForm.clearSelect();
      this.$refs.ticketDescription.resetValidation();
    },
    setTicketType() {
      this.formData.type = this.ticketTypes[0]?.id;
    },
    setSender(addresser) {
      this.formData.addresser = addresser.id;
      this.formData.building_id = addresser.building_id;
    },
    setExecutor(executor) {
      this.formData.executor = executor;
    },
    validateSender () {
      if (this.validSender === false) this.validSender = true;
    },
    validateExecutor () {
      if (this.validExecutor === false) this.validExecutor = true;
    },
    async addTicket() {
      try {
        this.submitTicket = true;
        this.formData.description = this.formData.description.trim();
        this.$refs.senderForm.validateSelect();
        this.$refs.executorForm.validateSelect();
        if (
          this.$refs.ticketDescription.validate() === true &&
          this.validSender &&
          this.validExecutor &&
          this.formData.description !== "" &&
          this.formData.type &&
          this.formData.executor &&
          this.formData.addresser &&
          this.formData.building_id &&
          this.formData.important !== undefined
        ) {
          const data = this.formData;
          const res = await addApplicationApi(data);
          console.log("result", res);
          this.setStatus(res.data.id, 1);
          this.$emit("close");
          this.$emit("addTicket");
          this.submitTicket = false;
          this.$refs.ticketDescription.reset();
          this.closeDialog();
          this.$store.commit("SHOW_SNACKBAR", "createTicketSuccessSnackbar");
          this.$store.commit(
            "SET_SNACKBAR_TITLE",
            `Заявка №${res.data.id} успешно создана`
          );
          this.$store.commit("SET_SNACKBAR_COLOR", "success");
        } else {
          if (!this.$refs.ticketDescription.validate()) {
            this.$store.commit("SHOW_SNACKBAR", "ticketFieldsErrorSnackbar");
            this.$store.commit("SET_SNACKBAR_TITLE", `Заполните все поля!`);
            this.$store.commit("SET_SNACKBAR_COLOR", "error");
          } else if (this.formData.description === "") {
            this.$store.commit("SHOW_SNACKBAR", "ticketDescriptionErrorSnackbar");
            this.$store.commit("SET_SNACKBAR_TITLE", "Введите корректное описание!");
            this.$store.commit("SET_SNACKBAR_COLOR", "error");
          } else if (!this.formData.type) {
            this.$store.commit("SHOW_SNACKBAR", "ticketTypeErrorSnackbar");
            this.$store.commit("SET_SNACKBAR_TITLE", "Выберите тип заявки!");
            this.$store.commit("SET_SNACKBAR_COLOR", "error");
          }
        }
      } catch (err) {
        this.$store.commit("SHOW_SNACKBAR", "createTicketErrorSnackbar");
        this.$store.commit("SET_SNACKBAR_TITLE", `Ошибка при создании заявки`);
        this.$store.commit("SET_SNACKBAR_COLOR", "error");
        throw err;
      } finally {
        this.submitTicket = false;
      }
    },
    async setStatus(app, id) {
      await setStatusApi(app, id).catch(() => {});
    },
  },
};
</script>
<style >
</style>
