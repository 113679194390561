<template>
  <div class="content">
    <div class="calendar-wrap">
      <div v-if="taskCalendarLoading">
        <v-skeleton-loader type="chip, button" class="d-flex jc-sb"></v-skeleton-loader>
        <v-skeleton-loader type="table-row-divider@7"></v-skeleton-loader>
      </div>
      <div v-else>
        <v-sheet>
          <v-toolbar flat>
            <v-btn icon color="primary" @click="prev">
              <v-icon> mdi-chevron-left </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.refTaskCalendar">
              {{ $refs.refTaskCalendar.title }}
            </v-toolbar-title>
            <v-btn icon color="primary" @click="next">
              <v-icon> mdi-chevron-right </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="openTaskDialog">
              Добавить событие
            </v-btn>
            <CreateEvent
              ref="refCreateTask"
              @updateCalendar="getTasks"
              @emitTaskComplete="emitTaskComplete"
            />
          </v-toolbar>
        </v-sheet>
        <v-sheet>
          <v-calendar
            ref="refTaskCalendar"
            v-model="taskCalendar"
            :weekdays="weekday"
            :events="tasks"
            :event-ripple="false"
            :type="taskCalendarType"
            :show-month-on-first="false"
            @click:event="viewTask"
            hide-header
            color="primary"
            locale="ru"
          ></v-calendar>
        </v-sheet>
      </div>
    </div>
    <DeletionConfirm
      v-if="completeTaskConfirm"
      title="Вы действительно хотите завершить событие?"
      :description="`${taskTitle}`"
      @close="rejectCompleteTask"
      @delete="completeTask"
    />
  </div>
</template>

<script>
import { getTasksApi, completeTaskApi } from "@/api/tasks";
import CreateEvent from "@/components/modals/CreateEvent.vue";
import DeletionConfirm from '@/components/modals/DeletionConfirm.vue';

export default {
  components: {
    CreateEvent,
    DeletionConfirm,
  },
  data: () => ({
    taskCalendar: "",
    taskCalendarType: "month",
    weekday: [1, 2, 3, 4, 5, 6, 0],
    tasks: [],
    taskData: {},
    taskDialogType: "create",
    taskCalendarLoading: true,
    completeTaskConfirm: false,
    taskId: "",
    taskTitle: "",
  }),
  created() {
    this.getTasks();
  },
  updated() {
    // Called to show month title in v-calendar
    this.$refs.refTaskCalendar.move(0);
  },
  methods: {
    async getTasks() {
      try {
        let res = await getTasksApi();
        this.tasks = res.data.map((task) => {
          return {
            _task: task.task,
            _sub_tasks: task.sub_tasks,
            // Data below parsed only for v-calendar
            id: task.task.id,
            name: task.task.title,
            start: task.task.start_date,
            end: task.task.end_date,
            color: task.task.color,
            textColor: task.task.color,
          }
        });
      } catch (err) {
        throw err;
      } finally {
        this.taskCalendarLoading = false;
      };
    },
    openTaskDialog() {
      this.$refs.refCreateTask.createTaskDialog = true;
    },
    emitTaskComplete(id, title) {
      this.taskId = id;
      this.taskTitle = title;
      this.completeTaskConfirm = true;
    },
    rejectCompleteTask() {
      this.taskId = "";
      this.taskTitle = "";
      this.completeTaskConfirm = false;
    },
    async completeTask() {
      try {
        await completeTaskApi({ task: this.taskId });
        this.taskId = "";
        this.taskTitle = "";
        this.$refs.refCreateTask.closeTaskDialog();
        this.getTasks();
        this.$store.commit('SHOW_SNACKBAR', 'completeTaskSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Событие успешно завершено');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'completeTaskErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при завершении события');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      } finally {
        this.completeTaskConfirm = false;
      };
    },
    // Vuetify v-calendar functions
    prev() {
      this.$refs.refTaskCalendar.prev();
    },
    next() {
      this.$refs.refTaskCalendar.next();
    },
    viewTask({ event }) {
      this.openTaskDialog();
      this.$refs.refCreateTask.setDataToForm(event);
    },
  },
};
</script>
<style lang="scss" scoped>
.v-toolbar__title {
  padding: 0 !important;
}
</style>